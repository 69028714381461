import React from 'react';
import _ from 'lodash';
import moment from "moment";
import uuidv4 from "uuid/v4";
import {WORKER_KEYS, TWO_DECIMAL_NO_REGEX, packModeLabel, FILE_STATUS,DEFAULT_CURRENCY,currencies, DEFAULT_ERROR, NETWORK_ERROR, QUICK_FILTER_MAP} from "./constants";

import * as Sentry from "@sentry/browser";
export const pluralize = (n,str="") => {
  return (n > 1 ? (str+"s") : str);
}

export const buildPlanId = (cid, profileId) => {
  const rand = uuidv4();
  if (!!profileId.match(/:/)) // profile id already captures cid and uid
    return profileId + ':' + rand;
  return cid + ':' + rand; // we can't determined he user id he :(
}

export const userPlanPath = (uid, planId, ...parts) => {
  if (!uid || !planId) return '';
  const rest = parts.join('/');
  const planPath = (planId.indexOf(':') < 0) ?
    `user_profiles/${uid}/plans/${planId}` :
    `plans/${planId}`;
  if (rest) return planPath + '/' + rest;
  return planPath;
}

export const sharePlanPath = (cid, packId) => {
  const planPath = 'https://' + cid + '.fitbudd.com/packs/' + packId
  return planPath;
}

export const DEFAULT_SERVING_ID = "ebba5d00-2e36-4c70-b294-6369d1c9eb0a";
export const GMML_SERVINGS = Object.freeze([
  '229e75c8-0a97-4a0c-bb14-a302c1bf56d2', // GM
  '92df1acd-a6c3-4d8d-bda3-3a750b674f53', // ML
]);
//Dont change the order below
export const DUMB_UNITS=Object.freeze([
  ...GMML_SERVINGS,
  'weight_imperial', // ounce
  'volume_imperial', //fl oz
  'volume_imperial_us' //fl oz(US)
]);
export const mapDumbUnitToServingType={
  [GMML_SERVINGS[0]]:"meal_weight",
  [GMML_SERVINGS[1]]:"meal_volume",
  weight_imperial:"meal_weight",
  volume_imperial:"meal_volume",
  volume_imperial_us:"meal_volume",
}
export const handleServingString = (tags, serving) => {
  const servingIndex = _.findIndex(tags, ['id', serving]);
  if(servingIndex !== -1) {
    return serving;
  }else{
    return DEFAULT_SERVING_ID;
  }
}

export const hextoRgb = (hex, opacity) => {
  if (!hex) return;
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
  return result;
};
export const isValidSubdomain=string=>{
  const subDomainRegex = /^[a-z0-9]{1}[a-z0-9\-]{4,61}[a-z0-9]{1}$/;
  return subDomainRegex.test(string);
}
export const isValidURL = string => {
  try {
    var res = decodeURI((string || '').replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')).match(
      // eslint-disable-next-line no-useless-escape
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  } catch (e) {
    console.error(e);
    return false;
  }
};
export const isValidURLWithHttps = string => {
  try {
    var res = decodeURI((string || '').replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')).match(
      // eslint-disable-next-line no-useless-escape
      /^https:\/\/(?:wwww\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const snapshotDocsToData = (snapshot, idField = true, filter = undefined) => {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data({ serverTimestamps: "none" });
  if (filter && !filter(data)) return undefined;
  return { data: { ...data }, ...(idField ? { _id: snapshot.id } : {}) };
};

export const snapshotDocsArrayToDataArray = (docs, idField = true, filter = undefined) => {
  const out = [];
  docs.forEach(s => {
    const tmp = snapshotDocsToData(s, idField, filter);
    if (tmp) out.push(tmp);
  });
  return out;
};
export function hasLength(obj) {
  return obj && Object.keys(obj).length > 0;
}

export const getWidthHeight = (aspect, maxWidth, maxHeight) => {
  let width = maxWidth;
  let height = maxHeight;
  if (aspect >= 1) {
    height = width / aspect;
  } else {
    width = aspect * height;
  }
  return { width, height };
};
export const checkWidthHeight = (width, height, maxWidth, maxHeight) => {
  return width <= maxWidth && height <= maxHeight;
};

export const changeDate = (date1, date2) => {
  return !date1.isSame(date2, "day");
};

export const getHeightWidthUisngFormula = (view_width, aspect) => {
  let width_new, height_new;
  let factor = 0.5;
  // for landscape image calculate width based view_width
  if(aspect > 1) {
    width_new = factor * view_width;
    height_new = width_new / aspect;
  } else  {
    height_new = factor * view_width;
    width_new = height_new * aspect
  }

  return { height_new, width_new };
};
export const getNameAndImage = (doc, keyName, query) => {
  return {
    name: (doc.data.nick_name) || (doc.data.profile && doc.data.profile.name) || (doc.data && doc.data.name),
    email: (doc.data.profile && doc.data.profile.email) || (doc.data && doc.data.email),
    image_data: (doc.data.profile && doc.data.profile.image_data) || (doc.data && doc.data.image_data),
    identifier: (doc.data.profile && doc.data.profile.image) || (doc.data && doc.data.image)
  };
};

export const getUiAvatar = name => {
  const matches = name && name.trim().split(" ");
  let first = "";
  if (matches && matches.length) first = matches[0].slice(0, 1);
  if(!!matches) {
    const initials = matches.length === 1 ? first : first + matches[matches.length - 1].slice(0, 1);
    return initials;
  }
};


export const countDecimals = (value) => {
  value = value || 0;
  if(Math.floor(value) === value) return 0;
  if(value % 1 === 0) return 0;
  return value.toString().split(".")[1].length || 0; 
}

export const towDecimalNumberValidation = (num) =>{
  if(TWO_DECIMAL_NO_REGEX.test(num)) return num;
  return roundNumber(num);
}

export const roundMacros = (num) =>{
  if (!num || typeof num !== 'number') return 0;
  if (num >= 100) Math.round(num);
  if (num >= 10) {
    const tmp = Math.round(num * 10) / 10;
    return Number(String(tmp).replace('.0', ''));
  } else {
    const tmp = Math.round(num * 100) / 100;
    return Number(String(tmp).replace('.00', '').replace(/\.([1-9])0/, '.$1'));
  }
}

export const roundNumber =(num, preciseCount =2) =>{
  if(num === undefined || num === null || num=== "") return ;
  if(num % 1 === 0){
    if(String(num).includes('.')) return num;
    else return Number(num);
  } 
  else return Number(Number(num).toFixed(preciseCount))
}

export const findCurrencySymbol=(currency=DEFAULT_CURRENCY)=>{
  currency=currency.toUpperCase();
  return _.get(currencies.find(i=>i.value===currency),"symbol", '');
}

export const formatCurrency=(value,currencySymbol,currency=DEFAULT_CURRENCY,...props)=>{
  currencySymbol=currencySymbol||findCurrencySymbol(currency);
  return formatNo(value, {style: "currency", currencySymbol,...props})
}
export const formatNo = (value, args = {}, placeholder="-") => {
  const {precision, abbr = false, ...options} = args || {};
  if(!value) return placeholder;
  if (abbr && value >= 1000) {
    if (value < 999999) return Math.round(value / 1000) + 'K';
    if (value < 999999999) return Math.round(value / 1000000) + 'M';
    return '1B+';
  }
  if(options.style==="currency"){
    return options.currencySymbol+''+new Intl.NumberFormat(options).format(roundNumber(value, precision));
  }
  return new Intl.NumberFormat(options).format(roundNumber(value, precision));
}
export const roundCurrency = (amount) => {
  return amount.toFixed(2).replace('.00', '');
};

export const exCategoryHelper = {
  types:['strength', 'timed', 'body_weight'],
  names:{'strength':"Strength", timed:"Timed", body_weight:"Body Weight"},
  typeToText: function(type, mode) {
    const src =  this.names;
    return src[type] || _.capitalize(type);
  },
};

export const exTypeHelper = {
  typeDuration: 'duration',
  typeReps: 'reps',
  typeRepsWeight: 'reps_w_weight',
  types: ['duration','reps','reps_w_weight','distance','cals'],
  names: { duration: 'Duration', reps: 'Repetitions', reps_w_weight: 'Repetitions with Weight', distance:"Distance",cals:"Calories" },
  shortNames: { duration: 'Duration', reps: 'Reps', reps_w_weight: 'Reps + Weights' },
  typeToText: function(type, mode) {
    const src = mode === 'short' ? this.shortNames : this.names;
    return src[type] || _.capitalize(type);
  },
  options: function(mode, filter=[]) {
    const _types = _.without(this.types,...filter)
    return _types.map(x => ({
      value: x, label: this.typeToText(x, mode)
    }));
  }
};

export const fakeEvent = (key, value, extra = {}) => ({
  target: {id: key, name: key, value, ...extra}
})

export const calculateHeight = (listRef) => {
  const height = listRef.getBoundingClientRect().height
  const defaultRow = 15;
  const cellDefaultHeight = 80;
  const cellHeight = defaultRow * cellDefaultHeight;
  const cellToBeAdded = (height - cellHeight) / cellDefaultHeight;
  const totalCell = defaultRow + cellToBeAdded;
  const dataLimit = Math.round(totalCell);
  return dataLimit;
}

const _trxrm = {
  singularize: {
    secs: 'sec',
    mins: 'min',
    hrs: 'hr',
    days: 'day',
    weeks: 'week',
    months: 'month',
  },
  pluralize: {
    secs: 'secs',
    mins: 'mins',
    hrs: 'hrs',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
  },
};
export const transform = (str, {
  capitalize = false,
  pluralize = 0,
}) => {
  let out = _.lowerCase(str);
  if (pluralize) out = _trxrm[pluralize === 1 ? 'singularize' : 'pluralize'][out] || out;
  if (capitalize) out = _.capitalize(out);
  return out;
}

export const resizeEvents = () =>{
 if(!!window)  window.dispatchEvent(new Event('resize'));
}



export const isEmptyArray = (wo)=>{
  if(!wo || !wo.length) return true;
  //incase wo = [undefined];
  let empty = true;
  wo.forEach(wo=>{
    if(!!wo) empty = false;
  });
  return empty;
}

export const numFormatter = (num) => {
  if(num > 999 && num < 1000000){
    return (num/1000).toFixed(1).replace(/\.0+$/,'') + 'K'; // convert to K for number from > 1000 < 1 million 
  }else if(num > 1000000){
      return (num/1000000).toFixed(1).replace(/\.0+$/,'') + 'M'; // convert to M for number from > 1 million 
  }else if(num < 1000){
      return num; // if value < 1000, nothing to do
  }
}

export const getEmailFirstLetter = (email) => {
  if(!email) return;
  return email.split('@').shift();
}

export const setPrefixSuffix = (str, prefix, suffix) => `${prefix || ''}${str}${suffix || ''}`



export const getUnitConversionAndText = (keyName , unit_details, unit_system, config) => {
  let res;
  if(unit_system === "custom") {
    const kus = unit_details[keyName]
    res = _.get(config, [keyName, kus], '')
  }else {
    res = _.get(config, [keyName , unit_system], '')
  }
  return res
}

export const getChipLabelPrefixSuffix = (keyName, str, extraProps = {}) => {
  const {unit_system,  unit_details, config, trainers, planMap } = extraProps
  const { unit : text } = getUnitConversionAndText(keyName , unit_details, unit_system, config);
  switch (keyName) {
    case "startDate":
      const [from, to] = str.split('-');
      return `Start: ${moment(from).format('DD MMM')}${(from !== to) ? ` - ${moment(to).format('DD MMM')}` : ''}`;
    case "age":  
      return setPrefixSuffix(str, '', ' yrs');
    case "weight":
      return setPrefixSuffix(str, '', ` ${text }`);
    case "tags":
      return setPrefixSuffix(str, '#', '');
    case "type":
      return packModeLabel[str]
    case "trainers":
      return _.get(_.find(trainers, ['_id', str]), 'data.name', str)
    case "multi_list_filters": {
      const [root, sub_filter] = str.split('.');
      if(!sub_filter) return QUICK_FILTER_MAP.browse.find(qf => qf.key === root).label
      return (QUICK_FILTER_MAP[root].find(qf => qf.key === sub_filter) || {}).label || ""
    }
    case "plan":
      return planMap ? planMap[str] : str;
    default:
      return str;
  }
}

export const getNonBreakingStr = (str) => str.replace(/\s+/g, "\u00a0")

export const getTrueDuration = (doc) => {
  if (!doc) return 0;
  const { purchase, currPurchase, duration, subs_duration } = doc;
  const isSubscription = 'subscription' === _.get(purchase || currPurchase, 'pack.mode');
  if (isSubscription && subs_duration) return subs_duration;
  return duration;
}

export const refreshDoc = async (fetchDoc, props, onFinish) => {
  if(!fetchDoc) return
  try {
    props.showLoader()
    const data = await fetchDoc();
    let doc, oldDoc;
    if (!!data) {
      const parsedDoc = !!data.data ? data.data() : data;
      doc = { ...parsedDoc };
      oldDoc = { ...parsedDoc };
    }
    onFinish({
      doc,
      oldDoc,
      loader: false
    })
    props.hideLoader()
  } catch (e) {
    console.error(e);
    props.enqueueSnackbar("Unable to fetch. Please Try Again.", {
      variant: "error"
    });
    props.hideLoader()
  }
}

export const getActivityUnitText = (unit, distance_unit) => {
  switch (unit) {
    case "steps":
      return "Steps";
    case "distance":
      return `${_.capitalize(distance_unit)} (distance)`;
    case "flights":
      return "Flights";
    case "energy":
      return "Cals (energy)";
    default:
      return unit;
  }
}

export const removeSpace=(val)=>{
  if(!val) return '';
  return val.replace( / +/g,"");
}
export const commafyNumber = (num) => {
  if(!num) return false;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isFilterEmpty = (filter) => _.values(filter).every((c) => { 
  if(_.isBoolean(c)) return !c
  if(_.isObject(c)) return isFilterEmpty(c)
  else return _.isEmpty(c) 
})
export const secondsToMinutes = (secs) => {
  if(!secs) return "00:00"
  const formatted = moment.utc((secs)*1000).format('mm:ss');
  return formatted;
};

export const secondsToMinuteFormatted = (sec, { 
  toString=false, units = ['m', 's'], secondsOnly=false } = {}) => {
    if(!!secondsOnly && toString) return `${sec} ${units[1]}`;
  if(!!secondsOnly && !toString) return [0, sec];
  const exactMoment = moment().startOf('day').add(sec, 'seconds');
  const  [mm, ss] = [exactMoment.clone().diff(moment().startOf('day'), 'minutes'), exactMoment.clone().seconds()];
  if(!toString) return [mm, ss]; //Returns in [minutes, seconds] format
  return (<span>
    {!!mm && <span>{mm} <span className='font_13_500'>{units[0]} {" "}</span></span>}
    {!!ss && <span>{ss} <span className='font_13_500'>{units[1]}</span></span>}
  </span>)
  //  returns a string e.g 1m 50s
};
export const disabledIconDuringUpload = (doc,isError=false) => {
  if (!doc) return;
  if(isError) return false;
  return (_.get(doc, 'media[0].status') === FILE_STATUS.uploading || _.get(doc, 'media[0].status') === FILE_STATUS.processing);
};


export const handleError = (e, enqueueSnackbar) => {
  if (e.response && e.response.data && e.response.data.message) {
    const { success, message } = e.response.data;
    if (!success) enqueueSnackbar(message, { variant: "error" });
  } else {
    const error = e.message === "Network Error" ? NETWORK_ERROR : DEFAULT_ERROR
    enqueueSnackbar(error, { variant: "error" });
  }
  if (e.message !== "Network Error") {
    Sentry.captureException(e);
  }
}

export const findAvgOfArr=(arr)=>arr.reduce( ( a, b ) => a + b, 0 ) / arr.length;

export const replaceAllSafe = (str, toReplace, replaceWith) => {
  if(!str) return '';
  const regex = new RegExp((toReplace || ''), "g");
  return str.replace(regex, (replaceWith || ''));
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const convertBlobToBase64=async(blob)=>{
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
export const isOnline=()=>navigator && navigator.onLine;
export const protectNumberInputs = (e, opts = {}) => {
  if((!opts.allowDecimal && e.key === ".") || e.key === "-" || e.key === "+" || e.key === "e"){
    e.preventDefault();
  }
}

export const downloadFile = async (url, filename = "", callback) => {
  if(!url) return
  const data = await fetch(url)
  const blob = await data.blob()
  const objectUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')

  link.setAttribute('href', objectUrl)
  link.setAttribute('download', filename)
  link.setAttribute('target', '_blank');

  link.style.display = 'none'

  document.body.appendChild(link)

  link.click()
  callback && callback()
  URL.revokeObjectURL(objectUrl);

  document.body.removeChild(link)
}


export const getDaysText = (days) => {
  return `${days} day${days > 1 ? 's' : ''}`;
};

/**
 * Generates a new list by adding a unique identifier to each item.
 *
 * @param {Array} list - The original list of items.
 * @param {string} [idName="id"] - The key to use for the unique identifier.
 * @returns {Array} - The new list with unique identifiers.
 */
export const generateListWithId = (list, idName = "id") => {
  return list.map(item => ({ ...item, [idName]: uuidv4() }));
}