import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import _ from "lodash";
import * as Sentry from '@sentry/browser';
import { connect, useDispatch } from "react-redux";
import { useSnackbar, withSnackbar } from "notistack";
import appRdxFns from "fitbud/redux/app";
import SlideButtons from "./slideButtons";
import usrRepo from "fitbud/repo/users";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemAvatar,
  ListItem,
  List,
  CircularProgress,
  Typography,
  Checkbox,
  ListItemIcon,
  Divider,
  Dialog as MUIDialog,
  DialogContent, DialogActions,
  DialogContentText, Button,
  FormControlLabel,
  Switch} from "@material-ui/core";
import firebase from "fitbud/firebase";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ClrdButton } from "fitbud/components/form-fields";
import { hasLength, pluralize, userPlanPath } from "fitbud/utils/helpers";
import Confirmation from "fitbud/components/confirmationDialog";
import CheckinForm from "../settings/checkinEditForm";
import Dialog from "fitbud/components/Dialog";
import moment from "moment-timezone";
import {DEFAULT_ERROR, ERROR_FREQUENCY } from "fitbud/utils/constants";
import { multiplier } from "fitbud/views/schedulePlanner/helper";
import selectedScheduleRdxFns from "fitbud/redux/selectedSchedule";
import { deactivateUser, deleteUser } from 'fitbud/api';
import * as consultoriaAPI from 'fitbud/api/consultoria';
import { useToggle } from 'fitbud/hooks/form';
import { TagsContext } from "fitbud/providers/tagsProvider";
import useListItemStyle from "fitbud/hooks/useListItemStyle";
import newUsersListRdxFns from "fitbud/redux/newUsersList";
import activeUsersListRdxFns from "fitbud/redux/activeUsersList";
import useTrainers from "fitbud/hooks/useTrainers";
import { RoleContext } from "fitbud/providers/roleProvider";
import { AvatarImage } from "fitbud/views/users/header";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import VideoCalling from "fitbud/components/videoCallCheck";
import ChangePlanDates from "fitbud/views/users/changePlanDates";
import { useLiveSessions } from "fitbud/providers/liveSessionsProvider";
import OrderHistory from "fitbud/views/users/orderHistory";
import { isSelfSignupUser, isActiveUser, isInactiveUser, getUserPlanEndDate, getDefaultSessionAssigned } from "./helpers";
import { MenuSharp } from "@material-ui/icons";
import { UserSchContext } from "fitbud/views/schedulev2";
import GcAccess from "fitbud/views/plan/editors/gcAccess";
import { parsePacks } from 'fitbud/views/plan/helper';
import { ACL_FEATURES_KEY, useAcl } from "fitbud/providers/acl-provider";
import ReminderSettings from "fitbud/views/users/reminder-settings";
import {getCheckInReminderAndConfig} from "fitbud/views/reminders/helper";

const userProfilePlanTransaction = async (userDocId, planId, userData, planData ) => {
  await firebase.firestore().runTransaction(async t => {
  const userRef = firebase.firestore().doc(`/user_profiles/${userDocId}`);
  const planRef = firebase.firestore().doc(userPlanPath(userDocId, planId));
  let promises = [];
  promises.push(t.update(userRef, {
    ...userData
  }));
  promises.push(t.update(planRef, {
    ...planData
  }));
  await Promise.all(promises);
});
}


const ChangeVideoSettings = ({vid_call, handleClose, onSave, sessions_invite}) => {
  const _default = {
    duration: '',
    frequency: 'week',
    includes_calls: true,
    num_sessions: '',
  };
  const [errors, setErrors] = useState({});
  const [state, setState] = useState(vid_call || _default);
  const dispatch = useDispatch();
  const {showLoader, hideLoader} = appRdxFns(dispatch)
  const {cid, comp} = useContext(FirebaseAuthContext);
  const company = comp ? comp.data() : {};
  const isGroupClass = Boolean(_.get(company, "features.group_class.enabled", false));
  const [dirty, setDirty] = useState(false); 
  const [sessionState, setSessionState] = useState(
    _.isEmpty(sessions_invite) ? getDefaultSessionAssigned() : sessions_invite
  );
  const { sessionAssigned, accessValue, status } = sessionState;
  const session_enabled = status === "active";
  const [loading, setLoading] = useState(true);
  const {enqueueSnackbar} = useSnackbar();
  const memoizeGcs = useRef({});
  const markDirty = ()=>setDirty(true);
  const [access_type, setAccessType] = useState(() => {
    const sessionAssigned = _.get(sessionState, 'sessionAssigned', []);
    if (!sessionAssigned || !sessionAssigned.length) return 'selected';
    if (sessionAssigned.includes('all')) return 'all';
    else return 'selected';
  });


  useEffect(()=>{
    const loadGcClasses = async ()=>{
      setLoading(true);
      showLoader()
      if(!!sessionAssigned && !!sessionAssigned.length){
        if(!sessionAssigned.includes("all")){
          const gcPromises = [];
          sessionAssigned.forEach((classId)=>{
            const classRef = firebase.firestore().doc(`/companies/${cid}/gcDetails/${classId}`);
            gcPromises.push(classRef.get())
          })
          const gcResponses = await Promise.all(gcPromises);
          const gcDetails = gcResponses.map((d) => ({ _id: d.id, data: d.data() }));
          const out = gcDetails.reduce((acc, current) => {
            acc[current._id] = { ...current };
            return acc;
          }, {});
          memoizeGcs.current = _.merge(memoizeGcs.current, out);
        }
      }
      setLoading(false);
      hideLoader();
    }
    loadGcClasses();
  },[])

  const onChangeAccess = (e) => {
    const value = e.target.value;
    let sessionAssigned;
    if (value === 'all') {
      sessionAssigned = ['all'];
    } else {
      sessionAssigned = [];
    }
    setSessionState((s) => ({ ...s, sessionAssigned }));
    setAccessType(value);
    markDirty()
  };

  const setGcValues = (out) =>{
    setSessionState((s) => ({ ...s, sessionAssigned : out }));
    markDirty()

  }

  const toggleSessionEnabled = (e) => {
    const value = e.target.checked;
    if (value) setAccessType('selected');
    const out = {sessionAssigned: []};
    if(value) out.status = "active";
    else out.status = "cancelled";
    setSessionState((s) => ({ ...s, ...out}));
    markDirty()

  };

  const onChangeCalls = (e) =>{
    const key = e.target.id || e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if(type === "number") value = Number(value);
    setSessionState((s) => ({ ...s, [key]: value}));
    markDirty()

  }


  const handleChange = (e) => {
    if (!e) return;
    let chng = {};
    if (!e.target) {
      chng = e;
    } else {
      chng = { [e.target.name || e.target.id]: e.target.value.trim() };
    }
    setState({ ...state, ...chng.vid_call });
    markDirty()

  };

  const valid = () => {
    let out = true;
    let errors = {};
    if (!!state.includes_calls && !state.num_sessions) {
      errors.num_sessions = ERROR_FREQUENCY;
      out = false;
    }
    if (!!state.includes_calls && !state.duration) {
      errors.call_duration = 'Please select a valid duration';
      out = false;
    }
    if(session_enabled && access_type === "selected"){
      if(_.get(sessionState, "sessionAssigned.length", 0) < 1){
        out = false;
        enqueueSnackbar("Select At least one class", {variant:"error"})
      }
    }
    if(session_enabled &&  !accessValue){
      errors.accessValue = ERROR_FREQUENCY;
      out = false;
    }
    setErrors(errors);    
    return out;
  };

  const onSubmit = () => {
    if (!valid()) return;
    onSave(state, sessionState);
  };

  if(loading) return null;
  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={handleClose}
      onSave={() => onSubmit()}
      title="Manage Video Calling"
      titleFont="h3"
      paperClass="width-600"
      withConfirmation
      isDirty={dirty}>
      <div className="px-20 py-10">
        <VideoCalling handleChange={handleChange} isStepper errors={errors} standalone vid_call={vid_call} />
        {isGroupClass && (
          <div>
            <Divider className="mb-8" />
            <FormControlLabel
              className="mb-8"
              control={<Switch color="primary" />}
              label={<Typography variant="h3">Group Classes</Typography>}
              checked={session_enabled}
              onChange={toggleSessionEnabled}
            />
            {session_enabled && (
              <div className="">
                <div className="info-box mb-20">
                  <Typography className="font_14_500 text-0d0d0d">
                    The clients on this plan can book any of the group classes as specified below. The quota, however,
                    is defined by the number of calls in the pricing option.
                  </Typography>
                </div>
                <GcAccess
                  accessType={access_type}
                  setAccessType={onChangeAccess}
                  values={sessionAssigned}
                  disabled={!session_enabled}
                  memoizeGcs={memoizeGcs}
                  ACCESS_OPTIONS={[
                    { label: 'Selected Classes', value: 'selected' },
                    { label: 'All', value: 'all' },
                  ]}
                  setValues={setGcValues}
                  withCalls
                  onChangeCalls={onChangeCalls}
                  noOfCalls={accessValue}
                  noCallsId="accessValue"
                  errors={errors}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
}

const ChangePlan = ({ cid, onSelect, priceDoc, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [list, updateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useListItemStyle();

  const fetchList = async (cid) => {
    setLoading(true);
    const plansSnapshot = await firebase
      .firestore()
      .collection(`/companies/${cid}/packs`).get();
    const data = parsePacks(plansSnapshot.docs)
    updateList(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchList(cid)
  }, [cid]);


  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onClose={handleClose}
      title={"Change Plan"}
      titleFont="h3"
      paperClass={isMobile ? "width-400" : "width-400 height-70"}
      dialogContentClassName="d-flex flex-column"
    >
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        {loading ? <CircularProgress /> :
          <List className="overflow-auto py-0 w-100 h-100">
            <ListItem
              key="custom"
              id="custom"
              className="fp-15"
              divider
              button
              classes={{
                root: classes.root,
                selected: classes.selected
              }}
              selected={priceDoc.id === "custom"}
              onClick={() => onSelect({
                id: "custom",
                pack_id: "custom",
                ref_name: "Custom",
                duration: priceDoc.duration,
                duration_type: priceDoc.duration_type,
                title: "Custom",
                mode: "one_time",
              })}
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <ListItemText
                  classes={{
                    multiline: "fm-0"
                  }}
                  primary={
                    <Typography
                      className="fpb-5"
                      classes={{
                        root: "text-truncate"
                      }}
                      variant="h5"
                    >
                      Custom
                      </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: "text-truncate text-muted"
                      }}
                      variant="body2"
                    >
                      {`${priceDoc.duration} ${priceDoc.duration_type.slice(0, -1)}${pluralize(priceDoc.duration)}`}
                    </Typography>
                  }
                />
              </div>
            </ListItem>
            {list.map((item, index) => {
              const { data: plan } = item
              return (plan && plan.price_opts.map((pricing, i) => {
                if (pricing && pricing.mode && pricing.mode !== 'subscription') {
                  const mode = pricing.mode !== 'subscription' ? "One Time Payment" : "Subscription"
                  return (
                    <ListItem
                      key={`${index}-${i}-${pricing.id}`}
                      id={pricing.id}
                      className="fp-15"
                      divider
                      button
                      classes={{
                        root: classes.root,
                        selected: classes.selected
                      }}
                      selected={priceDoc.id === pricing.id}
                      onClick={() => onSelect({
                        ...pricing, ref_name: plan.ref_name,
                        title: plan.title,
                        overrides: plan.overrides
                      })}
                    >
                      <div className="d-flex justify-content-between align-items-center w-100">
                          <ListItemText
                            classes={{
                              multiline: "fm-0",
                            }}
                            primary={
                              <Typography
                                className="fpb-5"
                                classes={{
                                  root: "text-truncate"
                                }}
                                variant="h5"
                              >
                                {plan.ref_name}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                classes={{
                                  root: "text-truncate text-muted"
                                }}
                                variant="body2"
                              >
                                {`${pricing.duration} ${pricing.duration_type.slice(0, -1)}${pluralize(pricing.duration)}${` • ${mode}`}`}
                              </Typography>
                            }
                          />
                      </div>
                    </ListItem>
                  )
                }
                else
                  return null;
              }))
            })}
          </List>
        }
      </div>
    </Dialog>
  );
}
const AssignTrainer = ({ cid, onSave, handleClose, trainer_id, trainer_name }) => {
  const theme = useTheme();
  const { loading , data: trainerList } = useTrainers(cid)
  const [trainer, setTrainer] = useState({ trainer_id, trainer_name })
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      toolbarClass="height-60"
      buttonColor="primary"
      open
      onSave={() => onSave(trainer)}
      onClose={handleClose}
      title="Assign Trainer"
      titleFont="h3"
      paperClass={isMobile ? "width-600" : "width-600 height-70"}
      dialogContentClassName="d-flex flex-column"
      actionText="Assign"
      disablePrimaryAction={!trainer.trainer_id}
    >
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        {loading ? <CircularProgress /> :
          <List className="overflow-auto py-0 w-100 h-100">
            {trainerList.map((item) => {
              const { _id, data } = item
              return (
                <ListItem
                  key={_id}
                  id={_id}
                  className="fpy-15 fpx-20 h-60"
                  divider
                  button
                  onClick={() => setTrainer({
                    trainer_id: _id,
                    trainer_name: data.name
                  })}
                >
                  <ListItemAvatar className="w-mn0">
                    <AvatarImage
                      src={data.image}
                      name={data.name}
                      className="fmr-15 border"
                      size="thumbnail"
                      alt={data.name || " "}
                      styles = {{ fontSize: "15px"}}
                    />
                  </ListItemAvatar>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <ListItemText
                      classes={{
                        multiline: "fm-0",
                      }}
                      primary={
                        <Typography
                          classes={{
                            root: "text-truncate"
                          }}
                          variant="h6"
                        >
                          {data.name}
                        </Typography>
                      }
                    />
                  </div>
                  <ListItemIcon className="w-mn0">
                  <Checkbox
                    color="primary"
                    size='small'
                    checked={_id === trainer.trainer_id}
                    tabIndex={-1}
                    disableRipple
                  />
                  </ListItemIcon>
                </ListItem>
              )
            })}
          </List>
        }
      </div>
    </Dialog>
  );
}

const ConsultoriaMenu = ({
  showLoader,
  hideLoader,
  enqueueSnackbar,
  userDoc
}) => {
  const { cid } = useContext(FirebaseAuthContext);
  const [conflict, toggleConflict] = useToggle();
  if (!userDoc) return null;
  const { uid, consultoria = false } = userDoc;
  if (cid !== 'hibrido' || !uid) return null;
  const genConsultoriaProgram = (evt, force = false) => {
    showLoader();
    consultoriaAPI.genProgram(uid, force)
      .then(resp => {
        const { data: { success, conflict, schId, message } } = resp;
        if (conflict) {
          hideLoader();
          toggleConflict();
        } else if (!success) {
          throw new Error(message);
        } else {
          window.location.href = `/fitness/schedules/${schId}`;
        }
      }).catch(err => {
        hideLoader();
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };
  const forceConsultoriaProgram = () => {
    toggleConflict();
    genConsultoriaProgram(null, true);
  };
  const switch2consultoria = () => {
    showLoader();
    consultoriaAPI.switchConsultoria(uid, consultoria)
      .then(resp => {
        const { data: { success, message } } = resp;
        if (!success) throw new Error(message);
        else window.location.reload();
      }).catch(err => {
        hideLoader();
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };
  return (
    <>
      <MenuItem key='Change email' onClick={genConsultoriaProgram}>
        Generate Consultoria Program
      </MenuItem>
      <MenuItem key='Change email' onClick={switch2consultoria}>
        Turn Consultoria {consultoria ? 'OFF' : 'ON'}
      </MenuItem>
      <hr className='m-0'/>
      <MUIDialog open={conflict} onClose={toggleConflict}> 
        <DialogContent>
          <DialogContentText>
            Consultoria Program already exists. Do you want to regenerate the Consultoria Program from scratch again ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleConflict} color="primary">
            Cancel
          </Button>
          <Button onClick={forceConsultoriaProgram} color="primary">
            Yes, Regenerate Program
          </Button>
        </DialogActions>
      </MUIDialog>
    </>
  );
};

const ConfigDropdown = ({
  planDoc = {},
  userDoc = {},
  showLoader,
  hideLoader,
  enqueueSnackbar,
  setUser,
  setPlan,
  userProfileId,
  onDelete,
  isSubscriptionUser,
  changeClientEmail,
  handleResendEmail,
  reminders = {}, //reminders at aggregate reminders level.
  reminderConfig={}, //reminders at company level .
  setReminders,
  company_prefs,
  check_in_tag_config
}) => {
  const { isOwner, clientAdmin, tEnabled } = useContext(RoleContext);
  const {status,checkAccessFeature} = useAcl();
  const { comp, userProfile } = useContext(FirebaseAuthContext);
  const { maps: { measurables, photos }, fetchTags } = useContext(TagsContext);
  const isVideoCalling = comp && _.get(comp ? comp.data() : {}, 'features.video_calling', false);
  const isGroupClass = comp && _.get(comp ? comp.data() : {}, "features.group_class.enabled", false);
  const isReminder = comp && _.get(comp ? comp.data() : {}, 'features.reminders', false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationOpen, showConfirmation] = useState(false);
  const [type, setType] = useState(null);
  const [hasUserLoggedProgress, setHasUserLoggedProgress] = useState(false)
  const { openBookingDialog } = useLiveSessions();
  const [checkinData, updateCheckInData] = useState({
    checkin_frequency:  7,
    checkin_due_count:  0,
    checkin_overdue_count: 0,
    measurements: {},
    photos: {}
  })
  const assignTrainerId = _.get(userDoc, "trainer_id");
  const loggedInId = _.get(userProfile, "uid");

  useEffect(() => {
    const userLevelNotifyTrainer = _.get(userDoc, 'overrides.checkin_config.notify_trainer');
    const compLevelNotifyTrainer = _.get(comp && comp.data(), 'checkin_config.notify_trainer');
    updateCheckInData({
      checkin_frequency: _.get(userDoc, "overrides.checkin_config.checkin_frequency") ||
        _.get(comp && comp.data(), "checkin_config.checkin_frequency") || 7,
      checkin_due_count: _.get(userDoc, "overrides.checkin_config.checkin_due_count", null) !== null ? _.get(userDoc, "overrides.checkin_config.checkin_due_count", 0) :  _.get(comp && comp.data(), "checkin_config.checkin_due_count", 0),
      checkin_overdue_count: _.get(userDoc, "overrides.checkin_config.checkin_overdue_count", null) !== null ? _.get(userDoc, "overrides.checkin_config.checkin_overdue_count", 0) :  _.get(comp && comp.data(), "checkin_config.checkin_overdue_count", 0), 
      specific_day_checkins: _.get(userDoc, "overrides.checkin_config.specific_day_checkins") ||
      _.get(comp && comp.data(), "checkin_config.specific_day_checkins") || [],
      measurements: _.get(userDoc, "overrides.checkin_config.measurements") ||
      _.get(comp && comp.data(),"checkin_config.measurements",{}),
        // _.get(comp && comp.data(), "checkin_config.measurements") || {},
      photos: _.get(userDoc, "overrides.checkin_config.photos") ||
        _.get(comp && comp.data(), "checkin_config.photos") || {},
      poses: _.get(userDoc, "overrides.checkin_config.poses") ||
        _.get(comp && comp.data(), "checkin_config.poses") || {},
      notify_trainer:
        userLevelNotifyTrainer === undefined || userLevelNotifyTrainer === null
          ? compLevelNotifyTrainer === null || compLevelNotifyTrainer === undefined
            ? true
            : compLevelNotifyTrainer
          : userLevelNotifyTrainer,
    })
  }, [userDoc, comp])

  const remindersOptions = useMemo(()=>{
    const user_config = _.get(reminders, 'user_config', {});
    const out_reminders = _.merge({}, reminderConfig, user_config )
    //merge default and user_config_reminders and return;
    return out_reminders;
  },[reminders, reminderConfig])

  const user_preference = useMemo(()=>{
    const user_prefs = _.get(reminders, 'user_prefs', {});
    const out_pref = _.merge({}, company_prefs, user_prefs);
    return out_pref;
  },[company_prefs, reminders ])

  const checkInConfiguration = useMemo(()=>{
    const checkInReminders = _.get(remindersOptions, "checkins",{});
    if(_.isEmpty(checkInReminders)){
      checkInReminders.day_of_week = _.get(checkinData, `specific_day_checkins.0`)
    }
    return {...checkinData, ...checkInReminders};
  },[checkinData,remindersOptions ])



  
  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const { currPurchase, plan_assign, onboarding_complete, cid, uid } = userDoc
  const isLead = isSelfSignupUser(userDoc);
  const priceDoc = (plan_assign && plan_assign.pack) || (currPurchase && currPurchase.pack) || { id: "custom", duration: userDoc.duration, duration_type: userDoc.durationType, mode: "one_time"};
  const showChangePlanDates = !isLead && !isInactiveUser(userDoc)
  const showCheckInSettings = !isInactiveUser(userDoc) && !isLead
  const showDeleteClient = clientAdmin && !isActiveUser(userDoc)
  const showDeactivateClient = clientAdmin && isActiveUser(userDoc)
  // const showAssignTrainer = isOwner;
  const showVideoSettings =  !isLead && !isInactiveUser(userDoc) && isVideoCalling && clientAdmin;
  const showOrderHistory = isInactiveUser(userDoc);
  const videoDoc = _.get(userDoc, "vid_call", false);
  const purchases = _.get(userDoc, "purchases", {});
  const showAddBooking = isVideoCalling;
  const showReminderSetting = isReminder && (!isInactiveUser(userDoc) && !isLead )//@TODO: verify that check same as checkIn config.


  const [showAssignTrainer, isTrainerFeatureEnabled] = useMemo(()=>{
    if (!isOwner) return [false];
    const isTrainerEnabled = checkAccessFeature(status, ACL_FEATURES_KEY.TRAINERS);
    if(!isTrainerEnabled){
      //for owner if trainer feature is not enabled, and once owner assigned  user to him self, then assign trainer option won't show.
      if(assignTrainerId === loggedInId) return [false];
    }
    return [true, isTrainerEnabled];
  },[isOwner, checkAccessFeature, status, assignTrainerId, loggedInId])

  // const pop = () => {
  //   const pop2 = history.location.pathname.split('/').slice(0, -1).join('/');
  //   history.replace(pop2);
  // };

  const savePreference = async (preference, key) =>{
    const aggCollection = firebase.firestore().collection(`/user_profiles/${userProfileId}/aggregate`);
    const reminderRef = aggCollection.doc('reminders');
    try {
      await reminderRef.set({ user_prefs: { [key]: _.get(preference, key) } }, { merge: true });
      const snap = await reminderRef.get();
      const data = await snap.data();
      setReminders(data);
    } catch (err) {
      throw new Error(err);
    }
  };

  const saveReminders = async(reminders, key)=>{
    //same as handleCheckInSettings in case of checkIns reminders. but here data formats are different here we get only  reminders and saved key.
    try {
      const aggCollection = firebase.firestore().collection(`/user_profiles/${userProfileId}/aggregate`);
      const reminderRef = aggCollection.doc('reminders');
      const editedReminderConfig = _.get(reminders, key);
      const enabled = _.get(reminders,"enabled", false);
      const promises = [];
      let checkInConfig  = _.cloneDeep(checkinData);
      if(key === "checkins"){
        const {checkInReminder, checkInConfig : outCheckInConfig} = getCheckInReminderAndConfig({...editedReminderConfig }, {check_in_tag_config})
        checkInConfig = {...outCheckInConfig};
        const userData = { "overrides.checkin_config": checkInConfig };
        const planData = { ..._.omit(checkInConfig.checkin_config, ['photos', 'measurements']) }; //@TODO: it is invalid.
        promises.push(reminderRef.set({ user_config: {enabled, [key]:checkInReminder } }, { merge: true }))
        if (userDoc.aplan) promises.push(userProfilePlanTransaction(userProfileId, userDoc.aplan, userData, planData));
        else promises.push(usrRepo.update(userProfileId, userData));    
      }else{
        promises.push(reminderRef.set({ user_config: {enabled, [key]:editedReminderConfig } }, { merge: true }))
      }
      await Promise.all(promises);
      const snap = await reminderRef.get();
      const data = await snap.data();
      setReminders(data);
      if(key === "checkins"){
        //update userDoc as well.
        setUser({
          ...userDoc, overrides: { ...(userDoc.overrides || {}), checkin_config : checkInConfig }
        })  
      }
    } catch (error) {
      throw new Error(error);
    }
  }


  const handleChangePlan = async (pack) => {
    const { duration, duration_type } = pack
    const endDate = moment(userDoc.startDate, "YYYYMMDD").add(duration, duration_type).format("YYYYMMDD")
    let durationInDays = multiplier(duration_type) * Number(duration);
    const userData = {
      duration: durationInDays,
      durationType: duration_type,
      endDate,
      plan_assign: {
        pack
      }
    }
    const planData = {
      duration: durationInDays,
      durationType: duration_type,
    }

    try {
      showLoader()
      if(userDoc.aplan) {
        await userProfilePlanTransaction(userProfileId, userDoc.aplan, userData, planData)
      }else {
        await usrRepo.update(userProfileId, userData)
      }

      setUser({
        ...userDoc, ...userData
      })
      setPlan({
        ...planDoc, ...planData
      })
      handleDialogClose()
    } catch (error) {
      console.log(error)
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(error);
    } finally {
      hideLoader()
    }

  }

  const handleDeactivateClient = async () => {
    try {
      showLoader()
      const { data } = await deactivateUser(cid, uid)
      if(!data.success) throw data.message
      let current_plan_status = userDoc.current_plan_status === "activated" ? "expired" : userDoc.current_plan_status
      setUser({
        ...userDoc, deactivated: true, deactivatedOn: new Date().toISOString() ,current_plan_status
      })
      onDelete(userProfileId);
      handleDialogClose()
      // pop();
    } catch (error) {
      const msg = (error.response && (error.response.status === 400)) ? error.response.data.message : DEFAULT_ERROR;
      enqueueSnackbar(msg, { variant: "error" });
      // Sentry.captureException(error);
    } finally {
      hideLoader()
    }
  }

  const handleVideoSettings = async (vid_call, sessions_invite) => {
    try {
      showLoader();
      const videoCall = vid_call || _.get(userDoc, "vid_call");
      const userData = { "vid_call": videoCall};
      if(!_.isEmpty(_.get(userDoc, "plan_assign"))){ //then there must be plan_assign purchases available.
        userData.purchases={...purchases}
        userData.purchases.plan_assign = userData.purchases.plan_assign || {};
        userData.purchases.plan_assign.accessCalls = videoCall;
      }
      if(isGroupClass){ //is group class feature enable then do that stuff...
        userData.purchases = {...purchases, sessions_invite : {...sessions_invite} }  
      }
      await usrRepo.update(userProfileId, userData)
      setUser({
        ...userDoc, ...userData
      })
      handleDialogClose()
    } catch (error) {
      console.log(error)
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(error);
    } finally {
      hideLoader()
    }
  }

  const handleCheckInSettings = async (conf) => {
    const { checkInReminder, checkInConfig } = getCheckInReminderAndConfig(conf, { check_in_tag_config });
    const aggCollection = firebase.firestore().collection(`/user_profiles/${userProfileId}/aggregate`);
    const reminderRef = aggCollection.doc('reminders');
    const userData = { 'overrides.checkin_config': checkInConfig };
    const planData = { ..._.omit(checkInConfig.checkin_config, ['photos', 'measurements', 'poses']) }; //@TODO: it is invalid.
    const promises = [];
    if (userDoc.aplan) promises.push(userProfilePlanTransaction(userProfileId, userDoc.aplan, userData, planData));
    else promises.push(usrRepo.update(userProfileId, userData));
    //preparing reminders
    const reminderEnabled = _.get(remindersOptions, 'enabled', false); //TOP level reminder
    promises.push(
      reminderRef.set({ user_config: { enabled: reminderEnabled, ['checkins']: checkInReminder } }, { merge: true })
    );
    showLoader();
    try {
      await Promise.all(promises);
      //@TODO.is it possible user doesn't have user_config reminders. do we fetch again or set state. i think safe to fetch again
      const snap = await reminderRef.get();
      const data = await snap.data();
      setReminders(data);
      setUser({
        ...userDoc,
        overrides: { ...(userDoc.overrides || {}), checkin_config: checkInConfig },
      });
      handleDialogClose();
    } catch (error) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      Sentry.captureException(error);
    } finally {
      hideLoader();
    }
  }

  const handleUserReminderReset = async () =>{
    showLoader()
    try {
      const aggCollection = firebase.firestore().collection(`/user_profiles/${userProfileId}/aggregate`);
      const reminderRef = aggCollection.doc('reminders');
      await reminderRef.set({ user_config: null }, { merge: true })
      setReminders({...reminders, user_config : null})
      handleDialogClose()
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }
    hideLoader()
  };

  const handleChatEnableDisable = async () => { 
    showLoader()
    try {
      await usrRepo.update(userProfileId, {  enable_chat: !userDoc.enable_chat })
      setUser({
        ...userDoc, enable_chat: !userDoc.enable_chat
      })
      handleDialogClose()
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }finally {
      hideLoader()
    }
  }

  const handleAssignTrainer = async (data) => { 
    showLoader()
    try {
      if(userDoc.aplan) {
        await userProfilePlanTransaction(userProfileId, userDoc.aplan, {...data, _uat: firebase.firestore.FieldValue.serverTimestamp()}, data)
      }else {
        await usrRepo.update(userProfileId, {...data, _uat: firebase.firestore.FieldValue.serverTimestamp()})
      } 
      setUser({
        ...userDoc, ...data
      })
      setPlan({
        ...planDoc, ...data
      })
      handleDialogClose()
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }finally {
      hideLoader()
    }
  }

  const handleDeleteClient = async () => { 
    showLoader()
    try {
      const { data } = await deleteUser(cid, uid)
      if(!data.success) throw data.message
      setUser({})
      onDelete(userProfileId)
      // pop();
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }finally {
      hideLoader()
    }
  }
  const handleUserCheckinReset = async () => {
    showLoader()
    try {
      await usrRepo.update(userProfileId, { overrides: firebase.firestore.FieldValue.delete() })
      setUser({
        ...userDoc, overrides: null
      })
      handleDialogClose()
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    }
    hideLoader()
  }

  const handleAssignMe = async () =>{
    handleAssignTrainer({
      trainer_id:userProfile.uid,
      trainer_name: userProfile.name
    })
  };

  const onClickChangePlanDates = () => { setType("change_plan_dates")}
  const onClickCheckInSettings = () => {setType("checkin_settings") }
  const onClickVideoSettings = () => {setType("video_settings")}
  const onClickAddBooking = () => openBookingDialog(undefined, userDoc)
  const onClickAssignTrainer = () => {setType("assign_trainer") }
  const onClickDeactivateClient = () => { showConfirmation(true); setType("deactivate_client")}
  const onClickDeleteClient = () => { showConfirmation(true); setType("delete_client")}
  const onClickChatEnableDisable = () => { showConfirmation(true); setType("chat_settings") }
  const onClickOrderHistory = () => {setType("order_history")}
  const onClickReminderSetting = ()=> setType("reminders_settings")
  const handleConfirmationChange = () => {
    switch (type) {
      case "deactivate_client": {
        handleDeactivateClient()
        break;
      }
      case "delete_client": {
        handleDeleteClient()
        break;
      }
      case "chat_settings": {
        handleChatEnableDisable()
        break;
      }
      default:
        break;
    }
    showConfirmation(false)
  }
  const getDialogDetail = type => {
    switch (type) {
      case "chat_settings":
        return {
          title: `${userDoc.enable_chat ? "Disable Chat" : "Enable Chat"}`,
          confirmOption: `${userDoc.enable_chat ? "Yes, Disable" : "Yes, Enable"}`,
          msg: `Are you sure you want to ${userDoc.enable_chat ? "disable" : "enable"} chat for this client? ${userDoc.enable_chat ? 'Client will be unable to send any messages to you. They will continue to receive your messages.': ''}`
        }
      case "deactivate_client":
        return {
          title: "Deactivate Client",
          confirmOption: "Yes, Deactivate",
          msg: "Are you sure you want to deactivate this client? After deactivation, the client will be unable to view their future schedule.",
          submsg: isSubscriptionUser && 'NOTE: Client has an active subscription, if you choose to proceed the subscription will be cancelled with immediate effect. This process cannot be reverted'
        }
      case "delete_client":
        return {
          title: "Delete this client? ",
          confirmOption: "Yes, Delete",
          msg: 'The client will be logged out and will no longer be able to access the app. Are you sure you want to delete this client?',
          confirmActionClassName: 'f-sm-med bg-red',
          submsg: isSubscriptionUser && 'NOTE: Client has an active subscription, if you choose to proceed the subscription will be cancelled with immediate effect. This process cannot be reverted'
        }
      case "checkin_settings":
        return {
          title: "Change Check-in Settings",
        }
      case "change_plan":
        return {
          title: "Change Plan",
        }
      case "change_plan_dates":
        return {
          title: "Change Plan Dates"
        }
      case "assign_trainer":
        return {
          title: "Assign Trainer"
        }
      case "video_settings":
        return {
          title: "Change Video Settings"
        }
      default:
        return {};
    }
  }
  const { title, confirmOption, msg, submsg, confirmActionClassName } = getDialogDetail(type)
  const handleDialogClose = () => setType(null)

  const renderDialogChildren = (type)=> {
    switch (type) {
      case "checkin_settings":{
        return <CheckinForm 
                  config={checkInConfiguration} 
                  measurables={measurables} 
                  photosData={photos}
                  disableSpecificDayCheckin 
                  onCancel={handleDialogClose}
                  user
                  hideReset={!userDoc.overrides}
                  checkInTagConfig={check_in_tag_config}
                  handleUserCheckinReset={handleUserCheckinReset}
                  handleUserReminderReset={handleUserReminderReset}
                  onSubmit={handleCheckInSettings}/>
      }
      case "change_plan":
        return <ChangePlan cid={userDoc.cid} handleClose={handleDialogClose} onSelect={handleChangePlan} priceDoc={priceDoc} isSubscriptionUser={isSubscriptionUser}/>
      case "change_plan_dates":
        return <ChangePlanDates handleClose={handleDialogClose} userDoc={userDoc} planDoc={planDoc} userProfileId={userProfileId} hasUserLoggedProgress={hasUserLoggedProgress} />
      case "assign_trainer":
        return <AssignTrainer cid={userDoc.cid} handleClose={handleDialogClose} onSave={handleAssignTrainer} trainer_id={userDoc.trainer_id} trainer_name={userDoc.trainer_name}/>
      case "video_settings":
        return <ChangeVideoSettings handleClose={handleDialogClose} onSave={handleVideoSettings} vid_call={videoDoc}  sessions_invite={_.get(purchases, "sessions_invite", false)} />
      case "order_history":
        return <OrderHistory handleClose={handleDialogClose} id={userProfileId} {...userDoc} />
        case "reminders_settings":
          return <ReminderSettings checkInConfig={checkinData} saveToDB={saveReminders} savePreferenceToDb={savePreference} user_preference={user_preference} handleClose={handleDialogClose} reminders={remindersOptions} />  
      default:
        return null;
    }
  }
  
  useEffect(()=> {
    if(type !== "checkin_settings") return;
    Promise.all([fetchTags('photos'), fetchTags('measurables')]);
  }, [type, fetchTags]);

  useEffect(() => {
    if(userProfileId && userDoc.aplan){
      (async () => {
        const aggCollection = firebase
        .firestore()
        .collection(userPlanPath(userProfileId, userDoc.aplan, 'aggregate'))
        const [woDoc, mlDoc] = await Promise.all([aggCollection.doc('workout').get(), aggCollection.doc('nutrition').get()])
        if(woDoc.exists || mlDoc.exists) setHasUserLoggedProgress(true)
      })()
    }
  }, [userProfileId,userDoc.aplan])

  return (
    <>
      <IconButton className="fp-10 mx-2" onClick={handleClick}>
        <MenuSharp />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        elevation={4}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        onClick={handleClose}
      >
        <ConsultoriaMenu userDoc={userDoc} showLoader={showLoader} hideLoader={hideLoader} enqueueSnackbar={enqueueSnackbar}/>
        {/* {!!tEnabled && showAssignTrainer && 
          <MenuItem key='Assign Trainer' onClick={onClickAssignTrainer}>
            Assign Trainer
          </MenuItem>
        } */}
        {
         !!showAssignTrainer && <>
          {isTrainerFeatureEnabled ? <MenuItem key='Assign Trainer' onClick={onClickAssignTrainer}>
            Assign Trainer
          </MenuItem> : <MenuItem key="Assign to me " onClick={handleAssignMe}> Assign to me </MenuItem>}
        </>
        }
        <MenuItem key='Change email' onClick={changeClientEmail}>
          Change Email
        </MenuItem>
        {!onboarding_complete &&
          <MenuItem key='Resend email' onClick={handleResendEmail}>
            Resend Email
          </MenuItem>
        }
        {showChangePlanDates &&
          <MenuItem key='Change Plan Dates' onClick={onClickChangePlanDates}>
            Change Plan Dates
          </MenuItem>
        }
        {showAddBooking &&
          <MenuItem key={"Video Booking"} onClick={onClickAddBooking}>
            Schedule a Video Call
          </MenuItem>
        }
        {showVideoSettings &&
          <MenuItem key={"Video settings"} onClick={onClickVideoSettings}>
            Video Call Settings
          </MenuItem>
        }
        {showCheckInSettings &&
          <MenuItem key={"Check-in settings"} onClick={onClickCheckInSettings}>
            Check-in Settings
          </MenuItem>
        }
        { showReminderSetting && <MenuItem key="reminders_settings" onClick={onClickReminderSetting}>
            Reminder Settings
          </MenuItem>
        }
        { showDeactivateClient && <MenuItem key={"Deactivate Client"} onClick={onClickDeactivateClient}>
          Deactivate Client
        </MenuItem>
        }
        {showOrderHistory && <MenuItem key={"Order History"} onClick={onClickOrderHistory}>
          Order History
        </MenuItem>
        }
        {
          showDeleteClient &&
          <MenuItem key={"Delete Client"} onClick={onClickDeleteClient}>
            Delete Client
        </MenuItem>
        }
        <MenuItem key={"Enable/disable chat"} onClick={onClickChatEnableDisable}>
          {`${userDoc.enable_chat ? "Disable Chat" : "Enable Chat"}`}
        </MenuItem>
      
      </Menu>
      {isConfirmationOpen && (
      <Confirmation
        open
        handleClose={() => showConfirmation(false)}
        handleChange={handleConfirmationChange}
        handleCancel={() => showConfirmation(false)}
        title={title}
        msg={msg}
        submsg={submsg}
        confirmOption={confirmOption}
        confirmActionClassName={confirmActionClassName}
      />
      )}
      {renderDialogChildren(type)}
    </>
  );
};

const NavBar = props => {
  const userData = props.userDoc;
  const { clientAdmin } = useContext(RoleContext);
  const { controller } = useContext(UserSchContext);
  const [openChangeDates, toggleChangeDates] = useState(false);
  const is_subscription = (userData.currPurchase ? _.get(userData, "currPurchase.pack.mode") : _.get(userData, "plan_assign.pack.mode")) === "subscription" ;
  const toggleReassignPlan = () => controller.reassign(props.userDoc, props.packDoc);
  const assignPlan = () => controller.assignPlan(props.userDoc);
  function returnControls({ handleReactivation,  }) {
    switch (true) {
      case !isActiveUser(userData) && !isInactiveUser(userData): {
        let mode = props.userDoc["aplan"] && hasLength(props.planDoc) && props.weeks && hasLength(props.weeks) ? "activate" : "assign";
        let isPlanAssigned  =  !!_.get(props,"userDoc.currPurchase") || !!_.get(props,"userDoc.plan_assign");    
        if (!!mode) {
          let text = mode === "assign" ? (isPlanAssigned ? 'Assign Schedule' :'Assign Plan') : (getUserPlanEndDate(props.userDoc).add(_.get(props, 'userDoc.subs_duration', 0), 'days').isBefore(moment()) ? 'Change Dates': 'Activate')
          let action = mode === "assign" ? (isPlanAssigned ? assignPlan : toggleReassignPlan) : (getUserPlanEndDate(props.userDoc).add(_.get(props, 'userDoc.subs_duration', 0), 'days').isBefore(moment()) ? toggleChangeDates : props.activateplan)
          return (
            <ClrdButton
              onClick={
                action
              }
              className="f-sm-med fpx-15"
              color="main"
              variant="contained"
            >
              {_.upperFirst(text)}
            </ClrdButton>
          );
        }
        else return null;
      }
      case isInactiveUser(userData): {
        if (_.get(props, "userDoc.deactivated") && getUserPlanEndDate(props.userDoc).isAfter(moment(), 'day')) {
          if (!clientAdmin) return null
          return <ClrdButton
          className="f-sm-med fpx-15"
          color="main"
          variant="contained"
          onClick={handleReactivation}
          >
              {_.upperFirst("Reactivate")}
          </ClrdButton>
        } else if (props.isExtendPlanEnable) {
          if (!is_subscription) return (<ClrdButton
            className="f-sm-med fpx-15"
            color="main"
            variant="contained"
            onClick={controller.extend}
          >
            {_.upperFirst("Extend Plan")}
          </ClrdButton>)
        } else if (getUserPlanEndDate(props.userDoc).isBefore(moment(), 'day')) {
          return <ClrdButton
            className="f-sm-med fpx-15"
            color="main"
            variant="contained"
            onClick={toggleReassignPlan}
            style={{ whiteSpace: "nowrap" }}
          >
              {_.upperFirst("Assign New Plan")}
          </ClrdButton>
        } else return null
        break;
      }
      default:
        return null;
    }
  }
  const handleReactivation  = async () =>  {
    const { showLoader, hideLoader, setUser, setPlan, enqueueSnackbar, userProfileId, userDoc, planDoc } = props;
    showLoader()
    try {
      let current_plan_status = "activated"
      let userData = { deactivated: false, current_plan_status, plan_assign: planDoc.purchase || null }
      let subs_duration = _.get(planDoc, 'purchase.pack.mode') === 'subscription' ? planDoc.duration + 28 : null
      if (userDoc.aplan) {
        await userProfilePlanTransaction(userProfileId, userDoc.aplan, userData, { plan_state: current_plan_status, grace_duration: firebase.firestore.FieldValue.delete(), ...(subs_duration ? { subs_duration } : {}) })
      } else {
        await usrRepo.update(userProfileId, userData)
      }
      setUser({
        ...userDoc, ...userData
      })
      if (userDoc.aplan) {
        setPlan({
          ...planDoc, plan_state: current_plan_status, grace_duration: null, ...(subs_duration ? { subs_duration } : {}) 
        })
      }
    } catch (err) {
      enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
      Sentry.captureException(err);
    } finally {
      hideLoader()
    }
  }
  const controls = returnControls({...props, handleReactivation})
  return (
    <div className="d-flex bg-white border-bottom">
      {!props.loading && <SlideButtons {...props}/>}
        <div className={`flex-shrink-0 d-flex justify-content-end align-items-center ${props.loading ? 'vis-collapse' : ''}`}>
        {controls}
        <ConfigDropdown {...props} isSubscriptionUser={is_subscription}/>
      </div>
      {
        openChangeDates &&
        <ChangePlanDates
          handleClose={() => toggleChangeDates(false)}
          userDoc={props.userDoc}
          planDoc={props.planDoc}
          userProfileId={props.userProfileId}
        />
      }
    </div>
  );
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const {
    delete: deleteNewUsersList
  } = newUsersListRdxFns(d);
  const {
    delete: deleteActiveUsersList
  } = activeUsersListRdxFns(d);
  const {
    setPlan, setUser, setSchedule
  } = selectedScheduleRdxFns(d);
  return {
    showLoader,
    hideLoader,
    setPlan,
    setUser,
    setSchedule,
    deleteNewUsersList,
    deleteActiveUsersList
  };
};

export default withSnackbar(
  connect(null, mapDispatchToProps)(NavBar)
);
