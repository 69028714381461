import React from 'react';
import { ListItem, ListItemText,ListItemAvatar } from '@material-ui/core';
import {ListStyling} from "./";
import Avatar from "./listAvatar";
import clsx from 'clsx';
import {get} from 'lodash';

function _parseListData(l){
    const obj={
            primaryText:get(l,'data.ref_name')||get(l,'data.name'),
            src:get(l,'data.thumbnail') || get(l,'data.image'),
        }
        return {...obj};
}
//Note: Do not pass keyName or put conditions inside this component
export const DumbList=({docs=[],parseListData=_parseListData,...rest})=>{
    function _isSelected(doc){
        return selected===doc._id;
    }
    const {
        onClick,
        avatarProps={},
        selected=null,
        isSelected=_isSelected,
        truncatePrimaryText=true,
        ListItemSecondaryAction=null,
        hideAvatar=false,
        idPrefix = ""
    }=rest;
    return(
        docs.map((l,i)=>{
            const {primaryText,secondaryText,src,icon}=parseListData(l);
            const selected=isSelected(l);
            return(
                <ListStyling key={l._id}>
                    <ListItem 
                        id={idPrefix + l._id} 
                        onClick={(e)=>{
                            if(onClick) onClick(e,l);
                        }} 
                        selected={selected}
                        button={!!onClick} 
                        >
                        <ListItemAvatar className={clsx(hideAvatar?"d-none":"")}>
                            <>
                            <Avatar src={src} name={primaryText} {...avatarProps}/>
                            {icon && <img className="icon" src={icon} alt=""/>}
                            </>
                        </ListItemAvatar>
                        <ListItemText
                            primary={primaryText}
                            secondary={secondaryText}
                            classes={{
                                primary:clsx(truncatePrimaryText && 'truncate'),
                                secondary:clsx(!secondaryText && "d-none")
                            }}/>
                        {ListItemSecondaryAction && <ListItemSecondaryAction index={i} doc={l}/>}
                    </ListItem>
                </ListStyling>
            )
        })
    )
}