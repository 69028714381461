import React, { useContext, useMemo } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { SCHEDULE_MAX_VALID_DAYS } from 'fitbud/utils/constants';
import { multiplier } from 'fitbud/views/schedulePlanner/helper';
import _, {get} from 'lodash';
import { PlanContext } from './planProvider';
import AppleIcon from 'fitbud/images/apple_icon.svg';

export const RenderPlanChip = (props) => {
  const { iosPackMode, androidPackMode } = useContext(PlanContext);
  const { ios = false, android = false, pricesIOS = [], pricesPlay = [], vidCallData = {} } = props;

  const yesOnAND = useMemo(() => {
    if (ios || android) return true; // nothing to check, since we are in specific mode
    if (
      _.findIndex(pricesPlay, (x) => {
        return !!x.play_product_id && getActiveStatus(x, "active_android");
      }) >= 0
    )
      return false; // if this pack has an actual IAP, then that overrides everything else
    if (androidPackMode === 'all') return true;
    if (androidPackMode === 'video_calls') return vidCallData && vidCallData.includes_calls;
    return false;
  }, [android, ios, androidPackMode, pricesPlay, vidCallData]);

  const yesOnIOS = useMemo(() => {
    if (ios || android) return true; // nothing to check, since we are in specific mode
    if (
      _.findIndex(pricesIOS, (x) => {
        return !!x.iap_id && x.method_ios === 'iap' && getActiveStatus(x, "active_ios");
      }) >= 0
    )
      return false; // if this pack has an actual IAP, then that overrides everything else
    if (iosPackMode === 'all') return true;
    if (iosPackMode === 'video_calls') return vidCallData && vidCallData.includes_calls;
    return false;
  }, [android, ios, iosPackMode, pricesIOS, vidCallData]);

  if (ios || android) {
    const prices = ios ? pricesIOS : pricesPlay;
    if (!prices || !prices.length) return null;
    const label = ios ? 'iOS' : 'Android';
    return (
      <div className="d-flex flex-row align-items-center">
        <Typography color="textSecondary" variant="caption" className="font-weight-500 fml-20">
          Available only on
        </Typography>
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>{label}</Typography>}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <Typography color="textSecondary" variant="caption" className="font-weight-500 fml-20">
        Available on
      </Typography>
      <Chip
        className="fml-10 rounded-pill text-black bg-grey-new"
        label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>Web</Typography>}
      />
      {yesOnAND && (
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>Android</Typography>}
        />
      )}
      {yesOnIOS && (
        <Chip
          className="fml-10 rounded-pill text-black bg-grey-new"
          label={<Typography style={{ fontWeight: 500, fontSize: 11 }}>iOS</Typography>}
        />
      )}
    </div>
  );
};

export const pricingValidation = (state) => {
  const { modeType = '' } = state || {};
  let err = {};
  let out = true;
  if (!state.duration || parseInt(state.duration) !== state.duration) {
    err.duration = modeType === 'subscription' ? 'Please choose a billing cycle' : 'Please enter a valid duration';
    out = false;
  }
  if (!state.price) {
    err.price = 'Please enter a valid price';
    out = false;
  }

  if (!!state.trial_duration_type) {
    if (!state.trial_duration_type) {
      err.trial_duration = 'Please enter a valid duration';
      out = false;
    }
  }

  if (!!state.enable_trial_pack && !state.trial_duration_type) {
    err.trial_duration = 'Please select a valid duration';
    out = false;
  }

  if (!!state.discount_enabled) {
    if (!state.discounted_price) {
      err.discounted_price = 'Please enter a valid discounted price';
      out = false;
    } else if (state.discounted_price >= state.price) {
      err.discounted_price = 'Discounted price cannot be more than the actual price';
      out = false;
    }
  }

  const durationInDays = state.duration * multiplier(state.duration_type);
  if (durationInDays > SCHEDULE_MAX_VALID_DAYS) {
    err.duration = `A plan of more than ${SCHEDULE_MAX_VALID_DAYS} days cannot be created`;
    out = false;
  }
  return { out, err };
};

export const disableWhenChallengePlan = (doc) => {
  if (!getActiveStatus(doc, "active")) { 
    return { disable: true };
  } else {
    const listIndex = _.findIndex(doc.price_opts, ['mode', 'challenge']);
    return { listIndex, disable: listIndex === -1 ? false : true };
  }
};

export const renderInsertData = (doc) => {
  const data = doc.data();
  return {
    ref_name: data.ref_name,
    image: data.image,
    active: getActiveStatus(data,"active"),
    vidCall: data.vid_call,
    iap: data.iap_ids,
    price_opts: data.price_opts,
  };
};

export const renderHeading = ({ mode, title }) => {
  switch (mode) {
    case 'subscription':
      return `${title} Subscription`;
    case 'one_time':
      return `One Time Payment • ${title}`;
    case 'challenge':
      return `Challenge • ${title}`;
    default:
      return `One Time Payment • ${title}`;
  }
};

export const PriceAvatar = (props) => {
  const { discount, currency, isIAP = false, isPlay = false } = props;
  return (
    <div
      className="bg-offWhite rounded-lg d-flex flex-column justify-content-center align-items-center"
      style={{ fontSize: 20, width: 90, height: 60 }}>
      {isIAP && <img alt="Apple In-App Purchase" src={AppleIcon} />}
      {isPlay && <i className="fab fa-google-play" />}
      {!isIAP && !isPlay && (
        <>
          <Typography variant="body1" className="font-weight-700">
            {new Intl.NumberFormat(`${currency}`).format(_.round(discount, 2))}
          </Typography>
          <Typography variant="caption" className="font-weight-500">
            {currency}
          </Typography>
        </>
      )}
    </div>
  );
};

export const PricingDefaultValue = () => {
  return {
    duration: 0,
    price: 0,
    discounted_price: 0,
    currency: 'USD',
    mode: 'one_time',
    duration_type: 'weeks',
    discount_enabled: false,
    activeV2: true,
  };
};

export const ReorderInputText = (item) => {
  let title = '';
  if (item.mode === 'subscription' && item.subs_duration_type) title = `${_.capitalize(item.subs_duration_type)}ly`;
  if (item.mode === 'one_time') {
    return `${item.duration} ${item.duration_type}`;
  } else {
    return `${item.iap_id ? `${item.mode} (${item.iap_id})` : `${title} ${item.mode}`}`;
  }
};

export const VideoCallDefaultValue = () => {
  return {
    duration: 0,
    frequency: 'week',
    includes_calls: false,
    num_sessions: 0,
  };
};

export const parsePack = (doc) => {
  if (!doc || !doc.data) return null;
  const data = doc.data();
  if (data.archive) return null;
  return {
    _id: doc.id,
    index: data.index || 0,
    data,
  };
};

export const orderPacks = (docs) => {
  const activePacks = [];
  const hiddenPacks = [];
  const inactivePacks = [];
  (docs || []).forEach((doc) => {
    if (getActiveStatus(doc.data, "active")) activePacks.push(doc);
    else if (!!doc.data.hidden) hiddenPacks.push(doc);
    else inactivePacks.push(doc);
  });
  return [
    ..._.sortBy(activePacks, ['data.index']),
    ..._.sortBy(hiddenPacks, ['data.index']),
    ..._.sortBy(inactivePacks, ['data.index']),
  ];
};

export const parsePacks = (docs, isGroupClass) => {
  const out = [];
  docs.forEach((doc) => {
    const object = parsePack(doc);
    if(!object) return;
    if(!isGroupClass && _.get(object, "data.add_on_type") === "group_class") return;
    out.push(object);
  });
  return orderPacks(out);
};

export const getGcIdsFromAccess = (access, id) => {
  const packsAccess = _.get(access, 'packs');
  const gcsForPack = _.get(packsAccess, `${id}.classes`, []);
  const except = _.get(packsAccess, `${id}.except`, []);
  const gcsToAllPacks = _.get(access, 'classes.all.packs', []);
  const gcIds = [...gcsForPack, ...gcsToAllPacks, ...except];
  return _.filter(gcIds, (id) => id !== 'all');
};

export const getAccess = (packId, packAccess, access) => {
  //pack previous access ..
  const packPreviousAccess = _.get(access, `packs.${packId}`, {});
  const packPreviousClassAccess = _.get(packPreviousAccess, 'classes', []);
  const packPreviousExceptAccess = _.get(packPreviousAccess, 'except', []);
  //whole access object
  access = _.cloneDeep(access);
  let packsAccess = _.get(access, 'packs', {});
  let classesAccess = _.get(access, 'classes', {});
  const packsAllClassesAccess = _.get(packsAccess, 'all.classes', []);
  const classesAllPackAccess = _.get(classesAccess, 'all.packs', []);
  //upcoming access ...

  const packClassesAccess = _.get(packAccess, 'access', []);
  const packExceptAccess = _.get(packAccess, 'except', []);
  const isPackAccessToAllClasses = _.includes(packClassesAccess, 'all');

  //if access to all classes then add to the all ->classes : []
  if (isPackAccessToAllClasses) {
    if (!_.includes(packsAllClassesAccess, packId)) packsAllClassesAccess.push(packId);
  } else {
    //if it is not all classes access then, if found in all access then remove form allAccess..
    const index = _.findIndex(packsAllClassesAccess, (id) => id === packId);
    if (index > -1) packsAllClassesAccess.splice(index, 1);
  }

  //now assign updated..... packId access ....
  packsAccess = {
    ...packsAccess,
    [packId]: {
      ...get(packsAccess,[packId],{}),
      classes: packClassesAccess,
      except: packExceptAccess,
    },
    all: {
      ..._.get(packsAccess, 'all', {}),
      classes: [...packsAllClassesAccess],
    },
  };

  /* --------- NOW WORKING IN REVERSE ----------
    impact on classes access :::::
         these factor will impact on classes access factors : 
            1 * selected Access,
            2 * classes which have all access .... 
            3 * removed Selected Access ..
            4 * Except Access 
            5 * Removed Except Access ....
  */
  const selectedClasses = _.filter(packClassesAccess, (classId) => !['all', 'free'].includes(classId));
  selectedClasses.forEach((classId) => {
    const classIdAccess = _.get(classesAccess, classId, {});
    const classPackAccess = _.get(classIdAccess, 'packs', []);
    const classExceptAccess = _.get(classIdAccess, 'except', []);
    const isClassAccessToAllPack = _.includes(classPackAccess, 'all');
    const isClassFreeAccess = _.includes(classPackAccess, 'free');
    if (isClassFreeAccess) return; //if class has free access then do noting...
    //case  1 : if pack is not all access then add classId to that packAccess,
    //case  2 : if pack is all access then check if it is found in except then removed ...
    if (!isClassAccessToAllPack) {
      //case 1
      if (!_.includes(classPackAccess, packId)) classPackAccess.push(packId);
    } else {
      //case 2 .
      const index = _.findIndex(classExceptAccess, (id) => id === packId);
      if (index > -1) classExceptAccess.splice(index, 1);
    }
    classesAccess = {
      ...classesAccess,
      [classId]: {
        packs: classPackAccess,
        except: classExceptAccess,
      },
    };
  });
  /* factor 2  ::: packs which have all access,  when pack has specific access....  */
  if (selectedClasses.length) {
    classesAllPackAccess.forEach((classId) => {
      const classIdAccess = _.get(classesAccess, classId, {});
      const classPackAccess = _.get(classIdAccess, 'packs', []);
      const classExceptAccess = _.get(classIdAccess, 'except', []);
      const isClassAccessToAllPack = _.includes(classPackAccess, 'all');
      const isClassFreeAccess = _.includes(classPackAccess, 'free');
      if (isClassFreeAccess) return null; //case not produce logically, but using for safe..
      //class id, which is includes in specific access handle in case 1 , just above. (if found in except  then remove it);
      if (packClassesAccess.includes(classId)) return;
      if (!classExceptAccess.includes(packId)) classExceptAccess.push(packId);
      classesAccess = {
        ...classesAccess,
        [classId]: {
          packs: classPackAccess,
          except: classExceptAccess,
        },
      };
    });
  }
  //new case A :
  if(isPackAccessToAllClasses && !packExceptAccess.length){
    classesAllPackAccess.forEach((classId) => {
      const classIdAccess = _.get(classesAccess, classId, {});
      const classPackAccess = _.get(classIdAccess, 'packs', []);
      const classExceptAccess = _.get(classIdAccess, 'except', []);
      const index = _.findIndex(classExceptAccess, (id)=>id === packId);
      if(index > -1){
        classExceptAccess.splice(index, 1)
      }
      classesAccess = {
        ...classesAccess,
        [classId]: {
          packs: classPackAccess,
          except: classExceptAccess,
        },
      };
    });

  }


  /* factor 3 :  removed Selected Access .. */
  const removedPackClassAccess = _.filter(
    packPreviousClassAccess,
    (classId) => !['all', 'free', 'trial'].includes(classId) && !packClassesAccess.includes(classId)
  );

  removedPackClassAccess.forEach((classId) => {
    const classIdAccess = _.get(classesAccess, classId, {});
    const classPackAccess = _.get(classIdAccess, 'packs', []);
    const classExceptAccess = _.get(classIdAccess, 'except', []);
    const isClassAccessToAllPack = _.includes(classPackAccess, 'all');
    const isClassFreeAccess = _.includes(classPackAccess, 'free');
    if (isClassFreeAccess) return;
    /* 
     cases here .... removed classes 
         case 1 : removed class has specific access ....
         case 2 : removed class has all access ...        
                 
    */
    if (!isClassAccessToAllPack) {
      const index = _.findIndex(classPackAccess, (id) => id === packId);
      if (index > -1) classPackAccess.splice(index, 1);
    } else {
      /*case 2 : if class id has all access,
             here cases according to the pack Id Access .
             sub case 1 : pack has all access : 
             sub case 3 : pack has specific access : (Handle in Factor 1 )
             sub case 4 : pack has some except access :  (Will handle in factor 4 )   
                    //new case A, suppose pack is totally for all classes... ie no except
                        in that case, removed this pack from except of all classes which have all packs access.

        */
      const index = _.findIndex(classExceptAccess, (id) => id === packId);
      if (index > -1) classExceptAccess.splice(index, 1);
    }
    classesAccess = {
      ...classesAccess,
      [classId]: {
        packs: classPackAccess,
        except: classExceptAccess,
      },
    };
  });

  /* Factor 4 ::::  Except Access */
  packExceptAccess.forEach((classId) => {
    const classIdAccess = _.get(classesAccess, classId, {});
    const classPackAccess = _.get(classIdAccess, 'packs', []);
    const classExceptAccess = _.get(classIdAccess, 'except', []);
    const isClassAccessToAllPack = _.includes(classPackAccess, 'all');
    const isClassFreeAccess = _.includes(classPackAccess, 'free');
    if (isClassFreeAccess) return;
    /* here sub cases :
         1. class has all access, if not found in except then add it.
         2 . class has partial access : then found in access then remove it..
    */
    if (isClassAccessToAllPack) {
      if (!classExceptAccess.includes(packId)) classExceptAccess.push(packId);
    } else {
      //has specific access case 2 :
      const index = _.findIndex(classPackAccess, (id) => id === packId);
      if (index > -1) classPackAccess.splice(index, 1);
    }
    classesAccess = {
      ...classesAccess,
      [classId]: {
        packs: classPackAccess,
        except: classExceptAccess,
      },
    };
  });

  const removeExceptPack = _.filter(packPreviousExceptAccess, (classId) => !packExceptAccess.includes(classId));
  removeExceptPack.forEach((classId) => {
    const classIdAccess = _.get(classesAccess, classId, {});
    const classPackAccess = _.get(classIdAccess, 'packs', []);
    const classExceptAccess = _.get(classIdAccess, 'except', []);
    const isClassAccessToAllPack = _.includes(classPackAccess, 'all');
    const isClassFreeAccess = _.includes(classPackAccess, 'free');
    if (isClassFreeAccess) return;
    /* sub cases ::
        case 1 : has all access ,
        case 2 : has specif access 

        now packId has may be : 
              all access, 
              specific access
               
    */
    if (isClassAccessToAllPack) {
      const index = _.findIndex(classExceptAccess, (id) => id === packId);
      if (index > -1) classExceptAccess.splice(index, 1);
    }
    classesAccess = {
      ...classesAccess,
      [classId]: {
        packs: classPackAccess,
        except: classExceptAccess,
      },
    };
  });

  return {
    ...access,
    classes:classesAccess,
    packs:packsAccess
  };
};

export const getCopyOfPlan = (doc = {}, index) => {
  const obj={
    //basics
    index: index,
    title: doc.title,
    ref_name: `Copy of ${doc.ref_name}`,
    image: doc.image || null,
    description: doc.description || '',
    active: false,
    activeV2:false,
    active_ios: false,
    active_iosV2:false,
    //pricing:
    iap_ids: [],
    pc_ids: [],
    price_opts: [],
    //calling
    vid_call: doc.vid_call || {},
    //points
    how_it_work: doc.how_it_work || [],
    plan_includes: doc.plan_includes || [],
    //automation
    automation: doc.automation || {},
  }
  if (doc.type) obj.type = doc.type;
  if (doc.add_on_type) obj.add_on_type = doc.add_on_type;
  return {...obj};
};


export const getActiveStatus = (data, key) =>{
  const keyV2 = key + "V2";
  return _.get(data, keyV2) === undefined ? _.get(data, key) : _.get(data, keyV2);
}
