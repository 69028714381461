import _, { get,sum,cloneDeep } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import uuidv4 from "uuid/v4";
import firebase from "fitbud/firebase";
import update from "immutability-helper";
import { IconButton,Divider,Card,CardHeader,CardContent,DialogActions, Button } from "@material-ui/core";
import Dialog from "fitbud/components/Dialog";
import woRepo from "fitbud/repo/workouts";
import exRepo from "fitbud/repo/exercises";
import woRdxFns from "fitbud/redux/workouts";
import woCardioRdxFns from "fitbud/redux/wocardio";
import appRdxFns from "fitbud/redux/app";
import fileUploadRdxFns from "fitbud/redux/fileUpload";
import CreateEditForm from "fitbud/views/workouts/createEditForm";
import ExerciseEditor from "fitbud/views/workouts/exerciseEditor";
import {VideoEditor} from "./videoEditor";
import Details from "fitbud/views/workouts/details";
import { getTags, uploadFile } from "fitbud/utils/services";
import Confirmation from "fitbud/components/confirmationDialog";
import * as Sentry from '@sentry/browser';
import ReorderDialog from "fitbud/components/reorderDialog";
import {EditorPlaceholder} from "fitbud/views/exercises/editorPlaceholder";
import DetailEditIcon from "fitbud/icons/detailEdit";
import {calculateSegmentDuration,calculateEquipmentsNBodyParts,mapIdToObject,exportTags,sanitizeBodyParts, videoExists, mediaObjExist} from "./helperfn";
import addIcon from "fitbud/images/plus.svg";
import { ClrdButton } from "fitbud/components/form-fields";
import clsx from "clsx";
import {
  ERROR_MIN_DURATION,
  ERROR_TITLE,
  ERROR_REF_NAME,
  ERROR_DESCRIPTION,
  WORKOUTS_STORAGE_FILE_PATH,
  DEFAULT_ERROR,
  ERROR_MEDIA_URL,
  ERROR_YOUTUBE_URL,
  ERROR_VIMEO_URL,
  ERROR_MEDIA_UPLOAD,
  YOUTUBE_VIDEO_URL_REGEX,
  VIMEO_VIDEO_URL_REGEX,
  FILE_STATUS,
  HUBSPOT_PROPS,
  EXERCISE_SINGLE_REP_DURATION,
  DRAFT_STATES,
  PUBLISHED,
  DRAFT,
  VIEWS_CONSTANTS,
  MUSIC_ON,
  DEFAULT_VID_UPLOAD_ERROR,
  DISCARD_MSG,
  EMPTY_WO_DISCARD_MSG,
  OFFLINE_ERROR,
  FETCH_ERROR,
  ERROR_CARIO_DUR
} from "fitbud/utils/constants";
import { FirebaseAuthContext } from "fitbud/providers/firebase-auth";
import CircularLoader from "fitbud/components/CircularLoader";
import PageNotFound from "fitbud/views/pageNotFound";
import { refreshDoc,isOnline } from "fitbud/utils/helpers";
import { bffUpdateHubspotProp } from "fitbud/api";
import {DetailPgStyling} from "fitbud/hooks/useDetailPgStyle";
import {DraftTag,DraftText} from "fitbud/components/draftTag";
import {SaveIcon} from "fitbud/icons/saveIcon";
import ViewPublishedIcon from '@material-ui/icons/VisibilityOutlined';
import ViewingPublishedIcon from '@material-ui/icons/VisibilityOffOutlined';
import {PublishedDrawer} from "./publishdedDrawer";
import {CustomConfirmation} from "fitbud/components/customConfirmation";
import { createVidObject } from "fitbud/api";
import {calculatePublishStatus,checkErrInMediaUpload} from "fitbud/utils/catalog";
import {createTrackFromExr,createNewExStateFromOld,getRefTypeFromTrack} from "fitbud/views/workouts/components/valuePopupNew";
import LibraryConfirmation from "./components/libraryConfirmation";
export const TRACKING_FIELDS=['ref_type','type','values','side','weight_type','weights',
'track','gender','male1','female1','type2','male2','female2','tempo'
];
const bps2taz = require("fitbud/views/exercises/bps2taz");
const getExAddState=(cid,d={},grpType)=>{
  const exType=get(d,['data','extype'])||"reps";
  const exr={
    ref: firebase.firestore()
    .doc(`companies/${cid}/exercises/${d._id}`),
    values:[15],
    rest:[30],
    side:get(d, "data.side", ""),
    type: "fixed",
    gender:false,
  }
  if(grpType==="tabata"){
    exr.values=[20];
    exr.rest = [10];
  }
  exr.track=createTrackFromExr({ref_type:exType});
  exr.ref_type=getRefTypeFromTrack(exr.track,grpType);
  exr.male1=[...exr.values];
  return exr;
}
const DEFAULT_DOC_STATE = {
  ref_name: "",
  title: "",
  desc: "",
  duration: "",
  thumbnail: "",
  type: "workout",
  intensity: "low",
  target_area: [],
  equipments:[],
  groups: [],
  is_single_video: false,
  media:[],
  per_rep_time:EXERCISE_SINGLE_REP_DURATION,
  target_area_overridden:false,
  equipments_overridden:false,
  duration_overridden:false
};

const DEFAULT_GROUP_STATE = {
  notes: "",
  sets: 3,
  rest: [30],
  rest_after: "set",
  exercises: [],
  type: "default",
  editNotes: false,
  rest_after_circuit_enable:false,
  rest_after_circuit:[30]
};
const DEFAULT_SUMMARY={
  calculated:{
    duration:0,
    equipments:[],
    body_parts:[],
    bodyPartObj:[],
    equipmentsObj:[]
  },
  override:{
    duration:0,
    equipments:[],
    body_parts:[],
    bodyPartObj:[],
    equipmentsObj:[]
  },
  flags:{
    duration:false,
    equipments:false,
    body_parts:false
  }
}

const DETAILS = "details";
const WORKOUT = "workout";

const DUPLICATE_REF_PREFIX = "Edited copy of";

// Note errors.groups stores group wise errors, on change of grp-index i.e. delete segment,add new-segment,import segment, errors needs to reset to avoid UI issues.
class WorkoutDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      isValidId: true,
      doc: props.scheduleDoc
        ? cloneDeep({ ...props.doc, ...props.scheduleDoc })
        : props.doc,
      oldDoc: props.doc,
      draftDoc: {},
      publishedDoc: {},
      exercises: [],
      altExercises:[],
      errors: {},
      editMode: false,
      exerciseEditorMode: props.isEditorMode || false,
      targetAreasData: [],
      sidesData: [],
      isConfirmationOpen: false,
      isDeleteConfirmationOpen: false,
      dataToDelete: {},
      dirty: false,
      uploadingError: {media_bgon:false,media_bgoff:false},
      isDuplicateConfirmationOpen: false,
      isAddToLibraryConfirmationOpen: false,
      currentConfirmation: "",
      dataToDuplicate: {},
      setCircuitReorderDialog: false,
      openAddExercise:false,
      insideGrp:false,
      grpIndex:false,
      equipmentsData:[],
      muscleGroupsData:[],
      summary:{...DEFAULT_SUMMARY},
      groupWiseWoDuration:[0],
      openWorkoutSelector:false,
      showingDoc: PUBLISHED,
      viewOrigDoc: false,
      isWoEmpty:true
    };
  }

  static contextType = FirebaseAuthContext;

  get draftFeature(){
    const {comp} = this.context;
    if(_.isUndefined(this.props.draftFeature)){
      return !!comp.data().features.draft;
    }
    return this.props.draftFeature;
  }

  get docId() {
    return this.props.id;
  }

  get isNew() {
    return this.props.id === "new";
  }
  get isLongWo() {
    return get(this.state, "doc.is_single_video");
  }
  get isDraftAvailable() {
    return !!get(this.state, "publishedDoc.publish_status",PUBLISHED).includes('draft');
  }
  sanitizePersetValues=(arr=[],sets)=>{
    let validValues=(arr||[]).filter(val => !Number.isNaN(val));
    if(validValues.length>sets){
      validValues=validValues.slice(0,sets);
    }
    else if(validValues.length<sets){
      const lastItem=validValues[validValues.length-1];
      validValues=[...validValues,...Array(sets-validValues.length).fill(lastItem)];
    }
    return [...validValues];
  }
  managePersetValues=(exercise,sets)=>{
    const {type,values,weight_type:wtType,weights,track=[]}=exercise;
    if(type==="perset"){
      exercise['values']=this.sanitizePersetValues(values,sets);
    }
    if(wtType==="perset"){
      exercise['weights']=this.sanitizePersetValues(weights,sets);
    }
    if(!!track[0] && type==="perset"){//new tracking values
      exercise['male1']=this.sanitizePersetValues(exercise.male1,sets);
      if(exercise.gender){
        exercise['female1']=this.sanitizePersetValues(exercise.female1,sets);
      }
    }
    if(!!track[1] && exercise.type2==="perset"){
      exercise['male2']=this.sanitizePersetValues(exercise.male2,sets);
      if(exercise.gender){
        exercise['female2']=this.sanitizePersetValues(exercise.female2,sets);
      }
    }
    return _.cloneDeep({...exercise});
  }
  updateValuesNWeights=({groupIndex,sets})=>{
    if(sets<1)return;
    const exercises=_.get(this.state,`doc.groups.${groupIndex}.exercises`)||[];
    const updatedExercises=exercises.map(exercise=>{
      const {overrideAlts={}}=exercise||{};
      const updatedOverrideAlts={};
      for(let i in overrideAlts){
        updatedOverrideAlts[i]=this.managePersetValues(overrideAlts[i],sets);
      }
      const updatedEx= this.managePersetValues(exercise,sets);
      return {...updatedEx,overrideAlts:updatedOverrideAlts}
    });
    this.setState(o=>({
      ...o,
      doc:{
        groups:{
          [groupIndex]:{
            exercises:{
              ...updatedExercises
            }
          }
        }
      }
    }));
  }
  //used by create form & long-wo form
handleMetaChange=e=>{
  let { name, value, type, checked, id } = e.target;
  let chng = null;
  let v=id || name;
  if(v==="target_cals"){
    value=Number(value);
    if(!value){
      value=firebase.firestore.FieldValue.delete();
    }
  }
  if (type === 'checkbox')
    chng = { [id || name]: checked };
  else
    chng = { [id || name]: value };
  if (!chng) return;
    this.setState(s => ({ doc: { ...s.doc, ...chng} , dirty:true }));
  }
  //used by wo editor (structured)
  handleChange = e => {
    let chng;
    let { name, value, type, id,dataset } = e.target;
    const groupIndex = dataset ? parseInt(dataset.group) : null;
    const exerciseIndex = dataset ? parseInt(dataset.exercise) : null;
    if (type === "number") {
      value = parseInt(value) || 0;
      if (name.includes("values")) {
        const index = name.split("_")[1];
        const newState = update(this.state, {
          doc: {
            groups: {
              [groupIndex]: {
                exercises: {
                  [exerciseIndex]: {
                    values: {
                      [index]: { $set: Number(value) },
                    },
                  },
                },
              },
            },
          },
        });
        this.setState({ ...newState, dirty: true });
        return;
      }
      const _name = id || name;
      //-----validations-----//
      if(_name==="sets"){
        if(value<0) return ;
        //update values,weights in exercise
        this.updateValuesNWeights({groupIndex,sets:value})
      }
      else if(_name==="duration_per_exercise"){
        if(value<0 || value>180) return ;
      }
      //------validations end----//
      chng = { [_name]: value };
    } else {
      //non-number fields
      chng = { [id || name]: value };
    }
    if (
      groupIndex !== null &&
      !isNaN(groupIndex) &&
      exerciseIndex !== null &&
      !isNaN(exerciseIndex)
    ) {
      if (name === "type") {
        chng = { ...chng, values: [] };
      }
      //change in exercise in group[] => exercises[]
      const newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              exercises: {
                [exerciseIndex]: {
                  $merge: chng,
                },
              },
            },
          },
        },
      });
      this.setState({ ...newState, dirty: true });
    } else if (
      groupIndex !== null &&
      !isNaN(groupIndex) &&
      isNaN(exerciseIndex)
    ) {
      //change in groups[]
      const newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              $merge: chng,
            },
          },
        },
      });
      this.setState({ ...newState, dirty: true });
    } else {
      this.setState((s) => ({ doc: { ...s.doc, ...chng }, dirty: true }));
    }
  };
  handleExerciseAltChange=({grpIndex,exIndex,exercise})=>{
    const newState=update(this.state,{
      doc:{
        groups:{
          [grpIndex]:{
            exercises:{
              [exIndex]:{
                $set:{
                  ...exercise
                }
              }
            }
          }
        }
      },
      dirty:{
        $set:true
      }
    });
    this.setState({...newState});
  }
  handleExTypeValuesChange = (
    e,
    exChanges,
    cb
  ) => {
    const groupIndex = parseInt(e.currentTarget.dataset.group);
    const exerciseIndex = parseInt(e.currentTarget.dataset.exercise);
    const keysToOmit=TRACKING_FIELDS;
    const exercise=_.omit(_.get(this.state,`doc.groups.${groupIndex}.exercises.${exerciseIndex}`),keysToOmit);
    const updatedExercise={
      ...exercise,
      ...exChanges
    }
    const newState = update(this.state, {
      doc: {
        groups: {
          [groupIndex]: {
            exercises: {
              [exerciseIndex]: {
                $set:{
                  ...updatedExercise
                }
              },
            },
          },
        },
      },
    });
    this.setState({ ...newState, dirty: true }, () => {
      cb && cb();
    });
  };

  handleRestChange = (e, values, cb, type = "rest") => {
    const groupIndex = parseInt(e.currentTarget.dataset.group);
    const exerciseIndex = parseInt(e.currentTarget.dataset.exercise);
    let newState = "";
    if (!isNaN(exerciseIndex)) {
      newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              exercises: {
                [exerciseIndex]: {
                  rest: {
                    $set: values,
                  },
                },
              },
            },
          },
        },
      });
    } else {
      newState = update(this.state, {
        doc: {
          groups: {
            [groupIndex]: {
              [type]: {
                $set: values,
              },
            },
          },
        },
      });
    }
    this.setState({ ...newState, dirty: true }, () => {
      cb && cb();
    });
  };

  handleExerciseAdd = async payload => {
    const {targetAreasData}=this.state;
    const newExerciseMeta=_.cloneDeep(_.get(this.state,'doc.exerciseMeta',{}));
    payload.data.forEach(i=>{
      newExerciseMeta[i._id]={
        body_parts:sanitizeBodyParts(_.get(i,"data.body_parts",[]),targetAreasData,bps2taz),
        equipments:_.get(i,"data.equipments",[])
      }
    });
    let exIds = [];
    let newState = this.state;
    let lengthWas = newState.doc.groups && newState.doc.groups.length;
    let newGroupsAdded = false;
    if (payload.type === "group") {
      const payloadDocs = payload.data.map((d) => {
        exIds.push(d._id);
        return {...getExAddState(this.context.cid,d,DEFAULT_GROUP_STATE.type)}
      });
      const newGrp = { ...DEFAULT_GROUP_STATE, exercises: [...payloadDocs] };
      newState = update(newState, {
        doc: {
          groups: {
            $push: [newGrp],
          },
          exerciseMeta: {
            $set: { ...newExerciseMeta },
          },
        },
      });
      newGroupsAdded = true;
    } else if (payload.type === "individual") {
      const groupIndex = payload.grpIndex;
      if (groupIndex !== null) {
        const typeWas = get(newState, `doc.groups[${groupIndex}].type`);
        const newExrcs = payload.data.map((d) => {
          if (d.existsData && Object.keys(d.existsData).length) {
            return { ...d.existsData }; //if exercise contain existsData ie, it was already added and have their own data
          }
          exIds.push(d._id);
          return {...getExAddState(this.context.cid,d,typeWas)}
        });
        newState = update(newState, {
          doc: {
            groups: {
              [groupIndex]: {
                type: {
                  $set: typeWas === "dropset" ? "default" : typeWas,
                },
                exercises: {
                  $set: [...newExrcs], //in case of individual action having grpIndex all exercise will set instead of push
                },
              },
            },
            exerciseMeta: {
              $set: { ...newExerciseMeta },
            },
          },
        });
        //if there is not any exercise then delete that group::
        if (!newExrcs || !newExrcs.length) {
          newState = update(newState, {
            doc: {
              groups: {
                $splice: [[groupIndex, 1]],
              },
              exerciseMeta: {
                $set: { ...newExerciseMeta },
              },
            },
          });
        }
      } else {
        const newGrps = payload.data.map((d) => {
          exIds.push(d._id);
          const exr = {...getExAddState(this.context.cid,d,DEFAULT_GROUP_STATE.type)}
          return {
            ...DEFAULT_GROUP_STATE,
            exercises: [exr],
          };
        });
        newState = update(newState, {
          doc: {
            groups: {
              $push: newGrps,
            },
            exerciseMeta: {
              $set: { ...newExerciseMeta },
            },
          },
        });
        newGroupsAdded = true;
      }
    }

    const exercisesData = await this.fetchExs(exIds);
    if (lengthWas && newGroupsAdded) {
      // was the erstwhile last exercise a cooldown change it to regular
      const last = newState.doc.groups[lengthWas - 1];
      if (last && last.type === "cooldown")
        newState = update(newState, {
          doc: {
            groups: {
              [lengthWas - 1]: { type: { $set: "default" } },
            },
          },
        });
    }
    newState = update(newState, {
      exercises: {
        $push: [...exercisesData],
      },
    });
    this.setState({ ...newState, dirty: true }, () => {});
  };
  handleGrpHeader= (grpIndex,payload={})=>{
    const newState=update(this.state,{
      doc:{
        groups:{
          [grpIndex]:{
            $merge:{
              ...payload
            }
          }
        }
      }
    });
    this.setState({ ...newState, dirty: true }, () => {});
  }
  //used by Meta form (Edit only)
  editMetaForm = () => {
    this.setState({ editMode: true});
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }

  };
  //used by Meta form (Edit only)
  closeMetaForm = (e) => {
    const newState = update(this.state, {
      doc: {
        $set: cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc }),
      },
      editMode: {
        $set: false,
      },
      errors:{
        $set:{}
      },
      dirty:{
        $set:false,
      },
      isConfirmationOpen: { 
        $set: false 
      },
    });
    this.setState({ ...newState});
  };
  //used by editors
  openEditor = () => {
    //reset errors important
    this.setState({ exerciseEditorMode: true,errors:{} });
    if (this.isDraftAvailable && this.state.showingDoc !== DRAFT) {
      this.toggleDoc(DRAFT);
    }
  };
  //used by editors
  closeEditor = async (action, addToLibrary = false) => {
    if (action === "done") {
      if(!this.state.dirty && !this.props.isEditorMode && !this.isDraftAvailable){
        //do nothing
      }
      else if(this.draftFeature) {
        this.setState({ isConfirmationOpen: true });
        return;
      }
      else {
        await this.handlePublish(2, addToLibrary);
        return;
      }
    }
    if (this.props.isEditorMode) {
      //will pop query params for isNew
      this.props.onSelect(this.docId);
    }
    const newState = update(this.state, {
      doc: {
        $set: cloneDeep({ ...this.state.oldDoc, ...this.props.scheduleDoc }),
      },
      exerciseEditorMode: {
        $set: false,
      },
      errors: { $set: {} },
      isConfirmationOpen: { $set: false },
      dirty: { $set: false },
    });
    this.setState(newState);
  };
  toggleDoc = async(docType) => {
    const { draftDoc, publishedDoc } = this.state;
    const doc = docType === DRAFT ? { ...draftDoc } : { ...publishedDoc };
    if (!Object.keys(doc).length) return;
    const [parsedDoc,exercises]=await this.parseCurrentDoc(doc);
    this.setState(o=>({ 
      showingDoc: docType,
      doc:{...parsedDoc},
      oldDoc:{...parsedDoc},
      exercises:[...o.exercises,...exercises]
     }));
  };
  submitSummary = ({ data, override = {}, flags = {} }) => {
    const currentDoc = cloneDeep(this.state.doc);
    Object.keys(flags).forEach((i) => {
      // WO will have 'target_area' key
      if (i === "body_parts") {
        currentDoc["target_area"] = data[i];
        currentDoc["target_area_overridden"] = flags["body_parts"];
      } else {
        currentDoc[i] = data[i];
        currentDoc[`${i}_overridden`] = flags[i];
      }
    });
    this.setState((o) => ({
      summary: {
        ...o.summary,
        flags: { ...o.summary.flags, ...flags },
        override: { ...o.summary.override, ...override },
      },
      doc: {
        ...currentDoc,
        //  target_area_overridden:flags.body_parts,
        //  equipments_overridden:flags.equipments,
        //  duration_overridden:flags.duration
      },
      dirty: true,
    }));
  };

  calculateSummary = () => {
    //-----
    //NOTE: Wo has 'target_area', exerciseMeta has 'body_parts'.
    //-----
    const { doc={}, targetAreasData, equipmentsData } = this.state;
    const {
      exerciseMeta = {},
      target_area,
      equipments,
      duration,
      per_rep_time = EXERCISE_SINGLE_REP_DURATION,
      target_area_overridden = false,
      equipments_overridden = false,
      duration_overridden = false,
    } = doc || {};
    //calculating duration
    const segmentDuration = (doc.groups || []).map((i) =>
      calculateSegmentDuration(i, per_rep_time, this.state.exercises)
    );
    //calculating equipments & bodyParts
    const {
      equipments: calculatedEquipments = [],
      body_parts: calculatedBodyParts = [],
    } = calculateEquipmentsNBodyParts(doc.groups, exerciseMeta);
    this.setState({
      groupWiseWoDuration:segmentDuration,
      summary:{
        calculated:{
          duration:sum(segmentDuration),
          equipments:calculatedEquipments,
          body_parts:sanitizeBodyParts(calculatedBodyParts,targetAreasData,bps2taz),
          bodyPartObj:mapIdToObject(targetAreasData,calculatedBodyParts),
          equipmentsObj:mapIdToObject(equipmentsData,calculatedEquipments)
        },
        override:{
          duration:duration,
          equipments:equipments||[],
          body_parts:sanitizeBodyParts(target_area||[],targetAreasData,bps2taz),
          bodyPartObj:mapIdToObject(targetAreasData,target_area),
          equipmentsObj:mapIdToObject(equipmentsData,equipments)
        },
        flags:{
          ...(DEFAULT_SUMMARY.flags),
          body_parts:!!target_area_overridden,
          equipments:!!equipments_overridden,
          duration:!!duration_overridden
        }
      }
    });
  };
  showDeleteConfirmation = (grpIndex, exrcsIndex, type) => {
    this.setState({ dataToDelete: { grpIndex, exrcsIndex, type } });
    this.setState({ isDeleteConfirmationOpen: true });
  };

  showDuplicateConfimation = (grpIndex) => {
    this.setState({ dataToDuplicate: { grpIndex } });
    this.setState({ isDuplicateConfirmationOpen: true });
  };

  handleDuplicate = ({ grpIndex }) => {
    const { doc } = this.state;
    let copy = cloneDeep(doc.groups[grpIndex]);
    let position = grpIndex + 1;
    if (copy.type === "warmup") copy.type = "default"; // warmup cannot be at posn>0
    if (copy.type === "cooldown") {
      copy.type = "default"; // cooldown cannot be at non-last posn
      position = grpIndex;
    }
    let newState = update(doc, {
      groups: {
        $splice: [[position, 0, copy]],
      },
    });
    this.setState((s) => ({
      doc: { ...s.doc, ...newState },
      isDuplicateConfirmationOpen: false,
      dataToDuplicate: {},
      dirty: true,
    }));
  };

  handleGrpAdd = async (grpIndex, groups) => {
    const newArry = [];
    groups.forEach((grp, i) => {
      if (grpIndex.includes(i)) {
        newArry.push(grp);
      }
    });
    const { doc } = this.state;
    let lastGroup = _.last(doc.groups);
    let exids = [];
    let newState = this.state;
    let copy = cloneDeep(newArry);

    copy.forEach((ex, i, sourceArr) => {
      // import exercise group type conditon handle
      const length = sourceArr.length;
      if (ex.type === "warmup") ex.type = "default"; // warmup cannot be at posn>0
      if (
        ex.type === "cooldown" &&
        ((i === 0 && length > 1) || (!lastGroup && length === 1))
      ) {
        ex.type = "default"; // cooldown cannot be at non-last posn
      }
    });

    if (lastGroup) {
      //last exercise group type condition handle
      if (lastGroup.type === "warmup") lastGroup.type = "default"; // warmup cannot be at posn>0
      if (lastGroup.type === "cooldown") {
        lastGroup.type = "default"; // cooldown cannot be at non-last posn
      }

      newState = update(newState, {
        doc: {
          groups: {
            $merge: lastGroup,
          },
        },
      });
    }

    newState = update(newState, {
      doc: {
        groups: {
          $push: copy,
        },
      },
    });

    copy.forEach((ex) => {
      if (ex.exercises && ex.exercises.length > 0) {
        ex.exercises.forEach((e) => {
          exids.push(e.ref.id);
        });
      }
    });

    const exercisesData = await this.fetchExs(exids);
    newState = update(newState, {
      exercises: {
        $push: [...exercisesData],
      },
    });
    this.setState({ ...newState, openWorkoutSelector: false, dirty: true });
  };

  handleReorderCircuit = ({ circuitIndex }) => {
    this.setState({ setCircuitReorderDialog: true });
  };

  setNotesState = (grpIndex, val) => {
    const newState = update(this.state, {
      doc: {
        groups: {
          [grpIndex]: {
            editNotes: {
              $set: val,
            },
          },
        },
      },
    });
    this.setState({ ...newState });
  };

  handleDelete = ({ grpIndex, exrcsIndex, type }) => {
    let newState = {};
    if (type === "delete_note") {
      newState = update(this.state, {
        doc: {
          groups: {
            [grpIndex]: {
              $unset: ["notes"],
              editNotes: {
                $set: false,
              },
            },
          },
        },
      });
    } else {
      if (
        this.state.doc.groups[grpIndex].exercises.length === 1 ||
        type === "group"
      ) {
        newState = update(this.state, {
          doc: {
            groups: {
              $splice: [[grpIndex, 1]],
            },
          },
        });
        //reset to default when deleting group with cooldown as last object
        if (newState.doc.groups.length === 1) {
          const { type } = newState.doc.groups[0];
          const change = {};
          if (type === "cooldown") {
            change.type = { $set: "default" };
            newState = update(newState, {
              doc: {
                groups: {
                  [grpIndex]: change,
                },
              },
            });
          }
        }
      } else {
        const change = { exercises: { $splice: [[exrcsIndex, 1]] } };
        const { exercises, type } = this.state.doc.groups[grpIndex];
        // if ((type === 'superset' || type === 'circuit'||type==='triset') && exercises.length <= 2)
        //   change.type = {$set: 'default'};
        if (exercises.length <= 2) {
          change.rest_after_circuit_enable = { $set: false };
        }
        newState = update(this.state, {
          doc: {
            groups: {
              [grpIndex]: change,
            },
          },
        });
      }
    }
    this.setState({
      ...newState,
      isDeleteConfirmationOpen: false,
      dataToDelete: {},
      dirty: true,
      errors: {},
    });
  };

  handleMove = (grpIndex, type) => {
    const { doc } = this.state;
    let items = doc.groups;

    if (type === "down") {
      items = this.reorder(items, grpIndex, grpIndex + 1);
    } else {
      items = this.reorder(items, grpIndex, grpIndex - 1);
    }
    const newState = update(this.state, {
      doc: {
        groups: {
          $set: items,
        },
      },
    });

    this.setState({ ...newState, dirty: true });
  };

  sanitizeGroups = () =>
    this.setState((state) => {
      const change = { groups: {} };
      const N = state.doc.groups.length;
      state.doc.groups.forEach((group, n) => {
        const { exercises, type } = group;
        switch (type) {
          case "warmup": // only 1st item can be a warmup
            if (n !== 0) change.groups[n] = { type: { $set: "default" } };
            break;
          case "cooldown": // only last item can be a cooldown
            if (n !== N - 1) change.groups[n] = { type: { $set: "default" } };
            break;
          // case 'triset':// circuit/superset/triset must be multi-exercises
          // case 'superset':
          // case 'circuit':
          //   if (exercises.length < 2) change.groups[n] = { type: {$set: 'default'} };
          // break;
          default:
            break;
        }
      });
      return update(state, { doc: change });
    });

  handleReorder = (grpIndex, exercises) => {
    const newState = update(this.state, {
      doc: {
        groups: {
          [grpIndex]: {
            exercises: {
              $set: exercises,
            },
          },
        },
      },
    });
    this.setState({ ...newState, dirty: true });
  };

  handleGrpAttributes = (payloads) => {
    let newState = this.state;
    payloads.forEach((payload) => {
      newState = update(newState, {
        doc: {
          groups: {
            [payload.index]: {
              [payload.key]: {
                $set: payload.value,
              },
            },
          },
        },
      });
    });

    this.setState({ ...newState, dirty: true });
  };

  valid = (step,isPublish) => {
    let out = true;
    let errors = {};
    let {
      title="",
      groups=[],
      ref_name="",
      is_single_video,
      media = [],
      duration,
      target_hr=[],
    } = this.state.doc;
    if (!title.trim()) {
      errors.title = ERROR_TITLE("display name");
      out = false;
    }
    if (!ref_name.trim()) {
      errors.ref_name = ERROR_REF_NAME;
      out = false;
    }
    const [minHr, maxHr] = target_hr;
    if((!!minHr && !maxHr)||(!!maxHr && !minHr)){
      errors.target_hr="Please enter valid Heart Rate";
      out = false;
    }
    if (!!minHr && !!maxHr && ((minHr < 60 || minHr > 200)||(maxHr < 60 || maxHr > 200))) {
      errors.target_hr= ERROR_CARIO_DUR;
      out = false;
    }
    if (!!minHr && !!maxHr && (minHr >= maxHr)) {
      errors.target_hr = "Min value should be less than max value";
      out = false;
    }
    if (step !== 1) {
      if (!!is_single_video) {
        //long wo validations
        if(media && media[0]){
          if(!duration){
            errors.duration = ERROR_MIN_DURATION;
            out = false;
          }
          if(media[0].type==="youtube" && !YOUTUBE_VIDEO_URL_REGEX.test(media[0].url)){
            errors.mediaUrl = ERROR_YOUTUBE_URL;
            out = false;
          }
          else if(media[0].type === "vimeo" && !VIMEO_VIDEO_URL_REGEX.test(media[0].url)){
            errors.mediaUrl = ERROR_VIMEO_URL;
            out = false;
          }
          //TODO: look for better key for uploading state
          //  else if (!media[0].url) {
          //   errors.mediaUpload = ERROR_MEDIA_UPLOAD;
          //   out = false;
          // }
        }
        else {
          if(isPublish){
            errors.mediaUrl = ERROR_MEDIA_URL;
            errors.mediaUpload = ERROR_MEDIA_UPLOAD;
            this.props.enqueueSnackbar("Pls add video to this workout before publishing",{variant:"warning"});
            out = false;
          }
        }
      } else {
        //structured wo validations
        if(isPublish && (!groups || (groups && !groups.length))){
          this.props.enqueueSnackbar("Pls add exercises to this workout before publishing",{variant:"warning"});
          out=false;
        }
        errors.groups = [];
        groups.forEach((grp, i) => {
          //errors at group level: duration,duration_per_exercise,sets,type, 
          const g = { exercises: [] };
          errors.groups[i] = {};
          switch (grp.type) {
            case "amrap": {
              if (!grp.duration) {
                g.duration = "Please provide valid sets value";
                errors.groups[i].duration = "Duration cannot be zero in AMRAP";
                out = false;
              }
              const duration=grp.duration;
              const exercises=grp.exercises;
              exercises.forEach(e=>{
                if(e.ref_type==="duration"){
                  const side=!!e.side && e.side!=="none";
                  e.values.forEach(v=>{
                    const val=v*(side?2:1);
                    if(val>duration){
                      errors.groups[i].duration = "Segment duration cannot be shorter than exercise duration";
                      out=false;
                    }
                  })
                }
              })
              break;
            }
            case "emom": {
              if (!grp.duration_per_exercise) {
                g.duration_per_exercise =
                  "Please provide valid duration per exercise";
                errors.groups[i].duration_per_exercise =
                  "Please provide valid duration per exercise";
                out = false;
              }
              if (!grp.sets) {
                g.sets = "Please provide valid sets value";
                errors.groups[i].sets = "Please provide valid sets value";
                out = false;
              }
              const exercises=grp.exercises;
              exercises.forEach(e=>{
                if(e.ref_type==="duration"){
                  const side=!!e.side && e.side!=="none";
                  e.values.forEach(v=>{
                    const val=v*(side?2:1);
                    if(val>grp.duration_per_exercise){
                      errors.groups[i].duration_per_exercise = `${grp.emom_type==="set"?"seconds/set":"seconds/exercise"} cannot be shorter than exercise duration`;
                      out=false;
                    }
                  })
                }
              })
              break;
            }
            case "triset": {
              if (grp.exercises.length <= 2) {
                out = false;
                errors.groups[i].type = "Trisets shall have atleast 3 exercises";
              }
              if (!grp.sets) {
                g.sets = "Please provide valid sets value";
                errors.groups[i].sets = "Please provide valid sets value";
                out = false;
              }
              break;
            }
            case "superset":
            case "circuit": {
              if (grp.exercises.length <= 1) {
                errors.groups[i].type = `${_.upperFirst(
                  grp.type
                )} shall have atleast 2 exercises`;
                out = false;
              }
              if (!grp.sets) {
                g.sets = "Please provide valid sets value";
                errors.groups[i].sets = `Please provide valid ${grp.type === "circuit" ? "rounds" : "sets"} value`;
                out = false;
              }
              break;
            }
            default: {
              if (!grp.sets) {
                g.sets = "Please provide valid sets value";
                errors.groups[i].sets = `Please provide valid sets value`;
                out = false;
              }
            }
          }
        });
        //Error Toasts- show 1 grp err at a time in toast
        if (!!errors.groups.length) {
          let index;
          for (let i in errors.groups) {
            if (!!Object.keys(errors.groups[i]).length) {
              index = i;
              break;
            }
          }
          if (!!index) {
            const selectedGrp = errors.groups[index];
            Object.keys(selectedGrp).forEach((i) => {
              this.props.enqueueSnackbar(selectedGrp[i], { variant: "error" });
            });
          }
        }
      }
    }
    this.setState({ errors });
    return out;
  };

  handleValuesOnSetsChange = (grpIndex, exrIndex, values, weights) => {
    const newState = { ...this.state.doc };
    if (values) newState.groups[grpIndex].exercises[exrIndex].values = values;
    if (!!weights)
      newState.groups[grpIndex].exercises[exrIndex].weights = weights;
    this.setState({ ...newState, dirty: true });
  };

  deleteItem = () => {
    const { onDelete, enqueueSnackbar, hideLoader, showLoader } = this.props;
    showLoader();
    woRepo(this.context.cid,this.draftFeature)
      .delete(this.docId)
      .then((doc) => {
        hideLoader();
        if (doc) {
          this.props.delete(doc.id);
          this.props.deleteWocardio(doc.id);
          if (!!onDelete) onDelete();
          enqueueSnackbar("Workout deleted successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };

  copyItem = () => {
    const { showLoader, hideLoader, insert, onSave, enqueueSnackbar } =
      this.props;
    const { doc } = this.state;
    showLoader();
    let copyDoc = update(doc, {
      ref_name: {
        $set: `Copy of ${doc.ref_name}`,
      },
    });
    woRepo(this.context.cid,this.draftFeature)
      .create(copyDoc)
      .then((doc) => {
        hideLoader();
        if (doc) {
          const _rdxState = {
            cid: this.context.cid,
            ref_name: doc.data().ref_name,
            type: doc.data().type,
            sub_type: doc.data().sub_type,
            intensity: doc.data().intensity,
            is_single_video: !!doc.data().is_single_video,
            duration: this.state.summary.flags.duration
              ? this.state.summary.override.duration
              : this.state.summary.calculated.duration,
            thumbnail: doc.data().thumbnail,
            publish_status:doc.data().publish_status
          };
          insert({
            _id: doc.id,
            data: _rdxState,
          });
          this.props.insertWocardio({
            _id: doc.id,
            data: _rdxState,
          });
          if (!!onSave) onSave(doc);
          enqueueSnackbar("Workout copy successfully.", { variant: "success" });
        }
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  uploadVideoToBunnyCDN=async({file,isPublish,media_bgoff,docName,...videoObject})=>{
    const { uploadBunnyFile } = this.props;
    uploadBunnyFile({
      docId:this.docId,
      cid:this.context.cid,
      file:file.url,
      collection:isPublish ? "workouts" : "draft-workouts",
      media_bgoff,
      path:`/fitness/workouts/${this.docId}`,
      docName,
      videoObject,
    });
  }
  
  sanitizeExerciseMeta=(updatedDoc)=>{
    const {targetAreasData}=this.state;
    const newExerciseMeta={};
    const {groups,exerciseMeta={}}=updatedDoc;
    groups.forEach(g=>{
      g.exercises.forEach(e=>{
        const exId=_.get(e,'ref.id');
        newExerciseMeta[exId]={
          body_parts:sanitizeBodyParts(_.get(exerciseMeta,`${exId}.body_parts`,[]),targetAreasData,bps2taz),
          equipments:_.get(exerciseMeta,`${exId}.equipments`,[]),
        }
      })
    });
    updatedDoc = update(updatedDoc, {
      exerciseMeta: {
        $set: newExerciseMeta,
      },
    });
    return updatedDoc;
  };
  createVideoObject=async({isPublish,media_bgoff,duration,title,resolution})=>{
    try{
      const collection=isPublish ? "workouts" : "draft-workouts";
      const resp=await createVidObject({
        cid:this.context.cid,
        docId:this.docId,
        path: `companies/${this.context.cid}/${collection}/${this.docId}`,
        collection,
        media_bgoff,
        video:{
          title,
          duration,
          resolution
        }
      });
      return await resp.data;
    }
    catch(err){
      this.props.enqueueSnackbar(_.get(err,"response.data.message",DEFAULT_VID_UPLOAD_ERROR),{variant:"error"});
      console.log("err",err);
      return null;
    }
    
  }
  sanitizeCodeBeforeSave = (primMediaVideObj,secMediaVidObj) => {
    const { doc, summary } = this.state;
    let updatedDoc = { ...doc };
    const { media = {}, media_bgoff = {} } = updatedDoc;
    updatedDoc["desc"] = updatedDoc["desc"].trim();
    if(updatedDoc.media_bgoff && !Object.keys(_.get(updatedDoc,'media_bgoff',{})).length||!mediaObjExist(media_bgoff)){
      updatedDoc.media_bgoff=null;
    }
    // For Structured Workout:
    if (!doc.is_single_video) {
      updatedDoc = {
        ...updatedDoc,
        duration: summary.flags.duration
          ? summary.override.duration
          : summary.calculated.duration,
        target_area: summary.flags.body_parts
          ? summary.override.body_parts
          : summary.calculated.body_parts,
        equipments: summary.flags.equipments
          ? summary.override.equipments
          : summary.calculated.equipments,
      };
      //Sanitize exercise meta:
      updatedDoc = this.sanitizeExerciseMeta(updatedDoc);
    } else {
      // For Long Workout:
      const newMediaAvailable=!!get(media,"0.url.name", null);
      const newSecMediaAvailable=!!get(media_bgoff,"url.name", null);
      //-----primary video----
      if (!!newMediaAvailable) {
        updatedDoc = update(updatedDoc, {
          media: {
            $set: [{ 
              type: "video", 
              status: primMediaVideObj?FILE_STATUS.uploading:FILE_STATUS.error, 
              videoId:_.get(primMediaVideObj,"videoId",""),
            }],
          },
        });
      }
      else{
        if(!!get(media,"0",null) && ["youtube", "vimeo"].includes(media[0].type) && media[0].url){
          updatedDoc=update(updatedDoc,{
            media:{
              $set:[{
                type: media[0].type, url: media[0].url, ...(!!media[0].error &&  {error : media[0].error})
              }]
            }
          });
        }
      }
      //-----secondary video----
      if (!!newSecMediaAvailable) {
        updatedDoc = update(updatedDoc, {
          media_bgoff: {
            $set: { 
              type: "video", 
              status: secMediaVidObj?FILE_STATUS.uploading:FILE_STATUS.error, 
              // s3Path:_.get(secMediaVidObj,"videoId",""),
              videoId:_.get(secMediaVidObj,"videoId",""),
            },
          },
        });
      }
      else{
        if(!!media_bgoff && ["youtube", "vimeo"].includes(media_bgoff.type) && media_bgoff.url){
          updatedDoc=update(updatedDoc,{
            media_bgoff:{
              $set:{
                type: media_bgoff.type, url: media_bgoff.url, ...(!!media_bgoff.error && {error : media_bgoff.error})
              }
            }
          })
        }
      }
      //Group Notes-To be Tested
      if (updatedDoc.groups && updatedDoc.groups.length > 0) {
        updatedDoc.groups.forEach((group) => {
          group.editNotes = false;
          if (
            group.type === "dropset" ||
            (group.notes && !!group.notes.trim())
          ) {
            group.editNotes = true;
          }
        });
      }
      //Per Rep Time
      if (this.isNew) {
        const { per_rep_time } = this.context.comp
          ? this.context.comp.data()
          : {};
        if (per_rep_time) {
          updatedDoc = update(updatedDoc, {
            per_rep_time: {
              $set: per_rep_time,
            },
          });
        }
      }
    }
    return { ...updatedDoc };
  };
  discardDraft = () => {
    const { showLoader, hideLoader, enqueueSnackbar, onDelete } = this.props;
    showLoader();
    woRepo(this.context.cid,this.draftFeature)
      .deleteDraft(this.docId, null, this.state.doc.publish_status)
      .then((doc) => {
        hideLoader();
        if (!doc) return;
        const updatedDoc = doc.data();
        if (get(updatedDoc, "publish_status", "") === DRAFT_STATES["DRAFT_ONLY"]) {
          this.props.delete(doc.id);
          this.props.deleteWocardio(doc.id);
          if (!!onDelete) onDelete();
        } else if (get(updatedDoc, "publish_status", "") === DRAFT_STATES["PUBLISHED"]) {
          this.setState(o=>({
            oldDoc: { ...o.oldDoc,publish_status:PUBLISHED },
            doc: { ...o.doc,publish_status:PUBLISHED },
            publishedDoc:{...o.publishedDoc,publish_status:PUBLISHED},
            showingDoc:PUBLISHED
          }));
          this.props.update({
            _id: doc.id,
            data: {
              cid: this.context.cid,
              ref_name: updatedDoc.ref_name,
              type: updatedDoc.type,
              sub_type: updatedDoc.sub_type,
              intensity: updatedDoc.intensity,
              is_single_video: !!updatedDoc.is_single_video,
              duration: updatedDoc.duration,
              thumbnail: updatedDoc.thumbnail,
              publish_status: updatedDoc.publish_status,
            },
          });
        }
        enqueueSnackbar("Draft deleted successfully.", { variant: "success" });
      })
      .catch((err) => {
        hideLoader();
        enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
        Sentry.captureException(err);
      });
  };
  handlePublish = async (step=2, addToLibrary = false) => {
    if(this.isDraftAvailable && this.state.showingDoc!==DRAFT){
      await this.toggleDoc(DRAFT);
    }
    await this.onSubmit({docType:PUBLISHED, closeOnSaving: true, step, addToLibrary});
  };
  handleDraft = async (close = false,step=2) => {
    this.onSubmit({docType:DRAFT, closeOnSaving: close, step});
  };

  closeLibraryConfirmation = () => {
    this.setState({isAddToLibraryConfirmationOpen: false, currentConfirmation: ""})
  }

  handleWorkoutSave = async (addToLibrary = false) => {
    if(this.state.currentConfirmation === WORKOUT) await this.closeEditor("done", addToLibrary);
    else this.onSubmit({docType: PUBLISHED, closeOnSaving: true, addToLibrary})
    this.closeLibraryConfirmation()
  }

  handleSave = (type) => {
    if(this.props.createNew && this.state.dirty) {
      this.setState({isAddToLibraryConfirmationOpen: true, currentConfirmation: type});
    } else {
      if(type === WORKOUT) this.closeEditor("done");
      else this.onSubmit({docType: PUBLISHED, closeOnSaving: true});
    }
  }
  
  //called for create form, structured wo editor, long wo editor
  onSubmit = async ({docType = PUBLISHED, closeOnSaving = false, step = 1, addToLibrary = false}) => {
    const { showLoader, hideLoader, insert, onSave, enqueueSnackbar } = this.props;
    const { doc: orignalDoc,summary } = this.state;

    const calculatedDocType=calculatePublishStatus(this.isNew,this.draftFeature,this.state.oldDoc,docType);
    const isPublish=calculatedDocType===DRAFT_STATES['PUBLISHED'];
    if (!this.valid(step,isPublish)){
      this.setState({isConfirmationOpen:false});
      return;
    }
    showLoader();
    let newMediaAvailable=!!get(orignalDoc,"media.0.url.name", null);
    let newSecMediaAvailable=!!get(orignalDoc,"media_bgoff.url.name", null);
    if(newMediaAvailable){
      const resp=await this.createVideoObject({
        isPublish,
        media_bgoff:false,
        duration:get(orignalDoc,"media.0.duration"),
        title:get(orignalDoc,'media.0.url.name',""),
        resolution:get(orignalDoc,'media.0.height',"")
      });
      if(!resp) {
        hideLoader();
        return;
      }
      newMediaAvailable=resp;
    }
    if(newSecMediaAvailable){
      const resp=await this.createVideoObject({
        isPublish,
        media_bgoff:true,
        duration:get(orignalDoc,'media_bgoff.duration'),//fix this
        title:get(orignalDoc,'media_bgoff.url.name',""),
        resolution:get(orignalDoc,'media_bgoff.height',"")
      });
      if(!resp) {
        hideLoader();
        return;
      }
      newSecMediaAvailable=resp;
    }
    let updatedDoc = { ...this.sanitizeCodeBeforeSave(newMediaAvailable,newSecMediaAvailable) };
    const { thumbnail } = updatedDoc;
    if (thumbnail.file) {
      const imageUrl = await uploadFile({
        file: thumbnail.file,
        filePath: WORKOUTS_STORAGE_FILE_PATH,
      });
      updatedDoc = update(updatedDoc, {
        thumbnail: {
          $set: imageUrl.replace("original", "240"),
        },
      });
    }

    //update Redux state
    
    const _rdxState = {
      cid: this.context.cid,
      ref_name: updatedDoc.ref_name,
      type: updatedDoc.type,
      sub_type: updatedDoc.sub_type,
      intensity: updatedDoc.intensity,
      is_single_video: !!updatedDoc.is_single_video,
      duration: updatedDoc.duration,
      // duration: this.state.summary.flags.duration?this.state.summary.override.duration:this.state.summary.calculated.duration,
      thumbnail:(calculatedDocType===DRAFT_STATES['DRAFT_ONLY']||calculatedDocType===DRAFT_STATES["PUBLISHED"])?updatedDoc.thumbnail:get(this.state.publishedDoc,'thumbnail'),
      publish_status:calculatedDocType
    };
    if (this.isNew) {
      woRepo(this.context.cid,this.draftFeature)
        .create(updatedDoc)
        .then((doc) => {
          bffUpdateHubspotProp(
            updatedDoc.is_single_video
              ? HUBSPOT_PROPS.LONGFORM_CREATED
              : HUBSPOT_PROPS.WORKOUT_CREATED
          );
          hideLoader();
          if (doc) {
            insert({
              _id: doc.id,
              data: _rdxState,
            });
            this.props.insertWocardio({
              _id: doc.id,
              data: _rdxState,
            });
            onSave(doc, "editorMode");
            //TODO:Test this callback in Draft/Publish Mode
            if (this.props.scheduleSaveWoEdit) {
              this.props.scheduleSaveWoEdit(updatedDoc);
            }
          }
        })
        .catch((err) => {
          hideLoader();
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    } else {
     //----hack-----
      //avoids BE flag pipeline, directly puts media in main collection, check flow in companyBase
      if(calculatedDocType===PUBLISHED && (newMediaAvailable||newSecMediaAvailable) && (updatedDoc.publish_status||'').includes('draft')){
        updatedDoc['to_publish']=true
      }
      //-------------
      let request = () =>
        woRepo(this.context.cid,this.draftFeature).update(this.docId, updatedDoc, null, docType);
      //TODO:Test this code with user module  
      //---user module code---
      if (this.props.createNew) {
        if(!updatedDoc.srcId) updatedDoc.srcId = this.docId;//one time writable
        updatedDoc.userProfileId = this.props.userProfileId;

        if(addToLibrary){
          updatedDoc.ref_name = `${DUPLICATE_REF_PREFIX} ${updatedDoc.ref_name}`;
          updatedDoc.archive = false;
        } else {
          updatedDoc.archive = true;
        }
        const docRef = await firebase
          .firestore()
          .collection(`companies/${this.context.cid}/workouts`)
          .doc(`modified_${uuidv4()}`);
        await docRef.set(updatedDoc);
        request = () => docRef.get();
      }
      //---user module code - end---
      request()
        .then((doc) => {
          hideLoader();
          if (doc) {
            if(newMediaAvailable){
              //uploadVideoToBunnyCDN
              this.uploadVideoToBunnyCDN({
                file:orignalDoc.media[0],
                isPublish,
                media_bgoff:false,
                docName:updatedDoc.ref_name,
                ...newMediaAvailable
              });
            }
            if(newSecMediaAvailable){
              this.uploadVideoToBunnyCDN({
                file:orignalDoc.media_bgoff,
                isPublish,
                media_bgoff:true,
                docName:updatedDoc.ref_name,
                ...newSecMediaAvailable
              });
            }
            this.props.update({
              _id: doc.id,
              data: _rdxState,
            });
            this.props.updateWocardio({
              _id: doc.id,
              data: _rdxState,
            });
            if (this.props.isEditorMode) {
              //pop query params
              this.props.onSelect(doc.id);
            }
            //TODO:Test this code with DRAFT/PUBLISH status
            if (this.props.scheduleSaveWoEdit) {
              this.props.scheduleSaveWoEdit(updatedDoc, doc.id,this.docId);
            }
            const to_publish=doc.data().to_publish;
            //---TODO ⬇︎ :replace docType with calculatedDocType ?? 
            this.setState(o=>({
              editMode: false,
              exerciseEditorMode: !closeOnSaving,
              showingDoc:to_publish? DRAFT:docType,
              publishedDoc:(docType===PUBLISHED && !to_publish)?doc.data():{...(o.publishedDoc||{}),publish_status:doc.data().publish_status},
              draftDoc:docType===DRAFT?doc.data():{...(o.draftDoc||{}),to_publish},
              oldDoc:doc.data(),
              doc:doc.data(),
              dirty: false,
              formStep: 1,
              isConfirmationOpen: false,
            }));
            //-------
          }
        })
        .catch((err) => {
          hideLoader();
          console.error(err);
          enqueueSnackbar(DEFAULT_ERROR, { variant: "error" });
          Sentry.captureException(err);
        });
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  handleSingleVideo = (e) => {
    let checked = Number(e.target.value);
    //check doc after set state, delete intro, warmup keys for strcutred workout
    let out = {
      [e.target.id || e.target.name]: !!checked,
      intro: (checked === 2),
      duration: 0,
      per_rep_time: EXERCISE_SINGLE_REP_DURATION,
      duration_overridden: false,
      equipments_overridden: false,
      target_area_overridden: false,
      total_duration: 0,
      intro_duration: 0,
      warmup_duration: 0,
      outro_duration: 0,
      media: [],
      media_bgoff: {},
      groups: [],
      exerciseMeta: {},
      thumbnail: "",
    };
    this.setState({ doc: { ...this.state.doc, ...out } });
  };

  videoStatusRefresh = (type) => {
    const { files } = this.props;
    const {showingDoc,draftDoc,publishedDoc}=this.state;
    const ongoingPipeline=draftDoc && !!draftDoc.to_publish && publishedDoc && publishedDoc.publish_status.includes('draft');
    let _currentshowingDoc=ongoingPipeline?PUBLISHED:showingDoc;
    const media = _.get(
      _currentshowingDoc === PUBLISHED ? { ...publishedDoc } : { ...draftDoc },
      type === "media_bgon" ? "media.0" : "media_bgoff",
      {}
    );

    const file = files.find(i=>i.id===media.videoId)||{};
    if (file.status === FILE_STATUS.processing ||media.status===FILE_STATUS.processing) {
      const fetchWorkout =()=> !this.isNew && this.fetchWo(_currentshowingDoc===DRAFT);
      refreshDoc(fetchWorkout, this.props, (data) => {
        const latestPublishedStatus=_.get(data,'doc.publish_status');
        if(ongoingPipeline ){
          if(latestPublishedStatus===DRAFT_STATES['PUBLISHED']){
          //pipeline completed
          this.setState({
            publishedDoc:{...data.doc},
            doc: { ...data.doc },
            oldDoc:{...data.doc},
            showingDoc:PUBLISHED
          })
          }
          else{
            //ignore
          }
        }
        else{
          this.setState({
            [showingDoc === DRAFT ? "draftDoc" : "publishedDoc"]: { ...data.doc },
            doc: { ...data.doc },
            oldDoc:{...data.doc}
          });
        }
      });
    }
  };
  updateMediaMeta=(mediaType,args={})=>{
    const {media=[],media_bgoff={}}=this.state.doc;
    if(mediaType===MUSIC_ON){
      if(media && !!_.isMatch(media[0],args)) return;
      const newState=update(this.state,{
        doc:{
          media:{
            0:{
              $merge:{
                ...args
              } 
            }
          }
        }
      });
      this.setState({
        ...newState
      });
    }
    else{
      if(!!_.isMatch(media_bgoff,args)) return;
      const newState=update(this.state,{
        doc:{
          media_bgoff:{
            $merge:{
              ...args
            }
          }
        }
      });
      this.setState({
        ...newState
      });
    }
  }
  handleMedia = (media, mediaType) => {
    const {type, url} = media; 
    let newState = this.state;
    //thumbnail update
    if (type === "thumbnail") {
      if (url) {
        newState = update(this.state, {
          doc: {
            thumbnail: {
              $set: {
                file: url,
                url: URL.createObjectURL(url),
              },
            },
          },
        });
      } else {
        newState = update(this.state, {
          doc: {
            thumbnail: {
              $set: "",
            },
          },
        });
      }
    } else {
      //video update
      if (mediaType === MUSIC_ON) {
        //primary video
        newState = update(this.state, {
          doc: {
            total_duration: { $set: 0 },
            intro_duration: { $set: 0 },
            warmup_duration: { $set: 0 },
            outro_duration: { $set: 0 },
            duration: { $set: url ? this.state.doc.duration : 0 },
            media: {
              $set: [media],
            },
            media_bgoff:
              type === "youtube"
                ? { $set: {} }
                : { $set: { ...this.state.doc.media_bgoff } },
          },
          dirty:{
            $set:true
          }
        });
      } else {
        newState = update(this.state, {
          doc: {
            media_bgoff: {
              $set: { ...media },
            },
          },
          dirty:{
            $set:true
          }
        });
      }
    }
    this.setState({ ...newState });
  };

  removeMedia = (mediaType) => {
    let newState;
    if (mediaType === MUSIC_ON) {
      newState = update(this.state, {
        doc: {
          total_duration: { $set: 0 },
          intro_duration: { $set: 0 },
          warmup_duration: { $set: 0 },
          outro_duration: { $set: 0 },
          duration: { $set: 0 },
          media: {
            $set: [{ type: "video", url: "" }],
          },
        },
        dirty: {
          $set: true,
        },
      });
    } else {
      newState = update(this.state, {
        doc: {
          media_bgoff: { $set: { type: "video", url: "" } },
        },
        dirty: {
          $set: true,
        },
      });
    }
    this.setState(newState);
  };

  exListData = () => {
    const { doc, exercises: storedExrcs } = this.state;
    let out = doc.groups.map((grp, i) => {
      const exLengh = grp.exercises.length;
      const exId = grp.exercises[0].ref.id;
      const data = storedExrcs && storedExrcs.find((d) => exId === d.id);
      const more = exLengh > 1 ? `+${exLengh - 1} more` : "";
      if (data)
        return {
          ref: grp.exercises[0].ref,
          name: `${data.name} ${more}`,
          groups: grp,
        };
      else return {};
    });
    return out;
  };

  onCircuitReorder = (list) => {
    let updatedGroups = [...this.state.doc.groups];
    updatedGroups = list.map((item) => item.groups);
    let newState = update(this.state, {
      doc: {
        groups: {
          $set: updatedGroups,
        },
      },
    });
    this.setState({ ...newState, errors: {}, dirty: true });
    this.sanitizeGroups();
  };
  onAddExerciseClick = (grpIndex, p = false) => {
    this.setState({ openAddExercise: true, insideGrp: p, grpIndex });
  };
  openWoSelector = () => {
    this.setState({ openWorkoutSelector: true });
  };
  closeWoSegment = () => {
    this.setState({ openWorkoutSelector: false });
  };

  toggleOrigDocPopup = () => {
    this.setState((o) => ({
      viewOrigDoc: !o.viewOrigDoc,
    }));
  };
  stopPublishPipeline=async()=>{
    try{
      const draftDoc=await woRepo(this.context.cid,this.draftFeature)
      .stopPublishPipeline(this.docId);
      if(!draftDoc || !draftDoc.exists) {
        this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
        return;
      }
      const [parsedDraftDoc,exercises]=await this.parseCurrentDoc(draftDoc.data());
      this.setState(o=>({
          draftDoc:{...parsedDraftDoc},
          doc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.doc},
          oldDoc:o.showingDoc===DRAFT?{...parsedDraftDoc}:{...o.oldDoc},
          exercises:[...o.exercises,...exercises]

        }));
      return draftDoc;
    }
    catch(err){
      this.props.enqueueSnackbar(DEFAULT_ERROR,{variant:"error"});
      console.log(err);
    }
  }
   isWoEmpty=()=>{
    if(this.isLongWo){
      return !videoExists(_.get(this.state.doc,"media.0",{}));
    }
    else{
      return !_.get(this.state.doc,"groups",[]).length;
    }
  }
  render() {
    const {
      doc,
      errors,
      editMode,
      exerciseEditorMode,
      targetAreasData,
      equipmentsData,
      sidesData,
      isConfirmationOpen,
      isDeleteConfirmationOpen,
      dataToDelete,
      loader,
      isValidId,
      isDuplicateConfirmationOpen,
      isAddToLibraryConfirmationOpen,
      dataToDuplicate,
      setCircuitReorderDialog,
      dirty,
      oldDoc,
      showingDoc,
      draftDoc,
      viewOrigDoc,
      uploadingError,
      isWoEmpty,
      altExercises=[],
      exercises=[]
    } = this.state;
    const { loading, scheduleDoc = {}, importWoView, isPreview = false,showOnlyEdit=false } = this.props;
    if (!isValidId) return <PageNotFound keyName="workout" />;
    if (this.isNew)
      return (
        <CreateEditForm
          loading={loading}
          isNew
          doc={doc}
          errors={errors}
          handleChange={this.handleMetaChange}
          handleSingleVideo={this.handleSingleVideo}
          onCancel={this.props.onCancel}
          onSubmit={() => this.handlePublish(1)}
          handleMedia={this.handleMedia}
          removeMedia={this.removeMedia}
          targetAreasData={targetAreasData}
          equipmentsData={equipmentsData}
        />
      );
    const exExist = !!get(doc, "groups", []).length;
    const showExerciseSection = !this.isLongWo && ((isPreview && exExist) || !isPreview);
    if (importWoView) {
      return (
        <>
          {loader && (
            <CircularLoader centerAlign={true} style={{ zIndex: 2 }} />
          )}
          <ExerciseEditor
            cid={this.context.cid}
            sidesData={sidesData}
            editorMode={false}
            doc={{ ...this.state.doc, ...scheduleDoc }}
            storedExrcs={exercises}
            selectorMode={true}
            selectedGps={this.props.selectedGps}
            handleGrpAdd={this.handleGrpAdd}
            grpData={this.props.grpData}
            grpIndex={this.state.grpIndex}
            docId={this.docId}
            altExercises={altExercises}
          />
        </>
      );
    }
    return (
      <>
        {loader && <CircularLoader centerAlign={true} style={{ zIndex: 2 }} />}
        <DetailPgStyling>
          {doc && Object.keys(doc) && !loader && (
            <>
              <Details
                id={this.props.id}
                doc={{ ...oldDoc, ...scheduleDoc }}
                openMetaForm={this.editMetaForm}
                targetAreasData={targetAreasData}
                isPreview={isPreview}
                showOnlyEdit={showOnlyEdit}
                copyItem={this.copyItem}
                deleteItem={this.deleteItem}
                disableActions={this.props.disableActions}
                uploadingError={uploadingError}
                videoStatusRefresh={this.videoStatusRefresh}
                editMode={editMode}
                summary={this.state.summary}
                openExEditor={this.openEditor}
                draftBannerProps={{
                  toggleDoc:this.toggleDoc,
                  isDraftAvailable:this.isDraftAvailable,
                  handlePublish:this.handlePublish,
                  discardDraft:this.discardDraft,
                  showingDoc:showingDoc,
                  videoStatusRefresh:this.videoStatusRefresh,
                  draftDoc
                }}
                draftDoc={draftDoc}
                isDraftAvailable={this.isDraftAvailable}
                stopPublishPipeline={this.stopPublishPipeline}
              />
              {editMode && (
                <CreateEditForm
                  loading={loading}
                  doc={doc}
                  errors={errors}
                  // withinSchedule ={!!this.props.disableActions}
                  handleChange={this.handleMetaChange}
                  handleSingleVideo={this.handleSingleVideo}
                  onCancel={this.closeMetaForm}
                  onSubmit={() => this.handleSave(DETAILS)}
                  handleMedia={this.handleMedia}
                  removeMedia={this.removeMedia}
                  targetAreasData={targetAreasData}
                  equipmentsData={equipmentsData}
                  handleDraft={()=>this.handleDraft(true,1)}
                  handlePublish={()=>this.handlePublish(1)}
                  draftFeature={this.draftFeature}
                  isDraftAvailable={this.isDraftAvailable}
                />
              )}
              {showExerciseSection && <Divider className="dense" />}
              {showExerciseSection && (
                <Card square elevation={0} classes={{root: "p-overflow-unset p-height-unset"}}>
                  <CardHeader
                    title="Exercises"
                    action={
                      isPreview ? null : (
                        <IconButton
                          onClick={this.openEditor}
                          className="d-print-none"
                        >
                          {exExist ? (
                            <DetailEditIcon />
                          ) : (
                            <img src={addIcon} alt="" />
                          )}
                        </IconButton>
                      )
                    }
                    classes={{
                      root: "sub-header-root",
                      title: "sub-title",
                      action: "margin-top-negative",
                    }}
                  />
                  <CardContent>
                    {!exExist && (
                      <EditorPlaceholder
                        keyName="workout-editor-placeholder"
                      />
                    )}
                    <ExerciseEditor
                      cid={this.context.cid}
                      disableExClick={this.props.disableExClick}
                      sidesData={sidesData}
                      editorMode={false}
                      doc={{ ...this.state.doc, ...scheduleDoc }}
                      storedExrcs={exercises}
                      selectedGps={this.props.selectedGps}
                      handleGrpAdd={this.handleGrpAdd}
                      grpData={this.props.grpData}
                      grpIndex={this.state.grpIndex}
                      docId={this.docId}
                      altExercises={altExercises}
                      isPreview={isPreview}
                    />
                  </CardContent>
                </Card>
              )}
            </>
          )}
          {exerciseEditorMode && (
            <>
              {!doc && <CircularLoader centerAlign={true} />}
              {!!doc && !this.isLongWo && (
                <Dialog
                  open
                  fullScreen
                  withConfirmation
                  isDirty={this.state.dirty||isWoEmpty}
                  showCustomConfirmation={this.draftFeature && !this.isNew && !isWoEmpty}
                  confirmationProps={{
                    msg:isWoEmpty?EMPTY_WO_DISCARD_MSG:DISCARD_MSG
                  }}
                  appBarColor="bg-white"
                  paperClass="bg-offWhite"
                  titleFont="h3"
                  buttonColor="primary"
                  toolbarClass="height-40"
                  actionText="Save"
                  title={
                    <Title
                      doc={doc}
                      toggleOrigDocPopup={this.toggleOrigDocPopup}
                      viewOrigDoc={viewOrigDoc}
                      isDraftAvailable={this.isDraftAvailable}
                    />
                  }
                  onClose={() => this.closeEditor("close")}
                  onSave={() => this.handleSave(WORKOUT)} //Close Button on UI
                  handleDraft={() => this.handleDraft(true)}
                  handlePublish={this.handlePublish}
                  additionalActions={
                    <ClrdButton
                      className={clsx(
                        "f-medium  mr-10",
                        !!exExist && "border-primary",
                        !this.draftFeature && 'd-none'
                      )}
                      onClick={() => this.handleDraft(false)}
                      disableElevation
                      color={"invert"}
                      variant="contained"
                    >
                      <SaveIcon
                        className={"mr-5"}
                        color={!exExist ? "inherit" : "primary"}
                      />
                      Save
                    </ClrdButton>
                  }
                >
                  <ExerciseEditor
                    cid={this.context.cid}
                    sidesData={sidesData}
                    editorMode
                    doc={doc}
                    storedExrcs={exercises}
                    handleChange={this.handleChange}
                    handleExerciseAdd={this.handleExerciseAdd}
                    handleExTypeValuesChange={this.handleExTypeValuesChange}
                    handleValuesOnSetsChange={this.handleValuesOnSetsChange}
                    handleDelete={this.handleDelete}
                    handleDuplicate={this.showDuplicateConfimation}
                    handleRestChange={this.handleRestChange}
                    handleReorder={this.handleReorder}
                    handleMove={this.handleMove}
                    handleGrpAttributes={this.handleGrpAttributes}
                    handleMetaChange={this.handleMetaChange}
                    sp_feedback={this.state.doc.sp_feedback || false}
                    errors={errors}
                    loading={loading}
                    setNotesState={this.setNotesState}
                    onAddExerciseClick={this.onAddExerciseClick}
                    closeAddExerciseDialog={() =>
                      this.setState({ openAddExercise: false })
                    }
                    open={this.state.openAddExercise}
                    insideGrp={this.state.insideGrp}
                    grpIndex={this.state.grpIndex}
                    targetAreasData={this.state.targetAreasData}
                    equipmentsData={this.state.equipmentsData}
                    muscleGroupsData={this.state.muscleGroupsData}
                    summary={this.state.summary}
                    groupWiseWoDuration={this.state.groupWiseWoDuration}
                    submitSummary={this.submitSummary}
                    onReorderClick={this.handleReorderCircuit}
                    isWoSelectorOpen={this.state.openWorkoutSelector}
                    openWoSelector={this.openWoSelector}
                    closeWoSelector={this.closeWoSegment}
                    handleGrpAdd={this.handleGrpAdd}
                    selectedGps={this.props.selectedGps}
                    grpData={this.props.grpData}
                    docId={this.docId}
                    altExercises={altExercises}
                    handleExerciseAltChange={this.handleExerciseAltChange}
                    handleGrpHeader={this.handleGrpHeader}
                  />
                  {viewOrigDoc && (
                    <PublishedDrawer onClose={this.toggleOrigDocPopup} keyName={VIEWS_CONSTANTS.WORKOUT}/>
                  )}
                </Dialog>
              )}
              {!!doc && !!this.isLongWo && (
                <Dialog
                  open
                  fullScreen
                  withConfirmation
                  isDirty={this.state.dirty||isWoEmpty}
                  showCustomConfirmation={this.draftFeature && !this.isNew && !isWoEmpty}
                  confirmationProps={{
                    msg:isWoEmpty?EMPTY_WO_DISCARD_MSG:DISCARD_MSG
                  }}
                  appBarColor="bg-white"
                  paperClass="bg-offWhite"
                  titleFont="h3"
                  buttonColor="primary"
                  toolbarClass="height-40"
                  actionText="Save"
                  title={
                    <Title
                      doc={doc}
                      toggleOrigDocPopup={this.toggleOrigDocPopup}
                      viewOrigDoc={viewOrigDoc}
                      isDraftAvailable={this.isDraftAvailable}
                    />
                  }
                  onClose={() => this.closeEditor("close")}
                  onSave={() => this.handleSave(WORKOUT)} //Close Button on UI
                  handleDraft={() => this.handleDraft(true)}
                  handlePublish={this.handlePublish}
                  additionalActions={
                    <ClrdButton
                      className={clsx(
                        "f-medium",
                        !this.draftFeature && 'd-none'
                      )}
                      onClick={() => this.handleDraft(false)}
                      disableElevation
                      color={"invert"}
                      variant="contained"
                    >
                      <SaveIcon
                        className={"mr-5"}
                        color={!exExist ? "inherit" : "primary"}
                      />
                      Save
                    </ClrdButton>
                  }
                >
                  <VideoEditor
                    doc={doc}
                    errors={errors}
                    equipmentsData={equipmentsData}
                    targetAreasData={targetAreasData}
                    handleChange={this.handleChange}
                    handleMedia={this.handleMedia}
                    removeMedia={this.removeMedia}
                    isNew={this.isNew}
                    videoStatusRefresh={this.videoStatusRefresh}
                    videoUploadError={uploadingError}
                    updateMediaMeta={this.updateMediaMeta}
                  />
                  {viewOrigDoc && (
                    <PublishedDrawer onClose={this.toggleOrigDocPopup} keyName={VIEWS_CONSTANTS.WORKOUT}/>
                  )}
                </Dialog>
              )}
            </>
          )}
          {isConfirmationOpen && (
            <CustomConfirmation
              handleClose={() => this.setState({ isConfirmationOpen: false })}
              handleDiscard={() => this.closeEditor()}
              handleDraft={() => this.handleDraft(true)}
              handlePublish={this.handlePublish}
              dirty={dirty}
              draftFeature={this.draftFeature}
            />
          )}
          {isDeleteConfirmationOpen && (
            <Confirmation
              open
              handleClose={() => {
                this.setState({ dataToDelete: {} });
                this.setState({ isDeleteConfirmationOpen: false });
              }}
              handleChange={() => this.handleDelete(dataToDelete)}
              handleCancel={() => {
                this.setState({ dataToDelete: {} });
                this.setState({ isDeleteConfirmationOpen: false });
              }}
              title={
                dataToDelete.type === "delete_note"
                  ? "Delete Note"
                  : "Delete Exercise"
              }
              msg={
                dataToDelete.type === "delete_note"
                  ? "Do you want to delete this note?"
                  : "Do you want to delete this exercise?"
              }
              confirmOption="Yes, Continue"
            />
          )}
          {isDuplicateConfirmationOpen && (
            <Confirmation
              open
              handleClose={() =>
                this.setState({
                  isDuplicateConfirmationOpen: false,
                  dataToDuplicate: {},
                })
              }
              handleChange={() => this.handleDuplicate(dataToDuplicate)}
              handleCancel={() =>
                this.setState({
                  isDuplicateConfirmationOpen: false,
                  dataToDuplicate: {},
                })
              }
              title="Duplicate Workout Segment"
              msg="Do you want to duplicate this workout segment?"
              confirmOption="Yes, Continue"
            />
          )}

          <LibraryConfirmation 
            open={isAddToLibraryConfirmationOpen} 
            onClose={this.closeLibraryConfirmation} 
            action1={() => this.handleWorkoutSave(false)}
            action2={() => this.handleWorkoutSave(true)}
          />


          {setCircuitReorderDialog && (
            <ReorderDialog
              open
              listData={this.exListData()}
              onClose={() => {
                this.setState({
                  setCircuitReorderDialog: false,
                });
              }}
              onSubmit={(list) => this.onCircuitReorder(list)}
              title="Reorder Segments"
              hideAddBtn={true}
              hideDeleteBtn={true}
              readOnly={true}
            />
          )}
        </DetailPgStyling>
      </>
    );
  }

  componentDidUpdate(prevProps, prevState){
    //---------------------drawer copy & edit--------------------------
    if(prevProps.showOnlyEdit!=="copy" && this.props.showOnlyEdit==="copy" && this.props.scheduleSaveWoEdit){
      //send back the doc;
      this.props.scheduleSaveWoEdit(this.state.doc);
    }
    //-----------------------summary calculation------------------------
    if ((prevState.doc !== this.state.doc)||(prevState.targetAreasData!==this.state.targetAreasData)
    ||(prevState.equipmentsData!==this.state.equipmentsData)) {
      //avoid recalculating when summary state is updated(Edit Summary form)
      if (
        prevState.summary === this.state.summary &&
        !this.isLongWo &&
        !!this.state.targetAreasData.length &&
        !!this.state.equipmentsData.length
      ) {
        this.calculateSummary();
      }
    }
    //-----------------------media upload check------------------------
    const files=_.get(this.props, "files", []);
    const primaryMediaObj= _.get(this.state, "oldDoc.media.0", {})||{};
    const secondaryMediaObj= _.get(this.state, "oldDoc.media_bgoff", {})||{};
    const primMediaError=checkErrInMediaUpload(primaryMediaObj,files);
    const secMediaError=checkErrInMediaUpload(secondaryMediaObj,files);
    const error={
      media_bgon:!!primMediaError,media_bgoff:!!secMediaError
    }
    if(!_.isEqual(this.state.uploadingError,error)){
      this.setState({ uploadingError: error });
    }
    //-----------------------Update localDoc on file upload--------
    if(primaryMediaObj.status===FILE_STATUS.uploading){
      const isPrimFileInRedux=files.find(i=>i.id===primaryMediaObj.videoId);
      if(!!isPrimFileInRedux && isPrimFileInRedux.status===FILE_STATUS.processing){
        const oldDoc=update(this.state.oldDoc,{
          media:{
            0:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          }
        });
        if(!this.draftFeature){
          //update publishedDoc only
          const publishedDoc=update(this.state.publishedDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({publishedDoc});
        }
        else{
          //update draftDoc only
          const draftDoc=update(this.state.draftDoc,{
            media:{
              0:{
                status:{
                  $set:FILE_STATUS.processing
                }
              }
            }
          });
          this.setState({draftDoc});
        }
        this.setState({
          doc:{...oldDoc},
          oldDoc:{...oldDoc}
        });
      }
    }
    if(secondaryMediaObj.status===FILE_STATUS.uploading){
      const isSecFileInRedux=files.find(i=>i.id===secondaryMediaObj.videoId);
      if(isSecFileInRedux && isSecFileInRedux.status===FILE_STATUS.processing){
        const oldDoc=update(this.state.oldDoc,{
          media_bgoff:{
            status:{
              $set:FILE_STATUS.processing
            }
          }
        });
        if(!this.draftFeature){
          //update publishedDoc only
          const publishedDoc=update(this.state.publishedDoc,{
            media_bgoff:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          });
          this.setState({publishedDoc});
        }
        else{
          //update draftDoc only
          const draftDoc=update(this.state.draftDoc,{
            media_bgoff:{
              status:{
                $set:FILE_STATUS.processing
              }
            }
          });
          this.setState({draftDoc});
        }
        this.setState({
          doc:{...oldDoc},
          oldDoc:{...oldDoc}
        });
      }
    }
    //-----------------------isWoEmpty check------------------------
    if (prevState.doc !== this.state.doc) {
      const isWoEmpty=this.isWoEmpty();
      if(isWoEmpty!==this.state.isWoEmpty){
        this.setState({
          isWoEmpty
        });
      }
    }
  }
  getWoDoc = async () => {
    try{
      this.setState({ loader: true });
      let doc,
        oldDoc = cloneDeep({ ...this.state.oldDoc }),
        publishedDoc = cloneDeep({ ...this.state.publishedDoc }),
        draftDoc = cloneDeep({ ...this.state.draftDoc }),
        showingDoc;
      //get published doc
      const workoutDoc = !this.props.scheduleDoc ? await this.fetchWo() : this.props.scheduleDoc;
      //TODO: Test with scheduleDoc
      if (!(workoutDoc.exists || this.props.scheduleDoc)) {
        this.setState({
          isValidId: false,
          loader: false,
        });
        return;
      }
      publishedDoc = workoutDoc.data ? workoutDoc.data() : workoutDoc;
      //if draft feature is off, treat main collection doc as published
      if(!this.draftFeature){
        publishedDoc['publish_status']=PUBLISHED;
      }
      const publish_status =get(publishedDoc, "publish_status", PUBLISHED);
      if (publish_status.includes("draft")) {
        //get draft doc
        const draftWo = await this.fetchWo(true);
        draftDoc = !!draftWo.data ? draftWo.data() : draftWo;
      }
      if (publish_status === DRAFT_STATES["DRAFT_ONLY"]) {
        doc = { ...draftDoc };
        oldDoc = { ...draftDoc };
        showingDoc = DRAFT;
      } else {
        doc = { ...publishedDoc };
        oldDoc = { ...publishedDoc };
        showingDoc = PUBLISHED;
      }
      const [parsedDoc,exercises]=await this.parseCurrentDoc(doc);
      this.setState(o=>({
        publishedDoc,
        draftDoc,
        loader: false,
        showingDoc,
        doc:{...parsedDoc},
        oldDoc:{...parsedDoc},
        exercises:[...o.exercises,...exercises]
      }));
      }
      catch(err){
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
        this.setState({loader:false})
      }
  };
  parseCurrentDoc = async (doc) => {
    const exIds = [];
    const _doc=cloneDeep(doc);
    //parsing
    _doc.groups=(doc.groups || []).map(group=>{
      group.editNotes = false;
      if (group.type === "dropset" || (group.notes && group.notes.trim() !== "")) {
        group.editNotes = true;
      }
      group.exercises=group.exercises.map(ex=>{
        exIds.push(ex.ref.id);
        ex={...ex,...createNewExStateFromOld({exercise:ex})};
        return ex;
      })
      return group;
    })
    const exercises = (await this.fetchExs(_.uniq(exIds))) || [];
    return  [_doc,exercises];
  };

  componentDidMount() {
    this.setState({ loader: true });
    if (!this.isNew) this.getWoDoc();
    const fetchTargetAreas = () => getTags("targetAreas");
    const fetchSides = () => getTags("sides");
    const fetchEquipments = () => getTags("equipments");
    const fetchMuscleGroups = () => getTags("muscleGroups");
    Promise.all([
      fetchTargetAreas(),
      fetchSides(),
      fetchEquipments(),
      fetchMuscleGroups(),
    ])
      .then(async ([targetAreas, sides, equipments, muscleGroups]) => {
        let targetAreasData = exportTags(targetAreas),
          sidesData = Object.entries(sides) || [],
          equipmentsData = exportTags(equipments),
          muscleGroupsData = exportTags(muscleGroups);
        this.setState({
          targetAreasData,
          sidesData,
          loader: false,
          equipmentsData,
          muscleGroupsData,
        });
      })
      .catch((err) => {
        const msg=!isOnline()?OFFLINE_ERROR:FETCH_ERROR;
        this.props.enqueueSnackbar(msg, {
          variant: "error",
        });
      });
  }

  fetchWo = (isDraft = false) => {
    // if (this.props.loading) return;
    return woRepo(this.context.cid,this.draftFeature).doc(this.docId, null, isDraft);
  };

  fetchExs = async (ids) => {
    const { exercises = [], loader } = this.state;
    let existIds = [];
    if (exercises && exercises.length) {
      exercises.forEach((ex) => {
        existIds.push(ex.id);
      });
    }
    const newIds = ids.filter((d) => existIds.indexOf(d) === -1) || [];
    if (!newIds.length) return [];
    if (!loader) this.props.showLoader();
    var alts=[];
    const exData = await Promise.all(
      newIds.map(async (id) => {
        const cacheData = this.props.objCache &&
          this.props.objCache[id] && { data: () => this.props.objCache[id] };
        const data = cacheData || (await exRepo(this.context.cid,this.draftFeature).doc(id));
        if (!!data) {
          const d = data.data();
          if (!cacheData && this.props.objCache) this.props.objCache[id] = d;
          alts.push(...d.alts||[]);
          return {
            id,
            name: d.ref_name,
            thumbnail: d.thumbnail,
            type: d.type,
            side: d.side||"",
            mediaType:_.get(d,"media.0.type",null),
            is_crop_square:d.is_crop_square,
            is_mute_video:d.is_mute_video,
            alts:d.alts||[],
            category:d.category,
            steps:d.steps,
            source:d.source
          };
        }
      })
    );
    this.fetchAltExercises(_.uniq(alts));
    if (!loader) this.props.hideLoader();
    return exData;
  };
  fetchAltExercises=async(ids=[])=>{
    const newIds = ids.filter((d) => this.state.altExercises.indexOf(d) === -1) || [];
    const altDataSnapshot=await Promise.all(
      newIds.map(i=>exRepo(this.context.cid).doc(i))
    );
    const newAltExercises=altDataSnapshot.map(i=>({
      _id:i.id,
      data:{
        ...i.data(),
        id:i.id
      }
    }));
    const newState = update(this.state, {
      altExercises: {
        $push: [...newAltExercises],
      },
    });
    this.setState({...newState});
  }
}

const mapStateToProps = (s, op) => {
  const id = op.id;
  if (id === "new")
    return {
      doc: { ...DEFAULT_DOC_STATE },
      loading: s.workouts.loading,
      files : s.fileUpload.files
    };
  else return { 
    doc: undefined, 
    loading: s.workouts.loading,
    files : s.fileUpload.files 
  };
};

const mapDispatchToProps = d => {
  const { showLoader, hideLoader } = appRdxFns(d);
  const woFns = woRdxFns(d);
  const { insert: insertWocardio, update: updateWocardio, delete: deleteWocardio } = woCardioRdxFns(d);
  const { uploadFile, uploadBunnyFile } = fileUploadRdxFns(d);
  return { ...woFns, insertWocardio, updateWocardio, deleteWocardio, showLoader, hideLoader, uploadFile,uploadBunnyFile };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(WorkoutDetails)
);

export const Title = ({
  doc,
  toggleOrigDocPopup,
  viewOrigDoc,
  isDraftAvailable,
}) => {
  const hideViewOriginal=get(doc,'publish_status',PUBLISHED)===DRAFT_STATES['DRAFT_ONLY'];
  return (
    <div className="d-flex align-items-center">
      <span className="font_15_600">{get(doc, "title")}{" "}</span>
      {isDraftAvailable && (
        <>
          <DraftText className="ml-30" />
          {!hideViewOriginal && <ClrdButton
            variant="text"
            color="primary"
            className="text-nowrap ml-30"
            onClick={toggleOrigDocPopup}
          >
            {!viewOrigDoc ? (
              <ViewPublishedIcon color="primary" className="mr-10" />
            ) : (
              <ViewingPublishedIcon color="primary" className="mr-10" />
            )}
            View Original
          </ClrdButton>}
        </>
      )}
    </div>
  );
};
