import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Typography,
  Card,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { usePlanFlowStyle } from './usePlanFlowStyle';
import Feature1Icon from '@material-ui/icons/CheckRounded';
import Feature2Icon from '@material-ui/icons/AddRounded';
import PlanEditIcon from 'fitbud/icons/detailEdit';
import { get } from 'lodash';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { roundCurrency } from 'fitbud/utils/helpers';
import { ClrdButton } from 'fitbud/components/form-fields';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuList } from 'fitbud/components/menuList';
import Confirmation from 'fitbud/components/confirmationDialog';
import { ContractContext } from 'fitbud/providers/contract';
import { useDispatch } from 'react-redux';
import appRdxFns from 'fitbud/redux/app';
import { FirebaseAuthContext } from 'fitbud/providers/firebase-auth';
import { bffManageAddOnFeatures } from 'fitbud/api';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { DEFAULT_ERROR } from 'fitbud/utils/constants';
import { useBillingContext } from 'fitbud/providers/billing-provider';
import moment from 'moment';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const AVOID_TARGETS = ['close-dialogue', 'close-promocode', 'switch'];
export const PromoCode = ({
  disabled,
  loading,
  onSubmit,
  onReset,
  discountAmt = 0,
  initPromoCode = null,
  success: couponSuccess,
  errcode,
  msg: apiMessage,
  isYearly,
  className,
  reset,
}) => {
  const classes = usePlanFlowStyle(true);
  const [type, updateType] = useState('text');
  const [value, updateValue] = useState('');
  const [apiHit, updateApiFlag] = useState(false);
  useEffect(() => {
    if (!!initPromoCode) {
      updateType('input');
      updateValue(initPromoCode);
      handleSubmit(initPromoCode);
    } else {
      updateType('text');
      updateValue('');
    }
  }, [initPromoCode]);

  useEffect(()=>{
    if(reset){ //HACK:: when toggle from month to yearly and vice versa, special props rest : true will get. in that case reset own state to initial state.
      updateType('text');
      updateApiFlag(false);
      updateValue('');
    }
  },[reset])

  const handleTypeChange = () => {
    if (type === 'text') updateType('input');
    else updateType('text');
  };
  const handleChange = (e) => {
    const value = e.target.value;
    updateValue(String(value).toUpperCase());
    updateApiFlag(false);
  };
  const handleSubmit = (code) => {
    const _v = code || value;
    if (!_v) return;
    updateApiFlag(true);
    onSubmit(_v);
  };
  const handleBlur = (e, t) => {
    const relatedTarget = get(e, 'relatedTarget.id', null);
    if (relatedTarget && AVOID_TARGETS.includes(relatedTarget)) {
      handleClosePrmCode();
      return;
    } else {
      return handleSubmit();
    }
  };

  const removeCode = () => {
    updateApiFlag(false);
    updateValue('');
    onReset();
  };
  const handleClosePrmCode = () => {
    removeCode();
    updateType('text');
  };
  
  return (
    <div className={clsx('w-100 d-flex justify-content-center', className)}>
      {type === 'text' ? (
        <Button onClick={handleTypeChange} disabled={disabled}>
          <Typography variant="subtitle2" className="text-left cursor-pointer text-primary">
            Have a Promo Code?
          </Typography>
        </Button>
      ) : (
        <div className="w-100">
          <Typography variant="h5" className="d-flex justify-content-between align-items-center fmb-15">
            Promo Code
            <IconButton id="close-promocode" onClick={handleClosePrmCode} className="fp-5">
              <CloseIcon className={classes.twoToneCloseIcon} />
            </IconButton>
          </Typography>
          <TextField
            fullWidth
            autoFocus
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled || apiHit}
            variant="outlined"
            placeholder="Promo code"
            InputProps={{
              endAdornment: value ? (
                <div className="d-flex align-items-center">
                  {!apiHit && (
                    <ClrdButton color="main" className="f-medium" onClick={handleSubmit} disabled={disabled}>
                      Apply
                    </ClrdButton>
                  )}
                  {loading && <CircularProgress size={20} />}
                </div>
              ) : null,
              classes: {
                root: 'medium w-100 ',
                input: 'size_16_500 h-100',
                adornedEnd: 'fpr-10',
              },
            }}
          />
          <div className="d-flex w-100 ">
            {apiMessage && errcode !== 'NOAUTO' && (
              <Typography
                variant="body2"
                className={clsx('w-100 text-left fmt-15', !couponSuccess ? 'text-danger' : 'text-success')}>
                {apiMessage}
              </Typography>
            )}
            <div className="flex-grow" />
            {apiHit && !!discountAmt && (
              <Typography style={{ color: '#212435' }} className="font_16_600 text-nowrap fml-10 fmt-15">
                -${roundCurrency(discountAmt)}
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const SelectedCard = ({
  disabled,
  className,
  selectedPlan,
  goBackToSelectPlan,
  isYearly,
  hideEdit = false,
  control,
}) => {
  const classes = usePlanFlowStyle(true);
  const { name, price, metadata } = selectedPlan;
  const monthlyRate = get(price, 'monthly.cost', 0);
  const annualRate = get(price, 'yearly.cost', 0);
  const { seat_cost, free_seats } = metadata || {};
  const billedAmt = isYearly ? annualRate : monthlyRate;
  const billedText = isYearly ? 'Yearly' : 'Monthly';
  const isPlatinum = selectedPlan?.name === 'Platinum';

  return (
    <Card className={clsx('p-20 mb-25', classes.card, className)}>
      {!!control && control}
      <Typography className="d-flex justify-content-between align-items-center mb-10">
        <span className="fmr-20 font_18_600 text-0d0d0d">{name}</span>
        {!hideEdit && (
          <IconButton onClick={goBackToSelectPlan} disabled={disabled} size="small">
            <PlanEditIcon />
          </IconButton>
        )}
      </Typography>
      {isPlatinum ? (
        <Typography className="font_13_500 text-dark-grey">Billing will be calculated as per the contract</Typography>
      ) : (
        <>
          <Typography className="font_13_500 text-dark-grey mb-5">
            <Feature1Icon fontSize="small" color="primary" className="fmr-20" /> Includes first &nbsp;
            <span className="font_13_700">{free_seats}</span> clients / month
          </Typography>
          <Typography className="font_13_500 text-dark-grey mb-5">
            <Feature2Icon color="primary" className="fmr-20" /> <span className="font-weight-bold">${seat_cost}</span> /
            client / month for over {free_seats} clients
          </Typography>
        </>
      )}
      <hr className="w-100" style={{ borderStyle: 'dashed' }} />
      {isPlatinum ? (
        <Typography className="font_18_600 d-flex justify-content-center">Custom Pricing</Typography>
      ) : (
        <Typography className="d-flex justify-content-between align-items-center">
          <span className="font_13_500 text-dark-grey" style={{ color: '#697380' }}>
            Billed {billedText}
          </span>
          <span className="font_15_600 text-0d0d0d">
            ${billedAmt}&nbsp;<span className="font_13_500">{isYearly ? '/yr' : '/mo'}</span>
          </span>
        </Typography>
      )}
    </Card>
  );
};

const useAddOnStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
  },
  unselected: {
    border: '1px solid #D3D9DB',
  },
  selected: {
    border: '1px solid #317FF5',
  },
}));

export const AddOn = (props) => {
  const { addOn, selected, className, onSelect, disabled, addOnInfo = null, hasTrial = false, previouslyBought = false, trialExpired = false, } = props;
  const isYearly = false; //only showing monthly
  const classes = useAddOnStyles();
  const { name, description, price, id } = addOn || {};
  const monthlyRate = get(price, 'monthly.cost', 0);
  const annualRate = get(price, 'yearly.cost', 0);
  const billedAmt = isYearly ? annualRate : monthlyRate;
  let billedText = (hasTrial && !selected) ? 'Try for Free' : null;
  let dueDate = addOnInfo?.invoicedItems?.[id]?.period?.end;
  if (hasTrial) dueDate = moment().add(7, 'days').unix();
  if (previouslyBought && !selected) billedText = 'Previously Bought';
  if (trialExpired && !selected) billedText = 'Trial Expired';
  if (selected && !hasTrial) billedText = addOnInfo?.invoicedItems?.[id] ? <span>Due today &nbsp;<span className="font_13_600">${addOnInfo?.invoicedItems?.[id].amount / 100}</span>. Next Billing &nbsp;<span className="font_13_600">{moment.unix(addOnInfo?.invoicedItems?.[id].period.end).format('MMM DD, YYYY')}</span></span> : null;
  if (selected && hasTrial) billedText = <span>Free for 7 Days. Billing starts &nbsp;<span className="font_13_600">{moment.unix(dueDate).format('MMM DD, YYYY')}</span>.</span>;
  return (
    <div
      className={clsx(
        'px-20 py-12 cursor-pointer',
        classes.root,
        selected ? classes.selected : classes.unselected,
        className
      )}
      onClick={() => {
        if (disabled) {
          return;
        } else {
          onSelect(id);
        }
      }}>
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <IconButton disabled={disabled} edge="start">
            {selected ? (
              <CheckCircleIcon fontSize="small" color="primary" />
            ) : (
              <AddCircleIcon color="primary" fontSize="small" />
            )}
          </IconButton>
          <Typography className="ml-8 font_15_500 text-0d0d0d flex-grow-1 text-truncate">
            {name}{' '}
            <span>
              <Tooltip title={description}>
                <InfoOutlinedIcon style={{fontSize:18, color:"#0d0d0d"}} size="small" />
              </Tooltip>
            </span>
          </Typography>
          <Typography className="font_13_500 d-flex justify-content-between align-items-center">
            <span className="font_15_600 text-0d0d0d">
              ${billedAmt} <span className="font_13_500">/&nbsp;{isYearly ? 'y' : 'mo'}</span>
            </span>
          </Typography>
          {/* Add divider here and bwlow show the trial details*/}
        </div>

        {billedText && <div>
          <hr style={{ borderStyle: 'dashed' }}/>
          <div className="d-flex text-dark-grey flex-row align-items-left font_13_500">
            {billedText}
          </div>
        </div>}
      </div>
    </div>
  );
};

const useActiveAddOnsStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
    border: '1px solid #D3D9DB',
  },
  icon: {
    fontSize: 20,
    height: 20,
    width: 20,
  },
  ending: {
    color: '#D73717',
  },
  active: {
    color: '#27AE60',
  },
}));

export const ActiveAddOn = (props) => {
  const { data, className, billingPeriodEnd } = props;
  const { name, description } = data;
  const classes = useActiveAddOnsStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const { reload, contract } = useContext(ContractContext);
  const { exitCheckout } = useBillingContext();
  const fkey = _.get(data, 'metadata.fkey', '');
  const d = useDispatch();
  const { showLoader, hideLoader } = appRdxFns(d);
  const { cid } = useContext(FirebaseAuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const futureAddOns = useMemo(() => {
    return _.get(contract, `meta.addOnsFuture.${fkey}`, undefined);
  }, [fkey, contract]);

  const value = useMemo(() => {
    if (futureAddOns) return false;
    else return data.value;
  }, [data, futureAddOns]);

  const trialExpiry = useMemo(() => {
    return data.trialExpiry;
  }, [data]);

  const expiryDate = useMemo(() => {
    return data.dateExpiry ? moment(moment.unix(data.dateExpiry._seconds)) : undefined;
  }, [data, futureAddOns, value]);

  const openConfirmation = () => setConfirmation(true);
  const closeConfirmation = () => setConfirmation(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClickMenuOption = () => {
    openConfirmation();
    handleClose();
  };

  const handleSubmit = async () => {
    const params = {
      cid,
      features: {
        addOns: [{ fkey: _.get(data, 'metadata.fkey'), value: !value }],
      },
    };
    showLoader();
    const response = await bffManageAddOnFeatures(params);
    const { success } = response?.data || {};
    if (!!success) {
      reload && reload();
      hideLoader();
      closeConfirmation();
    } else {
      enqueueSnackbar(DEFAULT_ERROR, { variant: 'error' });
      console.log('>>>>error', response);
      hideLoader();
    }
  };

  const getExpiryDate = () => {
    let expiryDateAddOn = expiryDate; // TODO check if expiry date is in past then use billing cycle.
    if (expiryDateAddOn && moment(expiryDateAddOn).isBefore(moment()) && billingPeriodEnd) expiryDateAddOn = moment.unix(billingPeriodEnd);
    if (value) {
      if (trialExpiry?._seconds > moment().unix()) return `In-Trial • Billing starts ${moment.unix(trialExpiry._seconds).format('D MMM, YYYY')}`
      return `Active • Renews on ${expiryDate ? moment(expiryDate).format('D MMM, YYYY') : 'Next Billing Cycle.'}`;
    } else {
      if (trialExpiry?._seconds > moment().unix()) return `Canceled • Trial Ends on ${moment.unix(trialExpiry._seconds).format('D MMM, YYYY')}`
      return `Canceled • Active until ${expiryDateAddOn ? moment(expiryDateAddOn).format('D MMM, YYYY') : 'Next Billing Cycle.'}`;
    }
  };

  const getMessage = (value) => {
    if (value) {
      return (
        <span>
          You will have benefits of <b>{name}</b> subscription until the end of your { trialExpiry && moment.unix(trialExpiry._seconds) > moment() ? 'trial period' : 'current billing cycle on' }{' '}
          <b>{ ((trialExpiry && moment.unix(trialExpiry._seconds) > moment()) ? moment.unix(trialExpiry._seconds).format('D-MMM-YYYY') : null) || (expiryDate && moment(expiryDate).format('D-MMM-YYYY'))}</b>.
          <br />
          <br />
          <br />
          Do you want to proceed ?
        </span>
      );
    } else {
      return (
        <span>
          You can undo cancellation and keep your add-on subscription of <b>{name}</b>.
          <br />
          <br />
          Do you want to proceed ?
        </span>
      );
    }
  };

  return (
    <div className={clsx('p-20 d-flex align-items-center', classes.root, className)}>
      <div className="flex-grow-1">
        <Typography className="font_15_600 text-0d0d0d" style={{ marginBottom: 8 }}>
          {name}
          <span>
            &nbsp;
            <Tooltip title={description}>
              <InfoOutlinedIcon style={{fontSize:18, color:"#0d0d0d"}} />
            </Tooltip>
          </span>
        </Typography>
        <Typography className="font_13_500 text-grey">{getExpiryDate()}</Typography>
      </div>
      <IconButton edge="end" onClick={handleClick}>
        <MoreVertIcon className={classes.icon} />
      </IconButton>
      <MenuList
        anchorEl={anchorEl}
        onClose={handleClose}
        options={
          value
            ? [{ label: 'Remove', value: 'remove', dense: true }]
            : [{ label: 'Undo Cancellation', value: 'activate', dense: true }]
        }
        handleClick={onClickMenuOption}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      {confirmation && (
        <Confirmation
          open={true}
          handleChange={handleSubmit}
          handleCancel={closeConfirmation}
          title={value ? 'Remove Add-On' : 'Undo Cancellation'}
          msg={getMessage(value)}
          confirmOption={'Proceed'}
        />
      )}
    </div>
  );
};
