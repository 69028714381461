import _ from 'lodash';
import moment from 'moment-timezone';
import uuid from 'uuid/v4';
import firebase from 'fitbud/firebase';
import { bffGetCatalogByIDs, bffUpdateHubspotProp } from 'fitbud/api';
import { multiplier } from 'fitbud/views/schedulePlanner/helper';
import { DEFAULT_CHECKIN_CONFIG, HUBSPOT_PROPS } from "fitbud/utils/constants";
import { getPlanAssignPurchase } from 'fitbud/views/users/helpers';

const insertMaster = (arr, master) => {
  if (!arr || !arr.length) return [[master], master];
  const {s = 0} = master;
  const out = [];
  let done = false;
  arr.forEach(x => {
    if (x.s < s) {
      out.push(x);
      if (!x.d) { // open ended, split it
        if (!done) {
          out.push(master);
          out.push({...x, s: s + 7});
          done = true;
        }
        x.d = s - x.s;
      }
    } else if (x.s <= s + 7) {
      if (!done) {
        out.push(master);
        done = true;
      }
      if (x.d) {
        x.d = x.d - (s + 7 - x.s);
        if (x.d <= 0) return; 
      }
      x.s = s + 7;
      out.push(x);
    } else {
      if (!done) {
        out.push(master);
        done = true;
      }
      out.push(x);
    }
  });
  if (!done) out.push(master);
  const where = out.findIndex(x => (x === master));
  if (where === out.length - 1) delete master.d;
  return [out, master];
};
const addMaster2Arr = (out, entry, s) => {
  const last = out[out.length - 1];
  if (!last || last.d) {
    if (entry) out.push({s, ...entry});
    return;
  }
  if (!entry) {
    // last.d = s - last.s; XXX we're not proactively closing legacy masters
    return;
  }
  const isSame = _.isEqual(_.omit(last, ['s', 'd', 'o']), _.omit(entry, ['s', 'd', 'o']));
  if (isSame) return;
  last.d = s - last.s;
  out.push({s, ...entry});
};
const reverseMasterMap = (map, isWOM = true) => {
  if (!map || _.isEmpty(map)) return [];
  const out = _.chain(map).entries().map(([masterId, ranges]) => {
    return ranges.map(x => ({...x, id: masterId}));
  }).flatten().sortBy('s', 'd').value();
  if (isWOM) return out;
  let n = 0;
  const final = [];
  while (n < out.length) {
    const m = out[n];
    const {s, id, mode = 'single', ...rest} = m;
    if (mode === 'per_day') final.push(m);
    else if (mode === 'single' || mode === 'bmr') final.push({...rest, s, mode, single: id});
    else if (mode === 'on' || mode === 'off') {
      const curr = {...rest, s, mode: 'on_off', [mode]: id};
      final.push(curr);
      const opposite = mode === 'on' ? 'off' : 'on';
      const nxt = out[n + 1];
      if (nxt && nxt.s === s && nxt.mode === opposite) {
        curr[opposite] = nxt.id;
        if (!rest.d || nxt.d <= rest.d) n++;
        else {
          nxt.s += rest.d;
          if (nxt.d) nxt.d -= rest.d;
        }
      }
    }
    n++;
  }
  return final;
};
const _addm = (out, id, range, isSch = false) => {
  if (!out[id]) {
    out[id] = [range];
  } else {
    const last = out[id][out[id].length - 1];
    const {s: s1, o: o1 = 0, mode: m1 = 'single', d} = last;
    const {s: s2, o: o2 = 0, mode: m2 = 'single'} = range;
    if (isSch && d && o2 && s1 + d === s2 && o1 + d === o2) { // last and current are continious both w.r.t start and offset
      if (range.d) last.d += range.d;
      else delete last.d;
    } else if (!isSch && d && s1 + d === s2 && m1 === m2) {
      if (range.d) last.d += range.d;
      else delete last.d;
    } else {
      out[id].push(range);
    }
  }
};
const reverseMasterArr = (arr, isWOM = true) => {
  if (!arr || !arr.length) return {};
  const out = {};
  for (let i = 0; i < arr.length; i++) {
    const {mode, id, single, on, off, ...rest} = arr[i];
    const tmp = {...rest};
    if (isWOM || mode === 'per_day') {
      if (mode) tmp.mode = mode;
      if (id) _addm(out, id, tmp, id !== 'none');
    } else if (mode === 'on_off') {
      if (on) _addm(out, on, {...tmp, mode: 'on'});
      if (off) _addm(out, off, {...tmp, mode: 'off'});
    } else if (id || single) {
      tmp.mode = 'single';
      _addm(out, id || single, tmp);
    }
  }
  return out;
};
const _madd = (out, coll, from, copies, objCache) => {
  if (typeof from === 'string') {
    if (from === 'rest' || from === '-') return;
    const ids = from.split('/');
    ids.forEach(x => {
      if (objCache && objCache[x]) return;
      if (copies && copies[x]) {
        objCache[x] = copies[x];
        return;
      }
      out[x] = true;
    });
  } else if (Array.isArray(from)) {
    from.forEach(x => _madd(out, coll, x, copies, objCache));
  } else if (from && from.onoff) {
    const { on, off } = from;
    if (on) _madd(out, coll, on, copies, objCache);
    if (off) _madd(out, coll, off, copies, objCache);
  } else if (from && from.single && from.single !== 'bmr') {
    _madd(out, coll, from.single, copies, objCache);
  }
};
const chkDirty = (remote, local) => {
  const {weeks: remoteWeeks, ...restRemote} = remote || {};
  const {weeks: localWeeks, ...restLocal} = local || {};
  return !_.isEqual(restRemote, restLocal);
};
const updatedAssignedTill = (startDate, planDuration, womArr, schCache, dataMap) => {
  let x = -1;
  const lastSch = _.last(womArr);
  if (lastSch && lastSch.id) {
    const { s, o = 0, d = 0 } = lastSch;
    const { duration = -1 } = schCache[lastSch.id] || {};
    if (d)
      x = Math.max(x, s + Math.min(d, duration - o) - 1);
    else
      x = Math.max(x, Math.min(planDuration, s + duration - o) - 1);
  }
  if (dataMap) {
    const wmax = _.max(Object.keys(dataMap).map(x => Number(x.replace(/\D/g, ''))));
    if (wmax) {
      const dmax = _.max(Object.keys(dataMap['w' + wmax] || {}).map(x => Number(x.replace(/\D/g, ''))));
      if (dmax) {
        x = Math.max(x, 7 * (wmax - 1) + dmax - 1);
      }
    }
  }
  return moment(startDate).add(x, 'days').format('YYYYMMDD');
};
const bmrResponse = (type, compBMRConfig, responses) => {
  const key = `bmr_${type}`;
  const selectedOptionArr = _.get(responses, key);
  if (!selectedOptionArr || !_.isArray(selectedOptionArr) || !selectedOptionArr.length) return null;
  const selectedOption = selectedOptionArr[0];
  if (!selectedOption || !_.isPlainObject(selectedOption)) return null;
  const compBMROption = _.get(compBMRConfig, key) || _.get(compBMRConfig, type);
  if (!compBMROption || !compBMROption.options || !compBMROption.options.length) return selectedOption;
  const compSelectedOption = _.find(compBMROption.options, ['id', selectedOption.id]);
  return compSelectedOption || selectedOption;
}
const handleEdited = (obj, cid, uid, out, isWO = false) => {
  if (!obj || !obj.id || !cid || !uid) return null;
  if (!obj.edited || !out || obj.id.startsWith('modified_')) return obj;
  const type = isWO ? 'workouts' : 'meals';
  // check if this modification is already built
  const exists = _.find(out[type], x => {
    if (!x) return false;
    if (x.srcId !== obj.id) return false;
    return _.isEqual(_.omit(obj, ['id', '_id', 'srcId', 'archive', 'userProfileId']),
      _.omit(x, ['id', '_id', 'srcId', 'archive', 'userProfileId']));
  });
  if (exists) return exists;
  const newID = 'modified_' + uuid();
  const doc = {...obj, id: newID, srcId: obj.id, archive: true, userProfileId: `${cid}:${uid}`};
  out[type].push(doc);
  return doc;
};

class StateMachine {
  constructor(cid, uid, dispatch, compData, updateUMS, snack) {
    this.cid = cid;
    this.uid = uid;
    this.compData = compData;
    this.bmrMode = _.get(compData, 'features.bmr');
    this.weekThresholdLimit = _.get(compData, 'app_config.week_threshold_limit', 1);
    this.dispatch = dispatch;
    this.updateUMS = updateUMS;
    this.snack = snack;
    this.planRef = null;
    this.loadingweek = null;
    this.mtz = moment;
  }

  genericErrHandler(err, msg) {
    if (err) {
      console.error(err);
      if (err.name === 'ValidationError') {
        this.snack(err.message);
        this.gotoWeek(err.week);
      }
    } else this.snack(msg || 'Oops', { variant: 'error' });
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    const {ready, currWeek, weekReady} = state;
    if (!ready) return;
    if (currWeek >= 0 && !weekReady) {
      this.loadingweek = currWeek; // record the latest value so the loaded data is in sync with what the user wants to see
      this.loadWeek();
    }
  }

  set user(user) {
    if (!user) return;
    const { profile, aplan, deactivated } = user;
    if (!profile || !profile.time_zone) {
      this.mtz = moment;
    } else {
      const tz = profile.time_zone;
      if (!tz || !moment.tz.zone(tz)) this.mtz = moment;
      else this.mtz = (...args) => moment.tz(...args, tz);
    }
    this.init(aplan, deactivated);
    this.initBMR(user);
  }

  initBMR = (user) => {
    if (!user) return;
    const { bmr } = this.compData;
    if (!bmr) return this.dispatch({ type: 'bmr', disabled: true });
    const { automation, profile, onboarding_response } = user;
    const { dob, gender, height, weight } = profile || {};
    const config = (automation && automation.nutrition) || {};
    const responses = {
      activity: bmrResponse('activity', bmr, onboarding_response) || {},
      diet: bmrResponse('diet', bmr, onboarding_response) || {},
      goal: bmrResponse('goal', bmr, onboarding_response) || {}};
    this.dispatch({type: 'bmr', bmrMode: this.bmrMode, dob, gender, height, weight, bmr, responses, ...config});
  };
  getWeekAndDayToday=(startDate,weeks)=>{
    let dayToday = this.mtz().startOf('day').diff(this.mtz(startDate, 'YYYYMMDD').startOf('day'), 'd');
    let week = 0;
    if (dayToday < 0) { // plan is yet to start
      dayToday = -1;
    } else if (weeks && dayToday >= weeks.length * 7) { // entire plan has passed
      dayToday = -1;
      week = weeks.length - 1;
    } else { // today lies somewhere inside the plan
      week = Math.floor(dayToday / 7);
    }
    return {dayToday,week};
  }
  initWithData = (planData, { aplan, buildCmd } = {}) => {
    if (!planData) return;
    const { startDate, weeks } = planData;
    const { dayToday, week }=this.getWeekAndDayToday(startDate, weeks);
    const weekId = weeks[week];
    if (aplan && weekId) { // init scenario
      const out = { plan: planData, weeks: {} };
      out.woms = reverseMasterMap(planData.wom);
      out.mlms = reverseMasterMap(planData.mlm, false);
      out.slms = reverseMasterMap(planData.slm, false);
      this.dispatch({type: 'init', dayToday, aplan, week, weekId, remote: out});
    } else if (buildCmd) {
      const local = { plan: planData, weeks: {}, woms: [], mlms: [], slms: [] };
      this.dispatch({type: 'dobuild', local, buildCmd, startDate, dayToday, week, weekId});
    } else { // reset scenario
      this.dispatch({type: 'reset', startDate, dayToday, week, weekId});
    }
  };

  init = (aplan, isDeactivated = false) => { // resets everything and loads from scratch
    if (!aplan) return; // nothing to load
    if (this.planRef && this.planRef.id === aplan) return; // we've already loaded aplan
    this.planRef = firebase.firestore().doc(`plans/${aplan}`);
    this.planRef.get().then(doc => {
      if (!doc.exists) throw new Error('Schedule is missing');
      const data = doc.data();
      const { duration, weeks } = data;
      if (!duration) return null;
      if (weeks && weeks.length * 7 < duration) { // number of weeks is less than the duration. Fix immediately
        const weeks2add = Math.ceil(duration/7) - weeks.length;
        for (let i = 0; i < weeks2add; i++) weeks.push(uuid());
        return this.planRef.update({ weeks }).then(() => ({
          ...data, weeks
        }));
      }
      return data;
    }).then(data => {
      if (!data) return;
      if (isDeactivated) data.deactivated = true;
      this.initWithData(data, { aplan });
    }).catch(this.genericErrHandler);
  }
  weekL2 = (n,year) => { // XXX for local only
    const { startDate, local: { plan } } = this.state;
    if (!plan || !startDate || typeof n === undefined || n < 0) return ' ';
    const a = this.mtz(startDate, 'YYYYMMDD').add(7 * n, 'd');
    const b = this.mtz(startDate, 'YYYYMMDD').add(7 * n + 6, 'd');
    const format=year ? "D MMM YYYY" : "D MMM";
    return a.format('D MMM') + ' - ' + b.format(format);
  };

  /* NAV AXNS */
  extend = () => {
    this.dispatch({ type: 'edit' });
    window.setTimeout(() => this.addWeeksClick(), 0);
  }
  edit = () => { this.dispatch({ type: 'edit' }); }
  reassign = (userDoc, packs) => { this.dispatch({ type: 'reassign.open', data: {userDoc, packs} }); }
  reassignCancel = () => { this.dispatch({ type: 'reassign.close' }); }
  gotoWeek = (n) => { this.dispatch({ type: 'week.load', x: n }); }
  thisWeek = () => { this.dispatch({ type: 'week.load.curr' }); }
  prevWeek = () => { this.dispatch({ type: 'week.load.prev' }); }
  nextWeek = () => { this.dispatch({ type: 'week.load.next' }); }
  nextWeek = () => { this.dispatch({ type: 'week.load.next' }); }
  doConfirm = (confirmation, action) => { this.dispatch({ type: 'opconfirm.start', confirmation, action }); }
  closeConfirm = (action) => { this.dispatch({ type: 'opconfirm.close' }); }
  applyConfirm = () => {
    const { opconfirm } = this.state;
    if (!opconfirm) return this.closeConfirm();
    const { action } = opconfirm;
    if (action) {
      this.dispatch(action);
    }
    return this.closeConfirm();
  };
  closeEdit = (e, confirmed = false) => {
    const { buildCmd, remote, local, startDate } = this.state;
    if (!confirmed) {
      const isDirty = chkDirty(remote, local);
      if (isDirty) return this.doConfirm({
        handleChange: () => this.closeEdit(null, true),
      }, { type: 'edit.cancel' });
    }
    if (buildCmd && remote && remote.plan) {
      // a build OP got cancelled and there was already a previous aplan => start from scratch
      this.initWithData(remote.plan);
    } else {
      const { weeks } = (remote && remote.plan) || {};
      const { dayToday,week } = this.getWeekAndDayToday(startDate, weeks);
      this.dispatch({ type: 'edit.cancel', dayToday,week });
    }
  }

  /* Quick AXNS */
  removeOverride = (weekId, week, day, key) => {
    this.dispatch({ type: 'rm.override', weekId,
      week: week + 1, day: day + 1, key });
  };

  toggleRest = (weekId, week, day, enabled) => {
    this.dispatch({ type: 'set.wb', weekId,
      week: week + 1, day: day + 1,
      value: enabled ? [] : ['rest'] });
  };

  removeFromDay = (weekId, week, day, objId, key, confirmed = false) => {
    if (!confirmed) {
      return this.doConfirm({
        title: 'Please Confirm', confirmOption: 'Yes, Remove',
        msg: 'Are you sure you want to remove this ' + (key === 'ml' ? 'meal' : 'workout'),
        handleChange: () => this.removeFromDay(weekId, week, day, objId, key, true),
      }, { type: 'edit.cancel' });
    }
    if (key === 'ml') {
      this.dispatch({ type: 'set.mb', weekId,
        week: week + 1, day: day + 1,
        value: 'none' });
    } else {
      const { wa, wb } = _.get(this.state, ['local', 'weeks', weekId, 'data', 'd' + (day + 1)], {});
      if (wb && wb.length) {
        const newVal = _.without(wb, objId);
        this.dispatch({ type: 'set.wb', weekId,
          week: week + 1, day: day + 1,
          value: newVal });
      } else if (wa && wa.length) {
        const newVal = _.without(wa, objId);
        this.dispatch({ type: 'set.wb', weekId,
          week: week + 1, day: day + 1,
          value: newVal });
      }
    }
    this.closeConfirm();
  };

  toggleWCAC = (currWeek, water = false) => {
    const {editing, dayToday, weekToday} = this.state;
    if (!editing || (currWeek < weekToday && dayToday >= 0)) return;
    this.dispatch({ type: 'toggle.wcac', key: water ? 'wc' : 'ac' });
  };

  setWCAC = (key, value) => {
    if (key === 'water')
      this.dispatch({ type: 'set.water', value });
    else
      this.dispatch({ type: 'set.activity', key, value });
  };

  changeMasters = (type, week, newVal, day = undefined, removeOverrides = false, confirmed = false) => {//0 index based
    /*
     * type   | required  | wo/ml/sl
     * week   | required  | week number (0 based). Expected to always be >= week containing today
     * newVal | required  | the new master to insert. `null` is allowed and interpreted as removal of master
     * day    | optional  | the DoW (0 based). Required for all day level ops. Only RHS components can skip it
     * removeOverrides    | only expected to be sent by WO/ML Sch import operation. This will remove any overrides were in place previously
     * */
    const { weekToday, dayToday, local } = this.state;
    const today = Math.max(0, dayToday);
    if (week < weekToday && dayToday >= 0) return; // changes to past masters is not allowd
    if (newVal && !newVal.mode) { // sanitize input value
      delete newVal.s;
      delete newVal.d;
    }
    const key = type + 'ms';
    const other= type==='wo'?['ml','sl']:(type==='ml'?['wo','sl']:['wo','ml'])
    const masters = local[key];
    const dayOfWeek = (typeof day === 'number') ? day : (week === weekToday ? (today % 7) : 0);
    const newStart = Math.max(week * 7 + dayOfWeek, today);
    let chngData = removeOverrides ? { s: newStart } : false; // HACK to tweak the override data. needed by the change master flow
    if (!masters || !masters.length) { // there are no masters at all, nothing to check just insert
      if (!newVal || newVal.remove) return; // XXX this should not be possible, deleting when master is already empty
      return this.dispatch({ type: 'set.masters', key, masters: [{ ...newVal, s: newStart }], chngData: {...chngData, other} });
    } else if (newVal && newVal.remove) {
      if (!confirmed)
        return this.doConfirm({
          title: 'Please Confirm', confirmOption: 'Yes, Remove',
          msg: 'Are you sure you want to remove this ' + (type === 'ml' ? 'meal' : 'supplement') + '. This will impact all subsequent weeks.',
          handleChange: () => this.changeMasters(type, week, newVal, day, removeOverrides = false, true),
        }, { type: 'edit.cancel' });
      else this.closeConfirm();
    }
    const dayToCheck = week * 7 + (typeof day === 'number' ? day : 6);
    const newMasters = [];
    let done = false;
    for (let i = 0; i < masters.length; i++) {
      const m = masters[i];
      const {s, d, o, ...rest} = m;
      if (s > dayToCheck) { // master is after D7 of this week
        if (newVal && !newVal.remove) newMasters.push({ ...newVal, s: newStart }); // XXX !newVal should not be possible
        done = true;
        break;
      } else if (d && (s + d) <= dayToCheck) { // master is before D7 of this week
        newMasters.push(m);
      } else { // there is a currently applicable master - close it, if closure implies no days, overtake it
        const prevDuration = newStart - s;
        if (newVal && newVal.remove) { // special case, removal of key from existing master
          const key2remove = newVal.remove;
          newVal = {...rest};
          delete newVal[key2remove];
          if (prevDuration <= 0) {
            newMasters.push({...newVal, s });
            if (removeOverrides) chngData = {s};
          } else {
            if (o) rest.o = o;
            newMasters.push({...rest, s, d: prevDuration}, { ...newVal, s: newStart });
            if (removeOverrides) chngData = {s: newStart}
          }
        } else if (prevDuration <= 0) { // this master needs to be overtaken OR deleted entirely
          if (newVal) {
            if ((type === 'ml' || type === 'sl') && newVal.mode)
              newMasters.push({...newVal, s });
            else
              newMasters.push({...rest, ...newVal, s });
            if (removeOverrides) chngData = {s};
          }
          // XXX there is no else scenario because that implies delete master which will happen automatically upon break
        } else if (newVal) {
          if (o) rest.o = o;
          newMasters.push({...rest, s, d: prevDuration}, { ...newVal, s: newStart });
          if (removeOverrides) chngData = {s: newStart};
        } else {
          if (o) rest.o = o;
          newMasters.push({...rest, s, d: prevDuration});
        }
        done = true
        break;
      }
    }
    if (!done && newVal) // if still not done. XXX !newVal should not be possible here
      newMasters.push({ ...newVal, s: newStart });
    if (!chngData && type !== 'sl') chngData = {s: newStart};
    this.dispatch({ type: 'set.masters', key, masters: newMasters, chngData: {...chngData, other} });
  };

  updateBMR = (changes) => {
    this.dispatch({type: 'bmr', bmrMode: this.bmrMode, ...changes});
  };

  /* Add Weeks */
  addWeeksCancel = (e) => { this.dispatch({ type: 'opaddwks.close' }); }
  addWeeksClick = (e) => { this.dispatch({ type: 'opaddwks.start' }); }
  addWeeksSave = (n) => { 
    const { startDate } = this.state;
    const dayToday=this.mtz().startOf('day').diff(this.mtz(startDate, 'YYYYMMDD').startOf('day'), 'd');
    this.dispatch({ type: 'opaddwks.process', n, dayToday,week: Math.floor(dayToday / 7)}); 
  }

  /* COPY AXNS */
  copyClose = (e) => { this.dispatch({ type: 'opcopy.close' }); }
  copySingleItem = (weekId, day, type, id) => {
    day = 1 + day;
    if (!id) return;
    if (type === 'ml')
      this.dispatch({ type: 'opcopy.start', weekId, day, purpose: 'singleML', id });
    else
      this.dispatch({ type: 'opcopy.start', weekId, day, purpose: 'singleWO', id });
  };
  copyClick = (e) => {
    /* event should be associated with required dataset. Allowed datasets:
     * - Copy from Day AXNs: weekId , day (string) , purpose: wo/ml/both(default), woId, mlId
     * - Copy from Week AXNs: weekId, purpose: wo/ml/sl
     * */
    if (!e || !e.currentTarget) return; // coz we'll be reading everything from the event target
    const data = e.currentTarget.dataset;
    const { week:weekId, day:str, purpose } = data;
    if (!purpose || !weekId) return; // purpose & week are needed for all copy OPs except single item copy, which is above
    if (str >= '0' && str < '7') { // i.e it's a day level OP
      const day = 1 + Number(str);
      this.dispatch({ type: 'opcopy.start', weekId, day, purpose });
    } else if (purpose) { // week level OPs must have a purpose
      this.dispatch({ type: 'opcopy.start', weekId, purpose });
    }
  };
  copyPaste = async (e) => {
    const wosToFetch = {};
    const mlsToFetch = {};
    const dataset = e.currentTarget.dataset;
    const { opcopy, weekToday, dayToday, local, objCache, lgcyOvrs, dfrdOvrs } = this.state;
    if (!opcopy) return;
    const { weekId: srcWeekId, day: srcDay, purpose, id } = this.state.opcopy;
    const { weekid: weekId, week: str0, day: str1 } = dataset;
    const week = 1 + Number(str0);
    const day = str1 >= '0' && str1 < 7 ? 1 + Number(str1) : 0;
    if (purpose === 'singleWO') {
      if (!id) return;
      if (id.startsWith('modified_') && !_.find(dfrdOvrs.workouts, ['id', id])) {
        const lgcyObj = _.find(lgcyOvrs.workouts, ['id', id]);
        if (lgcyObj) {dfrdOvrs.workouts.push(lgcyObj); objCache[id] = lgcyObj;}
      }
      if (!objCache[id]) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, workouts: [id] });
        if (data.success) {
          if (data.workouts) data.workouts.forEach(x => (objCache[x._id] = x));
        }
      }
      const { wa: wad, wb: wbd } = _.get(local, ['weeks', weekId, 'data', 'd' + day]) || {};
      let wDst = [...(wbd || wad || [])];
      if (wDst.length && wDst[0] === 'rest')
        wDst = [id];
      else {
        if (wDst.includes(id)) return this.snack('Workout already present on this day', { variant: 'warning' });
        wDst.push(id);
      }
      this.dispatch({ type: 'halt' });
      this.dispatch({ type: 'set.wb', weekId, week, day, value: wDst });
      this.dispatch({ type: 'unhalt' });
      return;
    } else if (purpose === 'singleML') {
      if (!id) return;
      if (id.startsWith('modified_') && !_.find(dfrdOvrs.meals, ['id', id])) {
        const lgcyObj = _.find(lgcyOvrs.meals, ['id', id]);
        if (lgcyObj) {dfrdOvrs.meals.push(lgcyObj); objCache[id] = lgcyObj;}
      }
      if (!objCache[id]) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, meals: [id] });
        if (data.success) {
          if (data.meals) data.meals.forEach(x => (objCache[x._id] = x));
        }
      }
      this.dispatch({ type: 'halt' });
      this.dispatch({ type: 'set.mb', weekId, week, day, value: id });
      this.dispatch({ type: 'unhalt' });
      return;
    } else if (['wo', 'ml', 'both'].includes(purpose)) {
      if (week <= weekToday && dayToday >= 0) return;
      const doWO = purpose === 'wo' || purpose === 'both';
      let doML = purpose === 'ml' || purpose === 'both';
      let doMLM = false, didML = false;
      if (!day && doML) {
        const srcMLM = _.get(local, ['weeks', srcWeekId, 'ml']) || {mode: 'per_day'};
        if (srcMLM.mode !== 'per_day') {
          const {single, on, off} = srcMLM;
          [single, on, off].forEach(id => {
            if (!id || !id.startsWith('modified_') || _.find(dfrdOvrs.meals, ['id', id])) return;
            const lgcyObj = _.find(lgcyOvrs.meals, ['id', id]);
            if (lgcyObj) {dfrdOvrs.meals.push(lgcyObj); objCache[id] = lgcyObj;}
          });
          doML = false;
          doMLM = {...srcMLM, s: 7 * (week - 1), d: 7};
        }
      } else if (day) {
        // const dstMLM = _.get(local, ['weeks', weekId, 'ml']);
        // if (!dstMLM || dstMLM.mode !== 'per_day') doML = false;
      }
      this.dispatch({ type: 'halt' });
      for (let i = (day || 1); i <= (day || 7); i++) {
        if (7 * (week - 1) + i - 1 < dayToday) continue;
        const { wa, wb, ma, mb, copy } = _.get(local, ['weeks', srcWeekId, 'data', 'd' + (srcDay || i)]) || {};
        let srcRest = false;
        if (doWO) {
          _madd(wosToFetch, 'workouts', wb || wa, copy, objCache);
          const wSrc = wb || wa || [];
          srcRest = wSrc.length && wSrc[0] === 'rest';
          wSrc.forEach(id => {
            if (!id.startsWith('modified_') || _.find(dfrdOvrs.workouts, ['id', id])) return;
            const lgcyObj = _.find(lgcyOvrs.workouts, ['id', id]);
            if (lgcyObj) {dfrdOvrs.workouts.push(lgcyObj); objCache[id] = lgcyObj;}
          });
          this.dispatch({ type: 'set.wb', weekId, week, day: i, value: [...wSrc] });
        }
        if (doML && (mb || ma)) {
          didML = true;
          _madd(mlsToFetch, 'meals', mb || ma, copy, objCache);
          const { onoff, on, off, single } = mb || ma;
          const mlid = (!onoff ? single : (srcRest ? off : on)) || null;
          if (mlid) {
            if (mlid.startsWith('modified_') && !_.find(dfrdOvrs.meals, ['id', mlid])) {
              const lgcyObj = _.find(lgcyOvrs.meals, ['id', mlid]);
              if (lgcyObj) {dfrdOvrs.meals.push(lgcyObj); objCache[id] = lgcyObj;}
            }
            this.dispatch({ type: 'set.mb', weekId, week, day: i, value: mlid });
          } else didML = false;
        }
      }
      const workouts = Object.keys(wosToFetch);
      const meals = Object.keys(mlsToFetch);
      if (workouts.length || meals.length) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, workouts, meals });
        if (data.success) {
          if (data.workouts) data.workouts.forEach(x => (objCache[x._id] = x));
          if (data.meals) data.meals.forEach(x => (objCache[x._id] = x));
        }
      }
      if (doMLM) {
        const [newMasters, inserted] = insertMaster(local.mlms, doMLM);
        this.dispatch({ type: 'set.masters', key: 'mlms', masters: newMasters, chngData: {...inserted, other: ['wo']} });
      } else if (!day && didML) {
        const [newMasters] = insertMaster(local.mlms, {id: 'none', mode: 'per_day', s: 7 * (week - 1), d: 7});
        this.dispatch({ type: 'set.masters', key: 'mlms', masters: newMasters });
      }
      this.dispatch({ type: 'unhalt' });
    } else {
      this.copyClose();
    }
  }
  cutPaste = async (weekId, weekStr, srcDayStr, srcIndex, dstDayStr, dstIndex, type, id) => {
    const { objCache } = this.state;
    const week = Number(weekStr) + 1;
    const srcDay = Number(srcDayStr) + 1;
    const dstDay = Number(dstDayStr) + 1;
    const { local } = this.state;
    if (type === 'ml') { // index don't matter for ml, just null the src and set the dst
      if (srcDay === dstDay) return; // meal dropped on same day is a no-op
      this.dispatch({ type: 'set.mb', weekId, week, day: srcDay, value: null });
      if (!objCache[id]) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, meals: [id] });
        if (data.success) {
          if (data.meals) data.meals.forEach(x => (objCache[x._id] = x));
        }
      }
      this.dispatch({ type: 'set.mb', weekId, week, day: dstDay, value: id });
    } else if (srcDay === dstDay) { // re-order within the same day
      if (srcIndex === dstIndex) return; // nothing has changed
      const { wa, wb } = _.get(local, ['weeks', weekId, 'data', 'd' + srcDay]) || {};
      const wSrc = [...(wb || wa || [])];
      wSrc.splice(srcIndex, 1);
      wSrc.splice(dstIndex, 0, id);
      this.dispatch({ type: 'set.wb', weekId, week, day: srcDay, value: wSrc });
    } else {
      const { wa: was, wb: wbs } = _.get(local, ['weeks', weekId, 'data', 'd' + srcDay]) || {};
      const { wa: wad, wb: wbd, copy } = _.get(local, ['weeks', weekId, 'data', 'd' + dstDay]) || {};
      const wSrc = [...(wbs || was || [])];
      let wDst = [...(wbd || wad || [])];
      wSrc.splice(srcIndex, 1);
      if (wDst.length && wDst[0] === 'rest')
        wDst = [id];
      else
        wDst.splice(dstIndex, 0, id);
      this.dispatch({ type: 'set.wb', weekId, week, day: srcDay, value: wSrc });
      const  wosToFetch = {};
      _madd(wosToFetch, 'workouts', wDst, copy, objCache);
      const workouts = Object.keys(wosToFetch);
      if (workouts.length) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, workouts });
        if (data.success) {
          if (data.workouts) data.workouts.forEach(x => (objCache[x._id] = x));
        }
      }
      this.dispatch({ type: 'set.wb', weekId, week, day: dstDay, value: wDst });
    }
  }

  /* IMPORT AXNS */
  mngAltClose = (e) => { this.dispatch({ type: 'opmngalt.close' }); }
  mngAltSave = (ids, workouts) => {
    const { objCache, opmngalt } = this.state;
    if (!opmngalt) return;
    const { weekId, week, day, index } = opmngalt;
    workouts.forEach((workout) => {
      objCache[workout.id] = workout; // XXX we're modifying state directly. Dispatch will happen below
    });
    const { wa, wb } = _.get(this.state, ['local', 'weeks', weekId, 'data', 'd' + (day + 1)], {});
    if (wb && wb.length) {
      const newVal = [...wb];
      newVal.splice(index, 1, ids.join('/'));
      this.dispatch({ type: 'set.wb', weekId,
        week: week + 1, day: day + 1,
        value: newVal });
    } else if (wa && wa.length) {
      const newVal = [...wa];
      newVal.splice(index, 1, ids.join('/'));
      this.dispatch({ type: 'set.wb', weekId,
        week: week + 1, day: day + 1,
        value: newVal });
    }
    this.mngAltClose();
  };

  /* IMPORT AXNS */
  importClose = (e) => { this.dispatch({ type: 'opimport.close' }); }
  importClick = (e) => {
    /* event should be associated with required dataset. Allowed datasets:
     * - import on Day AXNs: week/weekId , day , purpose: wo/woSch - ml/mlms/mlSch/bmr - sl/slms - water/activity
     * */
    if (!e || !e.currentTarget) return; // coz we'll be reading everything from the event target
    const data = e.currentTarget.dataset;
    const { week:wstr, weekid:weekId, day:str, purpose, ...rest } = data;
    if (!purpose || !weekId || !wstr) return;
    const week = Number(wstr);
    const day = str >= '0' && str < '7' ? Number(str) : 0;
    const existing = [], existingIDs = [];
    let index = null; // index used by add alternate flow
    if (purpose === 'wo') { // build list of existing WOs
      const { local, objCache } = this.state;
      const { wa, wb } = _.get(local, ['weeks', weekId, 'data', 'd' + (day + 1)], {});
      const ids = wb ? wb : wa;
      if (ids && ids.length && ids[0] !== 'rest') {
        ids.forEach(top => {
          const alts = top.split('/');
          existingIDs.push(top);
          alts.forEach(id => {
            const data = objCache[id];
            if (!data) return;
            existing.push({...data, id});
          });
        });
      }
    } else if (purpose === 'altWo') { // build list of existing alts
      const {id, index:str} = rest;
      index = Number(str);
      const ids = id.split('/');
      const { local, objCache } = this.state;
      ids.forEach(id => {
        const data = objCache[id];
        if (!data) return;
        existing.push({...data, id});
      });
      if (ids.length > 1)
        return this.dispatch({ type: 'opmngalt.start', weekId, week, day, ids, existing, index });
      // else add other WOs of the day to existing pool
      const { wa, wb } = _.get(local, ['weeks', weekId, 'data', 'd' + (day + 1)], {});
      const allIDs = wb ? wb : wa;
      if (allIDs && allIDs.length && allIDs[0] !== 'rest') {
        allIDs.forEach(top => {
          if (top === id) return;
          const alts = top.split('/');
          alts.forEach(id => {
            const data = objCache[id];
            if (!data) return;
            existing.push({...data, id});
          });
        });
      }
    }
    else if(purpose==="mlms"){// build list of existing Mls
      const { local, objCache } = this.state;
      const { ma } = _.get(local, ['weeks', weekId, 'data', 'd' + (day + 1)], {});
      if(!!Object.keys(ma).length){
        const {onoff,on,off,single}=ma;
        if(!onoff){
          existingIDs.push(single);
          existing.push({data:{...objCache[single]},_id:single})
        }
        else{
          existingIDs.push(on);
          existingIDs.push(off);
          existing.push({data:{...objCache[on]},_id:on});
          existing.push({data:{...objCache[off]},_id:off});
        }
      }
    }
    else if(purpose==="slms"){// build list of existing Sls
      const { local, objCache } = this.state;
      const { sa } = _.get(local, ['weeks', weekId, 'data', 'd' + (day + 1)], {});
      if(!!Object.keys(sa).length){
        const {onoff,on,off,single}=sa;
        if(!onoff){
          existingIDs.push(single);
          existing.push({data:{...objCache[single]},_id:single})
        }
        else{
          existingIDs.push(on);
          existingIDs.push(off);
          existing.push({data:{...objCache[on]},_id:on});
          existing.push({data:{...objCache[off]},_id:off});
        }
      }
    }
    this.dispatch({ type: 'opimport.start', weekId, week, day, purpose, existingIDs, existing, index });
  };
  importSave =async (op, args) => {
    /*
      ALIA : update the comment for altwo
      Allowed purpose:
      wo-wo(per_day),woSch(wo schedule)
      ml- ml(per_day),mlms-single(single for entire schd),mlms-on_off(on-off for entire schd)
      sl- sl(per_day),sl-single(single for entire schd)
    */
    const {objCache} = this.state;
    const {week, weekId, day, purpose, existingIDs, index} = op;
    if (purpose === 'altWo') {
      if (!args || !args.length) return this.importClose();
      const ids = [];
      args.forEach(({_id, data}) => {
        objCache[_id] = data; // XXX we're modifying state directly. Dispatch will happen below
        ids.push(_id);
      });
      const { wa, wb } = _.get(this.state, ['local', 'weeks', weekId, 'data', 'd' + (day + 1)], {});
      if (wb && wb.length) {
        const newVal = [...wb];
        const old = newVal[index];
        newVal.splice(index, 1, [old, ...ids].join('/'));
        this.dispatch({ type: 'set.wb', weekId,
          week: week + 1, day: day + 1,
          value: newVal });
      } else if (wa && wa.length) {
        const newVal = [...wa];
        const old = newVal[index];
        newVal.splice(index, 1, [old, ...ids].join('/'));
        this.dispatch({ type: 'set.wb', weekId,
          week: week + 1, day: day + 1,
          value: newVal });
      }
      this.importClose();
    } else if (purpose === 'wo') {
      if (!args || !args.length) return this.importClose();
      const newIds = [...existingIDs];
      args.forEach(({_id, data}) => {
        objCache[_id] = data; // XXX we're modifying state directly. Dispatch will happen below
        newIds.push(_id);
      });
      this.dispatch({ type: 'set.wb', weekId,
        week: week + 1, day: day + 1,
        value: newIds });
      this.importClose();
    } else if (purpose === 'ml') {
      if (!args || args.length !== 1) return this.importClose();
      const {_id, data} = args[0];
      objCache[_id] = data; // XXX we're modifying state directly. Dispatch will happen below
      this.dispatch({ type: 'set.mb', weekId,
        week: week + 1, day: day + 1, value: _id });
      bffUpdateHubspotProp(HUBSPOT_PROPS.NUTRITION_SCHEDULE_ASSIGNED);
      this.importClose();
    } else if(purpose==="sl"){
        if (!args || args.length !== 1) return this.importClose();
        const {_id, data} = args[0];
        objCache[_id] = data; // XXX we're modifying state directly. Dispatch will happen below
        this.dispatch({ 
          type: 'set.sb', 
          weekId,
          week: week + 1, 
          day: day + 1, 
          value: _id 
        });
        this.importClose();
    } else if (purpose === 'woSch') {
      const obj = args[0];
      if (!obj) return;
      objCache[obj._id] = obj.data; // XXX we're modifying state directly. Dispatch will happen below
      this.changeMasters('wo', week, {id: obj._id}, day || 0, true);
      bffUpdateHubspotProp(HUBSPOT_PROPS.FITNESS_SCHEDULE_ASSIGNED);
    } else if (purpose === 'mlSch') {
      const obj = args[0];
      if (!obj) return;
      objCache[obj._id] = obj.data; // XXX we're modifying state directly. Dispatch will happen below
      // this.changeMasters('ml', week, {id: obj._id, mode: 'per_day'}, day || 0, true);
      const newVal = {id: obj._id, mode: 'per_day'};
      const { duration, weekday_align } = obj.data || {};
      if (weekday_align === 2 && duration && duration % 7 === 0)
        newVal.r = true;
      this.changeMasters('ml', week, newVal, day || 0, true);
      bffUpdateHubspotProp(HUBSPOT_PROPS.NUTRITION_SCHEDULE_ASSIGNED);
    } else if (purpose.startsWith('mlms') || purpose.startsWith('slms')) {//single or on_off
      const obj = args[0];
      if (!obj) return;
      //ALIA: Pay attention here
      const [key, key2] = purpose.split('-');
      objCache[obj._id] = obj.data; // XXX we're modifying state directly. Dispatch will happen below
      if(key2==="on_off"){
        const off_obj=args[1];
        const _v={
          mode:"on_off",
          on:obj._id,
          off:off_obj._id
        }
        await this.changeMasters(key.substring(0, 2), week, {..._v},day||0,true);
        // const off_obj=args[1];
        // if(!off_obj) return;
        objCache[off_obj._id] = off_obj.data; // XXX we're modifying state directly. Dispatch will happen below
        // this.changeMasters(key.substring(0, 2), week, {["off"]: off_obj._id},day||0);
        //ALIA: Pay attention here
      }
      else{
        //single
        this.changeMasters(key.substring(0, 2), week, {id: obj._id, mode:"single"},day||0,true);
      }
      if(purpose.startsWith('mlms')) bffUpdateHubspotProp(HUBSPOT_PROPS.NUTRITION_SCHEDULE_ASSIGNED);
      this.importClose();
    } else {
      this.genericErrHandler(null, `WIP`);
      this.importClose();
    }
  }

  /* BUILD A NEW aplan */
  build = (startDate, { duration, num, durationType }, pack, calling = null) => {
    if (!duration)
      duration = num * multiplier(durationType);
    if (!duration || duration < 0) return;
    const buildCmd = { pack, calling, duration, durationType };
    const numWeeks = Math.ceil(duration / 7);
    const weeks = Array(numWeeks).fill().map(() => (uuid()));
    const fakePlan = { startDate, weeks,  duration, durationType };
    this.initWithData(fakePlan, { buildCmd });
  };
  assignPlan = (userDoc) => {
    if (!userDoc) return;
    const { startDate, duration, durationType, plan_assign, vid_call } = userDoc;
    this.build(startDate, { duration, durationType }, plan_assign, vid_call);
  };

  /* LOAD WEEK DATA SIDE FX */
  async loadWeek() {
    const { emm, startDate, editing, dayToday, weekToday, currWeek, currWeekId, remote, local, schCache, objCache } = this.state;
    const today = Math.max(0, dayToday);
    const currentSrc = editing ? local : remote;
    let rawData = null, backup = null;
    if (editing && currentSrc.weeks[currWeekId]) backup = currentSrc.weeks[currWeekId];
    if (!editing && currentSrc.weeks[currWeekId]) {
      rawData = currentSrc.weeks[currWeekId];
    } else if (!this.planRef) { // there is no plan, week must be fake
      rawData = { fromDate: this.mtz(startDate, 'YYYYMMDD').add(7 * currWeek, 'd'),
        data: {}, ml: {}, sl: {}, water: {}, activity: {} };
    } else if (backup && backup.legacy ) {
      rawData = backup;
    } else {
      try {
        const weekDoc = await this.planRef.collection('weeks').doc(currWeekId).get();
        if (currWeek !== this.loadingweek) return;
        if (!weekDoc.exists) { // fallback to an empty week
          rawData = { fromDate: this.mtz(startDate, 'YYYYMMDD').add(7 * currWeek, 'd'),
            data: {}, ml: {}, sl: {}, water: {}, activity: {} };
        } else {
          rawData = _.omit(weekDoc.data(),['sl','ml']);
        }
      } catch(e) {
        this.genericErrHandler(e);
        return;
      }
    }
    if ((currWeek < weekToday && dayToday >= 0)||(dayToday===-1 && !!weekToday)) { // past week, work with whatever is in the DB verbatim | schedule over
      window.setTimeout(() => {
        this.dispatch({ type: 'week.ready', weekId: currWeekId, data: this.buildFromLegacy(rawData, {past: editing}) });
      }, 0);
      return;
    }
    if (!editing && emm.needed) { // future week in read mode if not migrated, just use the data on record
      window.setTimeout(() => {
        this.dispatch({ type: 'week.ready', weekId: currWeekId, data: this.buildFromLegacy(rawData) });
      }, 0);
      return;
    }
    if (editing && emm.needed && !emm.isDone) { // LOAD ALL FUTURE WEEKS AND MARK EMM AS DONE
      await this.editFromLegacy();
      return;
    }
    try { // apply masters and overrides
      const dayStart = (currWeek < weekToday && dayToday >= 0) ? 7 : (currWeek === weekToday ? (today % 7) : 0); // day of the week to start from (0 based)
      const {woms=[], mlms=[], slms=[], plan} = currentSrc;
      const wosToFetch = {};
      const mlsToFetch = {};
      const slsToFetch = {};
      const masterMap = {ml: {}, sl: {}, d1: {wom: null, mlm: null, wo: [], ml: {}, sl: {}},
        d2: {wom: null, mlm: null, wo: [], ml: {}, sl: {}}, d3: {wo: [], ml: {}, sl: {}}, d4: {wo: [], ml: {}, sl: {}},
        d5: {wom: null, mlm: null, wo: [], ml: {}, sl: {}}, d6: {wo: [], ml: {}, sl: {}}, d7: {wo: [], ml: {}, sl: {}}};
      await Promise.all([
        this.getWeekFromMasters(masterMap, 'wo', woms, currWeek, dayStart, { schCache, rawData }),
        this.getWeekFromMasters(masterMap, 'ml', mlms, currWeek, dayStart, { schCache, rawData, objCache }),
        this.getWeekFromMasters(masterMap, 'sl', slms, currWeek, dayStart, { rawData,objCache })]);
      const overrides = _.get(plan, ['data', 'w' + (currWeek + 1)], {});
      const wtrCurr = _.get(plan, 'wc', {required: false});
      const actCurr = _.get(plan, 'ac', {required: false});
      // if (dayStart < 7) {
      //   rawData.ml = masterMap.ml;
      //   rawData.sl = masterMap.sl;
      // }
      for (let i = 0; i < 7; i++) {
        const key = 'd' + (i + 1);
        const {id, copy} = _.get(rawData, ['data', key]) || {};
        const {wo: wa, ml: ma, sl: sa, wom, mlm, slm} = masterMap[key] || {};
        const {wo: wb, ml} = overrides[key] || {};
        const mb = ml ? {onoff: false, single: ml} : null;
        if (i < dayStart) { // past day
          if (emm.needed && currWeek === weekToday) {
            _.set(rawData, ['data', key], _.get(backup, ['data', key]));
          } else {
            _.set(rawData, ['data', key, 'wom'], wom);
            _.set(rawData, ['data', key, 'mlm'], mlm);
          }
        } else {
          // if (mb && _.isEmpty(rawData.ml)) // any meal override means per day
            // rawData.ml = {mode: 'per_day'};
          _.set(rawData, ['data', key], {id, copy, wa, ma, sa, wb, mb, wom, mlm,slm});
          rawData.water = wtrCurr;
          rawData.activity = actCurr;
          _madd(wosToFetch, 'workouts', wa, copy, objCache);
          _madd(wosToFetch, 'workouts', wb, copy, objCache);
          _madd(mlsToFetch, 'meals', ma, copy, objCache);
          _madd(mlsToFetch, 'meals', mb, copy, objCache);
          _madd(slsToFetch, 'supplements', sa, copy, objCache);
          // _madd(slsToFetch, 'meals', i, sb, copy);
        }
      }
      const workouts = Object.keys(wosToFetch);
      const meals = Object.keys(mlsToFetch);
      const supplements = Object.keys(slsToFetch);
      if (workouts.length || meals.length || supplements.length) {
        const { data } = await bffGetCatalogByIDs({ cid: this.cid, workouts, meals, supplements });
        if (data.success) {
          if (data.workouts) data.workouts.forEach(x => (objCache[x._id] = x));
          if (data.meals) data.meals.forEach(x => (objCache[x._id] = x));
          if (data.supplements) data.supplements.forEach(x => (objCache[x._id] = x));
        }
      }
      window.setTimeout(() => { // we don't want to update state from within setstate
        this.dispatch({ type: 'week.ready', weekId: currWeekId, data: rawData });
      }, 0);
    } catch(e) {
      this.genericErrHandler(e);
    }
  }

  getWeekFromMasters = async (map, key, masters, week, offset, { schCache = {}, rawData, objCache }) => {
    const startDay = 7 * week;
    const endDay = 7 * week + 6;
    for (let i = 0; i < masters.length; i++) {
      const {id, single, on, off, s, d = 0, o = 0, mode = 'single', r = false} = masters[i];
      const isFake = id === 'fake';
      if (d && s + d < startDay) continue; // master ends before startDay
      if (s > endDay) break; // master starts after week ends
      const isSch = isFake || key === 'wo' || (key === 'ml' && mode === 'per_day');
      if (isSch) {
        let schDoc = schCache[id];
        if (!isFake && id && (!schDoc || !schDoc.data) && id !== 'none') {
          const sch = await firebase.firestore().doc(`companies/${this.cid}/${key === 'ml' ? 'mlSchedules' : 'schedules'}/${id}`).get();
          schDoc = sch.data();
          schCache[id] = sch.data();
        }
        for (let j = startDay, k = 0; j <= endDay; j++, k++) {
          if (key === 'ml') map.ml = {};
          if (s > j) continue; // master starts later
          if (d && s + d <= j) break; // master has ended
          const tkey = 'd' + (k + 1);
          if (isFake) {
            const { data, days, meal_details, supplement_details } = rawData || {};
            if (data) {
              const ka = key.replace(/.$/, 'a');
              const kb = key.replace(/.$/, 'a');
              const src = data[tkey][kb] || data[tkey][ka];
              _.set(map, [tkey, key], src);
            } else if (days && days.length) {
              const src = (days[k][key] || []).map(x => x.id).filter(x => Boolean(x));
              _.set(map, [tkey, key], src);
            }
            if (k === 6 && key === 'ml' && meal_details && meal_details.frequency) {
              const { frequency, off_day_meal, on_day_meal, meal } = meal_details;
              if (frequency === 'per_day') map.ml = {mode: 'per_day'};
              else if (frequency === 'bmr') map.ml = {mode: 'single', single: 'bmr'};
              else if (frequency === 'single' && meal && meal.id) map.ml = {mode: 'single', single: meal.id};
              else if (frequency === 'on_off_day') map.ml = {mode: 'on_off', on: (on_day_meal && on_day_meal.id) || null, off: (off_day_meal && off_day_meal.id) || null};
            } else if (k === 6 && key === 'sl' && supplement_details && supplement_details.frequency) {
              const { frequency, off_day_supplement, on_day_supplement, supplement } = supplement_details;
              if (frequency === 'single' && supplement && supplement.id) map.sl = {mode: 'single', single: supplement.id};
              else if (frequency === 'on_off_day') map.sl = {mode: 'on_off', on: (on_day_supplement && on_day_supplement.id) || null, off: (off_day_supplement && off_day_supplement.id) || null};
            }
            continue;
          }
          if (key === 'ml' && k === 6) map.ml = { mode: 'per_day' };
          if (!schDoc) continue;
          const { duration = 0 } = schDoc;
          let srcDay = j - s + o;
          if (r && duration && srcDay >= duration) srcDay = (srcDay % duration);
          if (srcDay >= duration) continue; // master may have data but, duration is stopped so abort
          const w = 1 + Math.floor(srcDay / 7), dow = 1 + (srcDay % 7);
          _.set(map, [tkey, key + 'm'], {id, name: schDoc.ref_name, s, d, o, w, dow, len: duration, mode}); // tell the day who their master is
          if (k < offset) continue; // don't set anything for past days
          const src = _.get(schDoc, ['data', 'w' + w, 'd' + dow, key]);
          if (!src) continue; // master has nothing to offer. move on
          if (key === 'ml') {
            if (Array.isArray(src))
              _.set(map, [tkey, key], {onoff: false, single: src[0]});
            else
              _.set(map, [tkey, key], {onoff: false, single: src});
          } else _.set(map, [tkey, key], src);
        }
      } else {
        const duration=Infinity;
        for (let j = startDay, k = 0; j <= endDay; j++, k++) {
          // map[key] = {};
          if (s > j) continue; // master starts later
          if (k < offset) continue; // don't set anything for past days
          if (d && s + d <= j) break; // master has ended
          const tkey = 'd' + (k + 1);
          let srcDay = j - s + o;
          if (r && duration && srcDay >= duration) srcDay = (srcDay % duration);
          if (srcDay >= duration) continue; // master may have data but, duration is stopped so abort
          const w = 1 + Math.floor(srcDay / 7), dow = 1 + (srcDay % 7);
          if (mode === 'on_off') { //handled sl | ml only
            if(!on || !off) continue;
            if((!!on && !!off) && (!objCache || !objCache[on] || !objCache[off])){
              //fetch
              const [_on,_off]=await Promise.all([
                firebase.firestore().doc(`companies/${this.cid}/meals/${on}`).get(),
                firebase.firestore().doc(`companies/${this.cid}/meals/${off}`).get()
              ]);
              objCache[on] = _on.data();
              objCache[off] = _off.data();
            }
            const id=on;
            const doc = objCache[id];
            const offDoc=objCache[off];
            _.set(map, [tkey, key], {onoff: true, on, off});
            _.set(map, [tkey, key + 'm'], {id, name: doc.ref_name, s, d, o, w, dow, len: duration, mode, off, off_name:offDoc.ref_name}); // tell the day who their master is
          } 
          else {
            const _id=single||id;
            const isBMR=_id==="bmr";
            if(_id && !isBMR && (!objCache || !objCache[_id])){
              const _d=await firebase.firestore().doc(`companies/${this.cid}/meals/${_id}`).get();
              objCache[_id] = _d.data();
            }
            const doc = objCache[_id];
            _.set(map, [tkey, key], {onoff: false, single: _id});
            _.set(map, [tkey, key + 'm'], {id:_id, name: (doc||{}).ref_name||"-", s, d, o, w, dow, len: duration, mode:isBMR?'bmr':mode}); // tell the day who their master is
            // if (k === 6) map[key] = { mode: 'single', single: single || id };
          }
        }
      }
    }
  }

  bmrChanges = (bmrEnabled) => {
    const { bmrlocal, bmrremote } = this.state;
    const required = _.pick(bmrlocal, ['p', 'f', 'c', 'groups', 'sort_by', 'overrides', 'meal']);
    const onRecord = _.pick(bmrremote, ['p', 'f', 'c', 'groups', 'sort_by', 'overrides']);
    const {meal, ...rest} = required;
    if (_.isEqual(onRecord, rest)) {
      // if (bmrEnabled) return [{}, meal];
      return []; // nothing has changed, nothing to save
    }
    return [rest, meal];
  }

  /* FINAL SAVE !!! */
  saveme = async() => {
    //ALIA : check remote here
    const { local, remote, startDate, buildCmd, schCache, dfrdOvrs } = this.state;
    const {plan, woms, mlms, slms} = local;
    let { data = {}, wom, mlm, slm, weeks = [], wc = {}, ac = {}, plan_state, deactivated } = plan;
    const oldWeeks = _.get(remote, 'plan.weeks', []);
    //-----important part 2
    wom = reverseMasterArr(woms);
    mlm = reverseMasterArr(mlms, false);
    slm = reverseMasterArr(slms, false);
    //------important part 2
    const [nutrition, bmrMeal] = this.bmrChanges(Boolean(mlm.bmr));
    this.dispatch({ type: 'block' });
    const start = this.mtz(startDate, 'YYYYMMDD');
    try {
      const userRef = firebase.firestore().doc(`user_profiles/${this.uid}`);
      if (buildCmd || !this.planRef) {
        const newAPlan = this.uid + ':' + uuid();
        const { duration, durationType, pack, calling } = buildCmd;
        const endDate = this.mtz(start).add(duration - 1, 'days').format('YYYYMMDD');
        const newAssTill = updatedAssignedTill(start, duration, woms, schCache, data);
        const [userChanges, planChanges] = await firebase.firestore().runTransaction(async (t) => {
          const promises = []
          /*
           * Expire old plan if present
           * update profile with new aplan, plan_assign and calling
           * pre-create target objects in the new aplan
           * and create the new aplan
           * */
          const isOldPlanExpired = remote && remote.plan && remote.plan.plan_state === 'expired';
          if (this.planRef && !isOldPlanExpired) {
            const changes = { plan_state: 'expired' };
            /* XXX not sure if we should be changing duration on expiry
            const { startDate: oldStart } = this.state.remote.plan;
            if (oldStart) {
              const diff = this.mtz().diff(this.mtz(oldStart, 'YYYYMMDD'), 'days');
              changes.duration = diff > 0 ? diff : 0;
            }*/
            promises.push(t.update(this.planRef, changes));
          }
          const {
            checkin_due_count,
            checkin_frequency,
            checkin_overdue_count,
          } = this.compData.checkin_config || DEFAULT_CHECKIN_CONFIG;
          const checkin_config = {
            checkin_due_count,
            checkin_frequency,
            checkin_overdue_count,
          };
          const purchases = {plan_assign : getPlanAssignPurchase(pack?.pack || null, calling || null, startDate)}
          const c1 = {
            current_plan_status: 'inactive', duration, durationType, startDate, endDate,
            aplan: newAPlan, plan_assign: (pack || null), assigned_till: newAssTill, purchases,
            currPurchase: null, vid_call: (calling || null)};
          if (nutrition) c1.automation = { nutrition };
          const c2 = {
            cid: this.cid, userProfileId: this.uid, purchase: (pack || null), plan_state: 'inactive',
            data, wom, mlm, slm, weeks, wc, ac, startDate, duration, durationType, _m: true, ...checkin_config };
          const newPlanRef = firebase.firestore().doc('plans/' + newAPlan);
          promises.push(t.update(userRef, c1));
          promises.push(t.set(newPlanRef.collection('aggregate').doc('activity_target'), {}));
          promises.push(t.set(newPlanRef.collection('aggregate').doc('nutrition_target'), {}));
          promises.push(t.set(newPlanRef.collection('aggregate').doc('water_target'), {}));
          promises.push(t.set(newPlanRef.collection('aggregate').doc('workout_target'), {}));
          promises.push(t.set(newPlanRef.collection('aggregate').doc('reminders'), {}));
          promises.push(t.set(newPlanRef, c2));
          this.saveLegacyOverrides(t, promises, dfrdOvrs);
          if (bmrMeal) promises.push(t.set(userRef.collection('misc').doc('bmr'), bmrMeal));
          await Promise.all(promises);
          return [c1, c2];
        });
        this.init(newAPlan);
        this.updateUMS(userChanges, planChanges);
      } else if (weeks.length > oldWeeks.length || deactivated) { // extend plan scenario
        const duration = 7 * weeks.length;
        const durationType = 'weeks';
        const endDate = this.mtz(start).add(duration - 1, 'days');
        const newState = plan_state === 'expired' ? (endDate.isAfter(this.mtz()) ? 'activated' : 'inactive') : (plan_state || null);
        const newAssTill = updatedAssignedTill(start, duration, woms, schCache, data);
        const purchase = _.get(plan, 'purchase', null);
        const [userChanges, planChanges] = await firebase.firestore().runTransaction(async (t) => {
          const promises = []
          const c1 = { plan_assign: purchase, duration, durationType, endDate: endDate.format('YYYYMMDD'),
            subs_duration: null, assigned_till: newAssTill, current_plan_status: newState,
            deactivatedOn: null, deactivated: false};
          if (nutrition) c1.automation = { nutrition };
          const c2 = {data, wom, mlm, slm, weeks, wc, ac, duration, durationType, subs_duration: null, plan_state: newState, _m: true};
          promises.push(t.update(userRef, c1));
          promises.push(t.update(this.planRef, c2));
          this.saveLegacyOverrides(t, promises, dfrdOvrs);
          if (bmrMeal) promises.push(t.set(userRef.collection('misc').doc('bmr'), bmrMeal));
          await Promise.all(promises);
          return [c1, c2];
        });
        this.updateUMS(userChanges, planChanges);
        this.dispatch({ type: 'apply' });
      } else {
        const duration = 7 * weeks.length;
        const [userChanges, planChanges] = await firebase.firestore().runTransaction(async (t) => {
          const promises = []
          const newAssTill = updatedAssignedTill(start, plan.duration || duration, woms, schCache, data);
          const c1 = {assigned_till: newAssTill};
          const c2 = { data, wom, mlm, slm, weeks, wc, ac, _m: true };
          if (nutrition) {
            promises.push(t.update(userRef, {automation: { nutrition }}));
            c1.automation = nutrition;
          }
          this.saveLegacyOverrides(t, promises, dfrdOvrs);
          if (bmrMeal) promises.push(t.set(userRef.collection('misc').doc('bmr'), bmrMeal));
          promises.push(t.update(this.planRef, c2 ));
          await Promise.all(promises);
          return [c1, c2];
        });
        this.updateUMS(userChanges, planChanges);
        this.dispatch({ type: 'apply', plan:planChanges });
      }
    } catch (e) {
      this.genericErrHandler(e, 'Unable to save the changes at this time. Please try again');
      this.dispatch({ type: 'unblock' });
    }
  }

  /* CHANGE DATES HOOK */
  changeDates = (userData, planData) => {
    if (!planData) {
      this.updateUMS(userData);
      return;
    }
    const {remote, aplan} = this.state;
    this.updateUMS(userData, planData);
    this.initWithData({...remote.plan, ...planData}, { aplan });
  };

  /* SHOW DETAILS DRAWER */
  showDetail = (data) => {
    this.dispatch({ type: 'detail.set', data: { ...(this.state.showCardDetails || {}), ...data } })
  };
  /* HIDE DETAILS DRAWER */
  hideDetail = () => this.dispatch({ type: 'detail.set', data: null })

  /* HANDLE EDIT */
  handleEdit = (weekId, weekStr, dayStr, type, id, data, restProps, oldId) => {
    const { objCache, opmngalt, local } = this.state;
    const [_str] = id.split('_');
    if(_str !== 'modified') return;
    objCache[id] = data;
    const week = Number(weekStr) + 1;
    const day = Number(dayStr) + 1;
    if (opmngalt) {
      const { ids, existing } = opmngalt;
      const idx = ids.indexOf(oldId)
      ids[idx] = id;
      existing[idx] = data;
      this.dispatch({ type: 'opmngalt.start', weekId, week, day, ids, existing });
    }
    if (type === 'food' || type === 'macros' || type === 'supplement') {
      // IMPORTANT -- new UI -- new logic --
      const key = type === 'supplement' ? 'sa' : 'ma';
      const key2 = type === 'supplement' ? 'sb' : 'mb';
      const key3 = type === 'supplement' ? 'slm' : 'mlm';
      const dayObj = _.get(local, ['weeks', weekId,'data', 'd'+day]);
      const master = dayObj[key2] ? dayObj[key2] : dayObj[key];
      const isPerDay=!dayObj[key3];
      const isSched=!!dayObj[key3] && dayObj[key3].mode==='per_day';
      //meal schedule-meal/meal-for-day override 
      if(isPerDay||isSched){
        return this.dispatch({ type: type==='supplement'?'set.sb':'set.mb', weekId, week, day, value: id });
      }
      //meal/supplement masters -> single/on-off
      const { on, off } = restProps;
      const other = (on || off ) ? { mode: 'on_off' } : { mode: 'single' };
      let idType='single';
      if (off) {
        other.on = master.on
        idType='off';
      };
      if (on) {
        other.off = master.off;
        idType='on';
      } 
      const masterKey = type === 'supplement' ? 'sl': 'ml'
      this.changeMasters(masterKey, Number(weekStr), {[idType]: id,  ...other }, Number(dayStr));
    } 
    else {
      //workout
      const { wa, wb } = _.get(local, ['weeks', weekId, 'data', 'd' + day]) || {};
      const wSrc = [...(wb || wa || [])];
      const index = wSrc.findIndex(ids => ids.includes(oldId));
      if(wSrc[index].includes('/')) {
        wSrc[index] = wSrc[index].replace(oldId, id);
      }else {
        wSrc[index] = id;
      }
      setTimeout(()=>this.dispatch({ type: 'set.wb', weekId, week, day, value: wSrc }),0);
    }
    this.dispatch({ type: 'objcache.set', id, data });
  }

  /* MIGRATION HELPERS */
  buildFromLegacy = (rawWeek, {past = false} = {}) => {
    const {lgcyOvrs, editing} = this.state;
    if (!rawWeek) // non-existant week :OMG: O.o
      return {data: {}};
    const { days, data, ml, meal_details, supplement_details } = rawWeek;
    if (past && !!data && _.isEmpty(ml)) { // past wk with per day override meals possible. Check if any day has ml and turn it on if it does
      let hasML = false;
      const _data = _.chain(data);
      for (let i = 1; i <= 7; i++) {
        if (!_data.get(`d${i}.mb`).isEmpty().value()) {
          hasML = true;
          break;
        }
      }
      if (hasML) rawWeek.ml = {mode: 'per_day'};
    }
    if (!days) // non-existant week :OMG: O.o
      return rawWeek;
    if (data) return rawWeek;
    rawWeek.data = {};
    days.forEach(({id, wo, ml, sl}, i) => {
      const out = {id, wa: null, wb: [], ma: null, mb: null, sa: null, sb: null, copy: {}};
      if (wo && wo.length === 1 && (wo[0] === 'rest' || wo[0].type === 'rest')) out.wb = ['rest'];
      else if (wo.length) wo.forEach(o => {
        if (!o || !o.id) return;
        const x = editing ? handleEdited(o, this.cid, this.uid, editing ? lgcyOvrs : null, true) : o;
        out.wb.push(x.id);
        out.copy[x.id] = x;
      });
      rawWeek.ml = this.mlslFromLegacy(meal_details, out, 'm', editing ? lgcyOvrs : null, Array.isArray(ml) ? ml[0] : ml);
      rawWeek.sl = this.mlslFromLegacy(supplement_details, out, 's', editing ? lgcyOvrs : null);
      rawWeek.data['d' + (i + 1)] = out;
    });
    return {...rawWeek, legacy: true};
  };

  editFromLegacy = async () => {
    const { emm, startDate, editing, weekToday, local } = this.state;
    if (!editing || emm.isDone) return false;
    const weeks = local.plan.weeks;
    if (!weeks || !weeks.length || weekToday >= weeks.length)
      return false;
    const weeks2fetch = local.plan.weeks.slice(weekToday);
    const weekDocs = await Promise.all(weeks2fetch.map(x => this.planRef.collection('weeks').doc(x).get()));
    const weeksData = {}, overrides = {}, mlms = [], slms = [], objCache = {}, schCache = {}, toFetch = {}, dfrdOvrs = {workouts: [], meals: []};
    let wc, ac, woms = null, theSCH = null, o = 0;
    const { workout_schedule_path, workout_schedule_offset = 0 } = local.plan;
    if (workout_schedule_path && workout_schedule_offset < weekToday * 7) { // there's a plan and we are already ahead
      const schID = workout_schedule_path.split('/').pop();
      const masterDoc = await firebase.firestore().doc(`companies/${this.cid}/schedules/${schID}`).get();
      if (masterDoc.exists && masterDoc.data().data) {
        schCache[schID] = masterDoc.data();
        theSCH = masterDoc.data().data;
        const wom = {id: schID, s: 0};
        if (workout_schedule_offset > 0) wom.s = workout_schedule_offset;
        else if (workout_schedule_offset < 0) wom.o = -1 * workout_schedule_offset;
        woms = [wom];
        o = wom.o || 0;
      }
    }
    weekDocs.forEach((doc, x) => {
      const id = doc.id;
      const { days, meal_details, supplement_details, water, activity } = doc.data() || {};
      const offset = 7 * (weekToday + x);
      const fromDate = Number(moment(startDate, 'YYYYMMDD').add(offset, 'days').format('YYYYMMDD'));
      const rawWeek = {data: {}, offset, startDate, fromDate};
      rawWeek.data = {};
      for (let y = 0; y < 7; y++) {
        let {id, wo, ml} = (days && days[y]) || {};
        if (!id) id = uuid();
        const out = {id, wa: null, wb: null, ma: null, mb: null, sa: null, sb: null, copy: {}};
        if (theSCH) {
          const srcDay = o + 7 * (weekToday + x) + y;
          const w = 'w' + (1 + Math.floor(srcDay / 7));
          const d = 'd' + (1 + srcDay % 7);
          const wos = _.get(theSCH, `${w}.${d}.wo`) || [];
          out.wb = null;
          out.wa = wos;
          wos.forEach(l => toFetch[l] = true);
        } else if (wo && wo.length) {
          if (wo[0] === 'rest' || wo[0].type === 'rest') out.wb = ['rest'];
          else {
            const tmp = [];
            wo.forEach(o => {
              if (!o || !o.id) return;
              const z = handleEdited(o, this.cid, this.uid, dfrdOvrs, true);
              tmp.push(z.id);
              out.copy[z.id] = z;
            });
            if (tmp.length) out.wb = tmp;
          }
        }
        rawWeek.ml = this.mlslFromLegacy(meal_details, out, 'm', dfrdOvrs, Array.isArray(ml) ? ml[0] : ml);
        rawWeek.sl = this.mlslFromLegacy(supplement_details, out, 's', dfrdOvrs);
        rawWeek.data['d' + (y + 1)] = out;
        if (out.wb && out.wb.length) _.set(overrides, `w${weekToday + x + 1}.d${y + 1}.wo`, out.wb);
        if (rawWeek.ml && rawWeek.ml.mode === 'per_day' && out.mb && out.mb.single)
          _.set(overrides, `w${weekToday + x + 1}.d${y + 1}.ml`, out.mb.single);
      }
      weeksData[id] = rawWeek;
      addMaster2Arr(mlms, rawWeek.ml, offset);
      addMaster2Arr(slms, rawWeek.sl, offset);
      wc = water || {};
      ac = activity || {};
    });
    if (!_.isEmpty(toFetch)) {
      const wos = await Promise.all(Object.keys(toFetch).map(x => firebase.firestore().doc(`companies/${this.cid}/workouts/${x}`).get()));
      wos.forEach(x => objCache[x.id] = x.data());
    }
    window.setTimeout(() => {
      this.dispatch({ type: 'legacy', overrides, weeksData, mlms, slms, wc, ac, woms, objCache, schCache, dfrdOvrs });
    }, 0);
  };

  mlslFromLegacy = (details, day, x, dfrdOvrs, dayMeal) => {
    const keyx = x === 'm' ? 'meal' : 'supplement';
    const key1 = x === 'm' ? 'on_day_meal' : 'on_day_supplement';
    const key0 = x === 'm' ? 'off_day_meal' : 'off_day_supplement';
    const out = x === 'm' ? 'ma' : 'sa';
    const { frequency, [keyx]: ox, [key0]: o0, [key1]: o1 } = details || {};
    const single = handleEdited(ox, this.cid, this.uid, dfrdOvrs);
    const on = handleEdited(o1, this.cid, this.uid, dfrdOvrs);
    const off = handleEdited(o0, this.cid, this.uid, dfrdOvrs);
    if (frequency === 'per_day') {
      if (dayMeal && dayMeal.id) {
        const tmp = handleEdited(dayMeal, this.cid, this.uid, dfrdOvrs);
        day.copy[tmp.id] = tmp;
        day.mb = {onoff: false, single: tmp.id};
      }
      return {mode: 'per_day', id: 'none'};
    } else if (frequency === 'bmr' && dayMeal) {
      day.copy.bmr = dayMeal;
      day[out] = {onoff: false, single: 'bmr'};
      return {mode: 'single', single: 'bmr'};
    } else if (frequency === 'single' && single && single.id) {
      day.copy[single.id] = single;
      day[out] = {onoff: false, single: single.id};
      return {mode: 'single', single: single.id};
    } else if (frequency === 'on_off_day' && (on || off) && (on || off).id) {
      if (off && off.id) {
        day.copy[off.id] = off;
        day[out] = {onoff: true, off: off.id};
      }
      if (on && on.id) {
        day.copy[on.id] = on;
        day[out] = {onoff: true, on: on.id};
      }
      return {mode: 'on_off', on: (on && on.id) || null, off: (off && off.id) || null };
    }
    return null;
  };

  saveLegacyOverrides = (txn, promises, legacyOverrides) => {
    if (!legacyOverrides) return;
    const {workouts, meals} = legacyOverrides;
    if (workouts && workouts.length)
      workouts.forEach(obj => promises.push(txn.set(
        firebase.firestore().doc(`companies/${this.cid}/workouts/${obj.id || ''}`), obj)));
    if (meals && meals.length)
      meals.forEach(obj => promises.push(txn.set(
        firebase.firestore().doc(`companies/${this.cid}/meals/${obj.id || ''}`), obj)));
  };
  isRestDay=(day,weekId)=>{
    const argsExist=!_.isUndefined(day) && !!weekId;
    const {local,currWeekId,opimport}=this.state;
    if(_.isEmpty(_.get(local,["weeks",weekId]))) return false;
    const _week=argsExist?weekId:currWeekId;
    const _day=argsExist?day:opimport.day;
    const weekData=local.weeks[_week]||{};
    const d="d"+(((_day+1)%7)||"7");
    const dayData=_.get(weekData,`data.${d}`,{});
    const {wa,wb}=dayData;
    if(wb && wb.length )return wb[0]==='rest';
    else if(wa && wa.length) return wa[0]==='rest';
    return false;
  }
  isMasterApplied=({weekId,week,day,type="wo",future=null})=>{
    const data=_.get(this.state,['local','weeks',weekId,'data','d'+(day+1)],null);
    if(!data) return false;
    const key=type==="wo"?"wom":(type==="sl"?"slm":"mlm");
    if(!data[key]) {
      if(type==="ml") {
        if(!future) {//past
          const {ma={},mb}=data;
          if(!!ma && !!Object.keys(ma).length){
            return {...ma,overridden:!!mb}
          }
        }
      }
      else if(type==="sl"){
        if(!future) {//past
          const {sa={},sb}=data;
          if(!!sa && !!Object.keys(sa).length){
            return {...sa,overridden:!!sb}
          }
        }
      }
      return false
    }
    const overridden=!!data[type==="wo"?"wb":(type==="sl"?"sb":"mb")];
    const { s, d, w, dow, len}=data[key];
    if ((week * 7 + day) < s) return false;
    if (d && (week * 7 + day) >= (s + d)) return false;
    if (7 * (w - 1) + dow > len) return false;
    return ({
      ...data[key],
      overridden
    });
  }
}

export default StateMachine;
