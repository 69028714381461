import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import {
  TextField,InputAdornment,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { DatePicker } from '@material-ui/pickers';
import Dialog from "fitbud/components/Dialog";
import { multiplier } from "fitbud/views/schedulePlanner/helper";
import { PlanField } from "fitbud/views/users/createEditForm";
import { FormTextField } from 'fitbud/components/form-fields';
import { ToggleLabel } from 'fitbud/components/toggles';
import { useInput, usePicker } from 'fitbud/hooks/form';
import { UserSchContext } from "fitbud/views/schedulev2";
import moment from "moment-timezone";
import VideoCalling from "fitbud/components/videoCallCheck";

const AssignNewPlan = () => {
  const { reassign, controller } = useContext(UserSchContext);
  const packs = (reassign && reassign.packs) || [];
  const [opt, setOpt] = usePicker(null);
  const initial_date_for_user = moment.tz(_.get(reassign, 'userDoc.profile.time_zone', 'UTC'));
  const [startDate, setStartDate] = useState(moment());
  const [pack, setPack] = useState(null);
  const {parsed: num, props: numProps, isValid: numValid, set: setNumber, setError} = useInput('', 'number * 1:min');
  const [durationType, setDurType] = useState('weeks');
  const [verrors, setVErrors] = useState({});
  const [calling, setCalling] = useState(null);
  useEffect(() => {
    if (opt === 'custom') {
      setPack(null);
      return;
    }
    const [priceId, packId] = opt.split(',');
    const pack = _.find(packs, ['pack_id', packId]);
    if (!pack) return;
    const {pack_id, title, ref_name, vid_call = null, price_opts } = pack;
    if (!price_opts || !price_opts.length) return;
    const price = _.find(price_opts, ['id', priceId]);
    let pricingData;
    if (price) {
      const {
        // this stuff is not needed anywhere in the actual payment flow. Neither here nor in the webhook
        active, active_android, active_ios, activeV2, active_iosV2, active_androidV2, iap_id, method_ios, play_product_id, // eslint-disable-line no-unused-vars
        // trial stuff, this gets used in the PG creation process only, don't need it in webhook
        enable_trial_pack, trial_duration, trial_duration_type, trial_cost,
        // the rest of the stuff relevant to the actual webhook
        ...rest
      } = price;
      pricingData = rest;
    } else {
      pricingData = { duration: '', duration_type: 'weeks', mode: 'one_time', id: 'custom' };
    }
    const {duration: d, duration_type} = pricingData;
    setNumber(d);
    setDurType(duration_type);
    setCalling(vid_call || null);
    setPack({pack_id, title, ref_name, vid_call, ...pricingData, app: false });
  }, [opt, setNumber, packs]);
  const handleCallingChange = ({vid_call}) => setCalling(vid_call);
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVErrors({});
    if (!numValid()) return;
    const duration = multiplier(durationType) * Number(num);
    if (!duration) return setError('Please specify');
    if (duration > 365) return setError('Cannot be more than a year');
    const {includes_calls } = calling || {};
    if (includes_calls) {
      if (!calling.duration) return setVErrors({call_duration: 'Please specify'});
      if (!calling.num_sessions) return setVErrors({num_sessions: 'Please specify'});
    }
    if(pack){
      pack.duration = duration;
      pack.duration_type = durationType;
    }
    controller.build(startDate.format('YYYYMMDD'), { duration, durationType },
      {pack: pack || {
        id: 'custom', pack_id: 'custom', mode: 'one_time',
        ref_name: 'Custom', title: 'Custom',
        duration: duration,
        duration_type: durationType,
      }}, calling);
  }

  return (
    <Dialog open formId='assign-new-plan-form'
      toolbarClass="height-60"
      buttonColor="primary"
      title="Assign New Plan"
      paperClass="width-600"
      
      onClose={controller.reassignCancel}
      actionText="Next"
    >
      <div className='p-20'>
        <form id='assign-new-plan-form' onSubmit={onSubmit}>
          <PlanField packDoc={packs} priceDoc={pack} handleChangeWrapper={setOpt}/>
          <div className='d-flex flex-row'>
            <FormTextField fullWidth label='Start Date'>
              <StartDateField value={startDate} onChange={setStartDate} minDate={initial_date_for_user}/>
            </FormTextField>
            <div className='mx-10'/>
            <FormTextField fullWidth label='Duration'>
              <TextField {...numProps} variant='outlined' className='standard'
                InputProps={{endAdornment: (
                  <InputAdornment position='end'>
                    <ToggleLabel size='small' value={durationType} options={DTypes} onChange={setDurType}/>
                  </InputAdornment>
                )}}/>
            </FormTextField>
          </div>
          <VideoCalling key={`vc-${opt}`} src={opt}
            handleChange={handleCallingChange}
            vid_call={calling}
            errors={verrors} />
        </form>
      </div>
    </Dialog>
  );
};

const DTypes = [{value: 'weeks', label: 'Weeks'}, {value: 'months', label: 'Months'}, {value: 'days', label: 'Days'}];
const Arr = React.memo(() => (<InputAdornment position='end'><ArrowIcon color='disabled'/></InputAdornment>));
const DatePickerField = (props) => (<TextField {...props} className='standard' InputProps={{endAdornment: <Arr/>}} />);
const StartDateField = (props) => {
  const {
    onChange, value, minDate,
  } = props;
  return (
    <DatePicker autoOk
      value={value}
      inputVariant='outlined'
      onChange={onChange}
      format='DD MMM YYYY'
      TextFieldComponent={DatePickerField}
      minDate={minDate}/>
  );
};

export default AssignNewPlan;
